import React from 'react'
import { DatosEdicionGenerales } from '../../../../types/types'
import { useDispatch, useSelector } from 'react-redux'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import {
  TipoIdentificacion,
  TipoTransaccion,
} from '../../../../../../../componentes/tipoIdentificacion/store/types'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import TipoIdentificacionLookUp from '../../../../../../../componentes/tipoIdentificacion/tipoIdentificacion'
import TipoEstadoLookUp from '../../../../../../../componentes/tipoEstado/'
import { RadioGroup, Switch, TextBox, Validator } from 'devextreme-react'
import {
  EmailRule,
  RequiredRule,
  StringLengthRule, AsyncRule
} from 'devextreme-react/validator'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { CCard, CCardBody } from '@coreui/react-pro'
import TabContactos, {
  ETipoAsociado,
} from '../../../../../../../proveedores/components/modalAdminProveedores/tabContactos/tabContactos'
import { RootState } from '../../../../../../../../store/store'
import { setTabGenerales, setTabPerson } from '../../../../store/editDataReducer'
import { TEstado } from '../../../../../../../../store/types'
import LocalidadesLookUp from '../../../../../../../componentes/localidadesLookUp/localidadesLookUp'
import TipoProveedorLookUp from '../../../../../../../componentes/tipoProveedor'
import LookUpClasificacionAdicional from '../../../../../../../componentes/lookUpClasificacionAdicional'
import { realacionadoGrupos } from '../../../../../../../clientes/pages/clientes/components/documento'
import { OptionCiudad } from '../../../../../../../../containers/component/formLocal/types/types'
import { TipoProveedor } from '../../../../../../../ventas/types/types'
import { ClasificacionOption } from '../../../../../../../componentes/lookUpClasificacionAdicional/store/type'
import { MessagesKeys, lh } from '../../../../../../../../helpers/localizationHelper'
import { GeneralService } from '../../../../../../../../services/general.service'
import { ProveedoresService } from '../../../../../../services/proveedores.service'
import { TDatosPersona, TelefonoCliente } from '../../../../../../../clientes/pages/clientes/types/types'

interface IDatosGeneralesProps {
  tabId: string
  refForm: any
}

const Generales: React.FunctionComponent<IDatosGeneralesProps> = (props) => {
  const { tabId } = props
  const dispatch = useDispatch()
  const [element] = React.useState('1')

  const stateEditGenerals = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.generales
  });
  const stateEditContribuyente = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.contribuyente
  })

  const [esNuevo, setEsNuevo] = React.useState<boolean>(false); // tab.editStatus === StatesEdition.new ? true : false
  const [loadPerson, setLoadPerson] = React.useState<{
    load: boolean
    data: any
    esNuevo: boolean
  }>({ load: false, data: null, esNuevo: false })

  const onChangedCarrier = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              transportista: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedRelated = React.useCallback(
    (event: any) => {
      if (event.event)
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              parteRelacionada: event.value,
            },
          }),
        )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedTypeClasification = React.useCallback(
    async (clasification: ClasificacionOption) => {
      dispatch(
        setTabGenerales({
          key: tabId,
          data: {
            ...stateEditGenerals,
            clasificacion: clasification,
          },
        }),
      )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedTypeSupplier = React.useCallback(
    async (typeSupplier: TipoProveedor) => {
      dispatch(
        setTabGenerales({
          key: tabId,
          data: {
            ...stateEditGenerals,
            tipoProveedor: typeSupplier,
          },
        }),
      )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedCity = React.useCallback(
    (city: OptionCiudad) => {
      dispatch(
        setTabGenerales({
          key: tabId,
          data: {
            ...stateEditGenerals,
            ciudad: city,
          },
        }),
      )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedPhones = React.useCallback(
    (phones: any) => {
      if (phones.length > 0)
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              telefonos: phones,
            },
          }),
        )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedState = React.useCallback(
    async (state: TEstado) => {
      dispatch(
        setTabGenerales({
          key: tabId,
          data: {
            ...stateEditGenerals,
            estado: state,
          },
        }),
      )
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedEmail = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              email: event.value,
            },
          }),
        )
      }
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedAddress = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              direccion: event.value,
            },
          }),
        )
      }
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedCraftsman = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              artesanoCalificado: event.value,
            },
          }),
        )
      }
    },
    [dispatch, stateEditGenerals, tabId],
  )

  const onChangedInsurance = React.useCallback(
    (event: any) => {
      if (event.event) {
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              proveedorSeguro: event.value,
            },
          }),
        )
      }
    }, [dispatch, stateEditGenerals, tabId])

  const onChangedIdentificationType = React.useCallback(
    (data: TipoIdentificacion) => {
      if (data !== null) {
        dispatch(
          setTabGenerales({
            key: tabId,
            data: {
              ...stateEditGenerals,
              tipoIdentificacion: data ?? null,
            },
          }),
        )
      }
    }, [dispatch, stateEditGenerals, tabId]);

  const onChangeIdentificacion = React.useCallback(async (data) => {
    if (data.value !== null) {
      // dispatch(setTabResetPerson({
      //   key: tabId
      // }));
      dispatch(setTabGenerales({
        key: tabId,
        data: {
          ...stateEditGenerals,
          identificacion: data.value
        }
      }));
    }
  }, [dispatch, tabId, stateEditGenerals])

  // const validateIdProveedor = React.useMemo(() => {
  //   if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '05') {
  //     return inputIdentificacionCedula
  //   } else if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '04') {
  //     return inputIdentificacionRuc
  //   } else {
  //     return inputIdentificacionPasaporte
  //   }
  // }, [
  //   stateEditGenerals, inputIdentificacionCedula,
  //   inputIdentificacionRuc, inputIdentificacionPasaporte
  // ]);

  const validateId = React.useCallback(async (params) => {
    const validacionIdentificacion = await GeneralService.validarIndentificacion(params.value ?? '')
    if (validacionIdentificacion.isValid === true) {
      setLoadPerson({ load: true, data: validacionIdentificacion, esNuevo: esNuevo })
      if (esNuevo) {
        const proveedor: any = await ProveedoresService.consultarProveedores('', params.value ?? '', false);
        if (proveedor.length > 0) return Promise.reject(`El proveedor con identificacion ${(params.value ?? '')} ya se encuentra registrado.`)
      }
      return Promise.resolve()
    } else {
      return Promise.reject(validacionIdentificacion.motivo ?? 'Error al validar identificación')
    }
  }, [esNuevo]);

  const getDataPerson = React.useCallback(async (infoPerson) => {
    console.log("getDataPerson", infoPerson)

    const actualizarPersona: TDatosPersona = {
      direccion: stateEditGenerals?.direccion ?? '',
      email: stateEditGenerals?.email ?? '',
      nombre: stateEditContribuyente?.nombre ?? '',
      razonComercial: stateEditContribuyente?.razonComercial ?? '',
      telefono: stateEditGenerals?.telefonos ?? []
    };
    if (infoPerson.isValid === true) {


      if (stateEditContribuyente?.nombre === "" && infoPerson?.nombre !== undefined) {
        actualizarPersona.nombre = infoPerson?.nombre
      }
      if (stateEditContribuyente?.razonComercial === "" && infoPerson?.razonComercial !== undefined) {
        actualizarPersona.razonComercial = infoPerson?.razonComercial
      }
      if (stateEditGenerals.email === "" && infoPerson?.email !== undefined) {
        actualizarPersona.email = infoPerson?.email
      }
      if (stateEditGenerals.direccion === "" && infoPerson?.direccion !== undefined) {
        actualizarPersona.direccion = infoPerson?.direccion
      }

      // stateEditGenerals?.telefonos
      console.log("stateEditGenerals:", stateEditGenerals)

      if (stateEditGenerals?.telefonos && stateEditGenerals?.telefonos.length > 0) {
        const contacto: any = await stateEditGenerals?.telefonos.findIndex((x) => {
          return String(x.numero) === String(infoPerson?.telefono)
        })
        console.log("contacto:", contacto)
        const contatosAgregados: TelefonoCliente[] = stateEditGenerals?.telefonos.slice();
        if (contacto < 0) {
          contatosAgregados.push({
            descripcion: 'CELULAR',
            numero: infoPerson.telefono,
            tipo: 'celular',
            principal: 1,
          })
          actualizarPersona.telefono = contatosAgregados;
          console.log("contatosAgregados:", contatosAgregados)
          console.log("infoPerson:", infoPerson)
        } else {
          actualizarPersona.telefono = [{
            descripcion: 'CELULAR',
            numero: infoPerson.telefono,
            tipo: 'celular',
            principal: 1,
          }]
        }
      } else {
        if (infoPerson.telefono) {
          actualizarPersona.telefono = [{
            descripcion: 'CELULAR',
            numero: infoPerson.telefono,
            tipo: 'celular',
            principal: 1,
          }]
        }
      }

      console.log("actualizarPersona : ", actualizarPersona)

      dispatch(setTabPerson({
        key: tabId,
        datosPersona: actualizarPersona
      }));
    }

  }, [dispatch, tabId, stateEditContribuyente, stateEditGenerals])


  const minLengthId = React.useCallback(() => {
    let minNumber: number = 3;
    if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '02') {
      minNumber = 10;
    } else if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '01') {
      minNumber = 13;
    } else {
      minNumber = 3;
    }
    return minNumber;
  }, [stateEditGenerals]);

  const maxLengthId = React.useCallback(() => {
    let minNumber: number = 50;
    if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '02') {
      minNumber = 10;
    } else if (stateEditGenerals?.tipoIdentificacion && stateEditGenerals?.tipoIdentificacion?.codigo === '01') {
      minNumber = 13;
    } else {
      minNumber = 50;
    }
    return minNumber;
  }, [stateEditGenerals]);

  const validateAsyncSelect = React.useCallback((datoSeleccion: any, message: string) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionTipoId = React.useCallback((datoSeleccion) => {
    return validateAsyncSelect(datoSeleccion, `Tipo de Identificación: Debe seleccionar una opción.`)
  }, [validateAsyncSelect])

  React.useEffect(() => {
    if (stateEditContribuyente?.codigo === 0) {
      setEsNuevo(true)
    } else if (stateEditContribuyente?.codigo > 0) {
      setEsNuevo(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEditContribuyente]);

  React.useEffect(() => {
    if (loadPerson.load === true && stateEditContribuyente?.codigo === 0) {
      setLoadPerson({ load: false, data: null, esNuevo: false })
      getDataPerson(loadPerson.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPerson])

  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Tipo Identificación:">
            <TipoIdentificacionLookUp
              onChanged={(data) => {
                if (data !== null || data !== undefined) {
                  onChangedIdentificationType(data)
                }
              }}
              transaccion={TipoTransaccion.compra}
              disabled={false}
              selected={stateEditGenerals?.tipoIdentificacion ?? null}
              provider={stateEditGenerals?.tiposIdentificacion ?? []}
              id="lookUpTipoIdDGP"
            >
              <Validator>
                <RequiredRule message={'Tipo de Identificación: Debe seleccionar una opción'} />
                <AsyncRule
                  message={'Tipo de Identificación: Debe seleccionar una opción'}
                  validationCallback={validateAsyncSeleccionTipoId}
                />
              </Validator>
            </TipoIdentificacionLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Cédula/RUC:">
            <TextBox
              name="identificacionInput"
              placeholder="C.I. / Identificación"
              value={stateEditGenerals?.identificacion ?? ''}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangeIdentificacion(e)
                }
              }}
              id={element}
            >
              <Validator>
                <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
                <StringLengthRule
                  max={maxLengthId()}
                  min={minLengthId()}
                  ignoreEmptyValue
                  message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, '')}
                />
                <AsyncRule
                  message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, '')}
                  validationCallback={validateId}
                />
              </Validator>
            </TextBox>
          </Labeled>

        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Proveedor de Seguros">
            <Switch
              value={stateEditGenerals?.proveedorSeguro ?? false}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedInsurance(e)
                }
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Artesano con Calificación">
            <Switch
              value={stateEditGenerals?.artesanoCalificado ?? false}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedCraftsman(e)
                }
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="5">
          <Labeled label="Dirección:">
            <TextBox
              value={stateEditGenerals?.direccion ?? ''}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedAddress(e)
                }
              }}
            >
              <Validator>
                <RequiredRule
                  message={'Dirección: Debe tener al menos 5 caracteres.'}
                />
                <StringLengthRule
                  min={5}
                  message={'Dirección: Debe tener al menos 5 caracteres.'}
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Email">
            <TextBox
              value={stateEditGenerals?.email ?? ''}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedEmail(e)
                }
              }}
            >
              <Validator>
                <RequiredRule message={'Email: Formato de email incorrecto.'} />
                <EmailRule message={'Email: Formato de email incorrecto.'} />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Estado:">
            <TipoEstadoLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedState(e)
                }
              }}
              selected={stateEditGenerals?.estado ?? null}
              provider={stateEditGenerals?.estados ?? []}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="12" className="mt-2 mb-2">
          <CCard>
            <CCardBody>
              <TabContactos
                tipoAsociado={ETipoAsociado.proveedor}
                data={stateEditGenerals?.telefonos ?? []}
                actionSelect={(data) => console.log('data', data)}
                tiposOperadoras={[]}
                returnContactos={onChangedPhones}
              />
            </CCardBody>
          </CCard>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Ciudad:">
            <LocalidadesLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedCity(e)
                }
              }}
              selected={stateEditGenerals?.ciudad ?? null}
              allowEdit
              allowClear
            ></LocalidadesLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Tipo de Proveedor:">
            <TipoProveedorLookUp
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedTypeSupplier(e)
                }
              }}
              selected={stateEditGenerals?.tipoProveedor ?? null}
              provider={stateEditGenerals?.tiposProveedor ?? []}
            ></TipoProveedorLookUp>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Clasificación:">
            <LookUpClasificacionAdicional
              selected={stateEditGenerals?.clasificacion ?? null}
              provider={stateEditGenerals?.clasificacionOptions ?? []}
              onChanged={(e) => {
                if (e !== null || e !== undefined) {
                  onChangedTypeClasification(e)
                }
              }}
            ></LookUpClasificacionAdicional>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3">
          <Labeled label="Parte Relacionada:">
            <RadioGroup
              name="relacionado"
              layout="horizontal"
              displayExpr="label"
              dataSource={realacionadoGrupos}
              value={stateEditGenerals?.parteRelacionada ?? null}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedRelated(e)
                }
              }}
            />
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="2">
          <Labeled label="Transportista: ">
            <Switch
              value={stateEditGenerals?.transportista ?? false}
              onValueChanged={(e) => {
                if (e.event !== null || e.event !== undefined) {
                  onChangedCarrier(e)
                }
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default Generales

export const defaultDatosGenerales: DatosEdicionGenerales = {
  tipoIdentificacion: null,
  tiposIdentificacion: [],
  identificacion: '',
  proveedorSeguro: false,
  artesanoCalificado: false,
  direccion: '',
  email: '',
  estado: null,
  estados: [],
  telefonos: [],
  ciudad: null,
  tipoProveedor: null,
  tiposProveedor: [],
  clasificacion: null,
  clasificacionOptions: [],
  parteRelacionada: null,
  transportista: false,
}
