import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  ToastTypes,
} from '../../../../../store/types'
import {
  TEstudioData,
  TLoginData,
  TMainEdition,
  TPersonalesData,
  TUsuarioData,
  UsuarioDatosEdicion,
} from '../../usuario/store/types'
import { RootState } from '../../../../../store/store'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import {
  CAlert,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import TEstudios, { defaultEstudiosUsuarioData } from './estudios/estudios'
import TExtras from './extras/extras'
import TLogin, { defaulDatosEdicionTabDatosLogin } from './login/login'
import TRenta from './renta/renta'
import TNomina from './nomina/nomina'
import TPersonales, {
  defaulDatosEdicionTabDatosPersonales,
} from './personales/personales'
import { ValidationGroup, ValidationSummary } from 'devextreme-react'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabsReducer'
import { ERolUser, StatesEdition } from '../../../../../store/enums'
import {
  setChangeLoaderEdit,
  setDatosEdicion,
  updateLoader,
} from '../store/editDataReducer'
import { addToast } from '../../../../../store/toasterReducer'
import { tipoIdentificacionService } from '../../../../componentes/tipoIdentificacion/service/tipoId.service'
import { estadosService } from '../../../../componentes/estadosCivilLookUp/service/estados.service'
import { tipoSangreService } from '../../../../componentes/tipoSangre/services/tipoSangre.service'
import { tipoEstadoService } from '../../../../componentes/tipoEstado/service/tipoEstado.service'
import { estudiosService } from '../../../../componentes/nivelesEstudioLookUp/service/estudios.service'
import { localesService } from '../../../../componentes/localesLookUp/service/locales.service'
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import { TipoTransaccion } from '../../../../componentes/tipoIdentificacion/store/types'
import { getIndexProvider } from '../../../../shared/helpers/funciones'
import {
  Asignacion,
  Contactos,
  SavePersonal,
} from '../../../../nomina/pages/personal/types/types'
import VisualizaError from '../../../../ventas/pages/shared/visualizaError/visualizaError'
import ListErrosValidationForm from '../../../../componentes/listadoErroresValidacionForm'
import { cuentaService } from '../../../../../services/cuenta.service'
import { PersonalServices } from '../../../../nomina/pages/personal/services/personal.service'
import { tiposPersonasServices } from '../../../../componentes/tiposPersonasLookUp/service/tiposPersonas.service'
import { localidadesService } from '../../../../../services/localidades.service'
import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'
import TUsuarioBasico from './usuarioBasico/usuarioBasico'
import { UsuariosService } from '../services/usuarios.services'
import prefijos from '../../../../../views/pages/registrarUsuario/prefijosTelefonos'
import { setDatosEdicionBackup } from '../store/configReducer'
import { utilidades } from '../../../../../helpers/utilidades'
import config from '../../../../../config/config'
import { encriptarConKey } from '../../../../../helpers/Helper'
import { ECountry } from '../../../../../store/enum/countries'
import TPuntoVenta from './puntoVenta/puntoVenta'
interface ICompraProps extends React.PropsWithChildren {
  info: DocumentInfo<TUsuarioData>
  tabId: string
  tab: TabState<TUsuarioData>
}
export const generosGruposPersonal = [
  { value: 1, label: 'M' },
  { value: 0, label: 'F' },
  { value: -1, label: 'N/A' },
]
const Usuario: React.FunctionComponent<ICompraProps> = (props) => {
  const { info, tabId, tab } = props
  const dispatch = useDispatch()
  const validationGroupRef = React.useRef<any>()

  const dialogRef = React.useRef<any>(null)
  const tabs = useSelector(
    (state: RootState) => state.administracion.configuraciones.usuarios.tabs,
  )
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const usuarioSesion = useSelector((state: RootState) => {
    return state.global.session?.usuario
  })
  const loader = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId].loader
  })
  const loading = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId].loading
  })
  const usuarioState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
  })
  const localSession = useSelector(
    (state: RootState) => state.global.session.local,
  )
  const contratoEmpresa = useSelector(
    (state: RootState) => state.pagosSuscripcion.contratoEmpresa,
  )
  const usuarioBasicoState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
      .usuarioBasico
  })
  const usuarioBackupState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.configuraciones[tabId]
  })

  const [showTab, setShowTab] = React.useState<
    | string
    | 'personales'
    | 'estudios'
    | 'extras'
    | 'login'
    | 'rentas'
    | 'nomina'
    | 'puntoVenta'
  >('personales')
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return (
        <CAlert color="danger" id={'alert-' + utilidades.getUUID()}>
          <ul className="errorList" id={utilidades.getUUID() + 'errorList'}>
            {item.map(function (item, id) {
              return <li key={id + '-' + utilidades.getUUID()}>{item}</li>
            })}
          </ul>
        </CAlert>
      )
    } else {
      return <></>
    }
  }, [])

  const setToast = React.useCallback(
    (type: ToastTypes, error: any) => {
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Acatha',
          content: typeof error == 'string' ? error : JSON.stringify(error),
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const onLoader = React.useCallback(
    (showLoader: boolean, text: string) => {
      dispatch(
        setChangeLoaderEdit({
          key: tabId,
          data: {
            show: showLoader,
            mensaje: text,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }

      dispatch(
        updateLoader({
          key: tabId,
          loader: { mensaje: 'Cargando', show: true },
        }),
      )

      const data = { ...defaultDatosEdicionUsuario }

      try {
        const dataPersonales: TPersonalesData = { ...data.personales }
        const dataEstudios: TEstudioData = { ...data.estudios }
        // const dataExtras: ExtrasDatosEdicion = { ...data.extras }
        const dataLogin: TLoginData = { ...data.login }
        // const dataRentas: RentasDatosEdicion = { ...data.rentas }
        // const dataRolesPago: RolesPagoDatosEdicion = { ...data.nomina.rolesPago }
        // const dataDescuentos: DescuentosDatosEdicion = { ...data.nomina.descuentos }

        const maximoUsuarios = await UsuariosService.getMaxUsers()
        if (maximoUsuarios >= contratoEmpresa?.numUsuariosContrato) {
          data.maximoUsuarios = Number(maximoUsuarios)
        }
        const dataId = await tipoIdentificacionService.getTipoIdetificacion(
          TipoTransaccion.compra,
          localSession?.ciudad?.paisCodigo ?? 1,
        )
        const dataEstadoCivil = await estadosService.getEstados(
          'Elija un estado',
        )
        const dataTipoSangre = await tipoSangreService.getTipoSangre(
          'Elija tipo',
        )
        const dataEstado = await tipoEstadoService.getTipoEstado(
          'Elija un estado',
        )
        const dataNivelEstudios = await estudiosService.getEstados(
          'Elija una opción',
        )
        const dataLocales = await localesService.getLocales('Elija una opción')

        const dataTipoUsuario = await tiposPersonasServices.getTipos(
          179,
          'Elija tipo',
        )

        if (dataId['auto'] && dataId['error'] === false) {
          dataPersonales.tiposIdentificacion = dataId['auto']
          dataPersonales.tipoIdentificacion = dataId['auto']?.[1]
        }

        if (dataEstadoCivil['auto'] && dataEstadoCivil['error'] === false) {
          dataPersonales.estadosCivil = dataEstadoCivil['auto']
          dataPersonales.estadoCivil = dataEstadoCivil['auto']?.[1]
        }

        if (dataTipoSangre['auto'] && dataTipoSangre['error'] === false) {
          dataPersonales.tiposSangre = dataTipoSangre['auto']
          dataPersonales.tipoSangre = dataTipoSangre['auto']?.[1]
        }

        if (dataEstado['auto'] && dataEstado['error'] === false) {
          dataPersonales.estados = dataEstado['auto']
          dataPersonales.estado = dataEstado['auto'][1]
        }
        if (dataTipoUsuario?.auto && dataTipoUsuario.auto.length > 0) {
          dataLogin.tiposUsuario = dataTipoUsuario.auto
          const find = 'Usuario'
          dataLogin.tipoUsuario = dataTipoUsuario.auto.find((it) =>
            it.descripcion.toLowerCase().includes(find.toLowerCase()),
          )
        }

        dataPersonales.fechaNacimiento = DateUtils.getCurrentDateAsString()
        dataPersonales.genero = generosGruposPersonal[0]
        dataLogin.creado = DateUtils.getCurrentDateAsString()

        // dataRentas.anio = parseInt(DateUtils.getCurrentDateAsString('dd/MM/yyyy').substring(6.4))
        if (dataNivelEstudios['auto'] && dataNivelEstudios['error'] === false) {
          dataEstudios.estudios = dataNivelEstudios['auto']
          dataEstudios.estudio = dataNivelEstudios['auto']?.[1]
        }

        if (dataLocales['auto'] && dataLocales['error'] === false) {
          const provider = dataLocales['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            localSession?.codigo ?? -1,
          )
          if (index > -1) {
            dataLogin.lugarCC = provider[index]
          } else {
            dataLogin.lugarCC = provider[0]
          }
        }

        data.personales = dataPersonales
        data.estudios = dataEstudios
        // data.extras = dataExtras
        data.login = dataLogin
        // data.rentas = dataRentas
        // data.nomina = {
        //   rolesPago: dataRolesPago,
        //   descuentos: dataDescuentos
        // }
        data.loading = false

        let localForUser = dataLogin.lugarCC

        localSession?.codigo
        if (maximoUsuarios > 1) {
          if (dataLocales['auto'] && dataLocales['error'] === false) {
            const provider = dataLocales['auto'].slice(0)
            const index = await getIndexProvider(
              provider,
              'codigo',
              localSession?.codigo ?? -1,
            )
            if (index > -1) {
              localForUser = provider[index]
            } else {
              localForUser = provider[0]
            }
          }
        }

        let prefijoPais = prefijos[149]
        if (empresaState?.codigoPais === ECountry.Ecuador) {
          prefijoPais = prefijos[149]
        } else if (empresaState?.codigoPais === ECountry.ElSalvador) {
          prefijoPais = prefijos[139]
        }

        data.usuarioBasico = {
          codigo: 0,
          identificacion: '',
          nombres: '',
          apellidos: '',
          email: '',
          usuario: '',
          password: '',
          ciudadReferencia: null,
          telefono: '',
          telefonos: [],
          local: localForUser,
          prefijo: prefijoPais,
        }

        console.log('inici', data)

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...data },
          }),
        )
      } catch (error) {
        console.error(error)
        data.tieneError = true
        data.mensajeError = error
        data.loading = false

        dispatch(
          addToast({
            content: data.mensajeError,
            type: ToastTypes.Danger,
          }),
        )

        dispatch(
          setCurrentExecutingAction({
            tabKey: tabId,
            buttonType: undefined,
          }),
        )
      }

      // dispatch(setDatosEdicionBackup({
      //   data: data,
      //   key: tabId,
      // }))
    },
    [loading, dispatch, tabId, localSession, contratoEmpresa, empresaState],
  )

  const modoEdicion = React.useCallback(
    async (cliente, personalData) => {
      dispatch(
        setButtons({
          tabKey: tabId,
          buttons: {
            ...EUsuarioButtons,
            Imprimir: true,
          },
        }),
      )

      onSetButtonAction(ButtonTypes.edit)
      onLoader(true, 'Cargando Usuario...')

      try {
        const dataEdit = { ...personalData }

        const dataPersona = personalData
        const dataPersonales: TPersonalesData = { ...dataEdit.personales }
        const dataEstudios: TEstudioData = { ...dataEdit.estudios }
        const dataLogin: TLoginData = { ...dataEdit.login }

        const dataId = await tipoIdentificacionService.getTipoIdetificacion(
          TipoTransaccion.compra,
          localSession?.ciudad?.paisCodigo ?? 1,
        )
        const dataEstadoCivil = await estadosService.getEstados(
          'Elija un estado',
        )
        const dataTipoSangre = await tipoSangreService.getTipoSangre(
          'Elija tipo',
        )
        const dataEstado = await tipoEstadoService.getTipoEstado(
          'Elija un estado',
        )
        const dataNivelEstudios = await estudiosService.getEstados(
          'Elija una opción',
        )
        const dataLocales = await localesService.getLocales('Elija una opción')
        const dataTipoUsuario = await tiposPersonasServices.getTipos(
          179,
          'Elija tipo',
        )
        const localesUsuario = await UsuariosService.getLocalesUsuarios(
          dataPersona?.codigo ?? 0,
        )

        const dataCiudades = await localidadesService.getDpCiudades(
          dataPersona?.proCodigo ?? 1,
          'Elija una opción',
        )
        dataEdit.codigo = dataPersona?.codigo ?? 0

        if (dataId['auto'] && dataId['error'] === false) {
          dataPersonales.tiposIdentificacion = dataId?.auto ?? []
          const provider = dataId['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.ideCodigo ?? '-1',
          )

          if (index > -1) {
            dataPersonales.tipoIdentificacion = provider[index]
          } else {
            dataPersonales.tipoIdentificacion = provider[0]
          }
        }

        if (dataEstado['auto'] && dataEstado['error'] === false) {
          dataPersonales.estados = dataEstado?.auto ?? []
          const provider = dataEstado['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.estado ?? 0,
          )
          if (index > -1) {
            dataPersonales.estado = provider[index]
          } else {
            dataPersonales.estado = provider[0]
          }
        }

        if (dataEstadoCivil['auto'] && dataEstadoCivil['error'] === false) {
          dataPersonales.estadosCivil = dataEstadoCivil?.auto ?? []
          const provider = dataEstadoCivil['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.ecivil ?? 0,
          )
          if (index > -1) {
            dataPersonales.estadoCivil = provider[index]
          } else {
            dataPersonales.estadoCivil = provider[0]
          }
        }

        if (dataTipoSangre['auto'] && dataTipoSangre['error'] === false) {
          dataPersonales.tiposSangre = dataTipoSangre?.auto ?? []
          const provider = dataTipoSangre['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'descripcion',
            personalData?.tipoSangre ?? 0,
          )
          if (index > -1) {
            dataPersonales.tipoSangre = provider[index]
          } else {
            dataPersonales.tipoSangre = provider[0]
          }
        }

        if (dataTipoUsuario['auto'] && dataTipoUsuario['error'] === false) {
          dataLogin.tiposUsuario = dataTipoUsuario?.auto ?? []
          const provider = dataTipoUsuario['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.tipo ?? 0,
          )
          if (index > -1) {
            dataLogin.tipoUsuario = provider[index]
          } else {
            dataLogin.tipoUsuario = provider[0]
          }
        }

        if (generosGruposPersonal.length > 0) {
          const provider = generosGruposPersonal.slice(0)
          const index = await getIndexProvider(
            provider,
            'value',
            personalData?.genero ?? 0,
          )
          if (index > -1) {
            dataPersonales.genero = provider[index]
          } else {
            dataPersonales.genero = provider[0]
          }
        }
        const ciudades = [] as Array<OptionCiudad>
        if (dataCiudades && dataCiudades.length > 0) {
          for (const item of dataCiudades) {
            ciudades.push({
              codigoPais: Number(item?.paisCodigo) ?? 0,
              nombrePais: String(item?.paisNombre) ?? '',
              codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
              nombreProvincia: String(item?.provinciaNombre) ?? '',
              codigo: Number(item?.codigo) ?? 0,
              nombre: String(item?.nombre) ?? '',
            })
          }
        }

        if (ciudades.length > 0) {
          const provider = ciudades.slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.ciudadCodigo ?? 0,
          )
          if (index > -1) {
            dataPersonales.ciudadReferencia = provider[index]
          } else {
            dataPersonales.ciudadReferencia = provider[0]
          }
        }

        if (ciudades.length > 0) {
          const provider = ciudades.slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.ciuOrigen ?? 0,
          )
          if (index > -1) {
            dataPersonales.ciudadOrigen = provider[index]
          } else {
            dataPersonales.ciudadOrigen = provider[0]
          }
        }

        let fnacimiento = ''
        if (
          personalData?.fnacimiento &&
          personalData?.fnacimiento !== '00/00/0000'
        ) {
          fnacimiento = DateUtils.format(
            personalData?.fnacimiento,
            'yyyy-MM-dd',
            'dd/MM/yyyy',
          )
        }

        dataPersonales.identificacion = personalData?.identificacion ?? ''
        dataPersonales.nombres = personalData?.nombres ?? ''
        dataPersonales.apellidos = personalData?.apellidos ?? ''
        dataPersonales.codigoIess = personalData?.codigoIess ?? ''
        dataPersonales.direccion = personalData?.direccion ?? ''
        dataPersonales.numeroCasa = personalData?.numero ?? ''
        dataPersonales.fechaNacimiento = fnacimiento
        dataPersonales.email = personalData?.email ?? ''
        dataPersonales.cargasFamiliares = personalData?.cargasFamiliares

        const telefonosUsuario = await PersonalServices.getPhones(
          dataPersona?.codigo ?? 0,
        )
        if (telefonosUsuario['auto'] && telefonosUsuario['error'] === false) {
          dataPersonales.telefonos = telefonosUsuario['auto']
        }
        // Tab estudios

        if (dataNivelEstudios['auto'] && dataNivelEstudios['error'] === false) {
          dataEstudios.estudios = dataNivelEstudios?.auto ?? []
          const provider = dataNivelEstudios['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            personalData?.nivelCodigo ?? 0,
          )

          if (index > -1) {
            dataEstudios.estudio = provider[index]
          } else {
            dataEstudios.estudio = provider[0]
          }
        }

        dataEstudios.titulos = personalData?.titulos ?? ''
        dataEstudios.cursos = personalData?.cursos ?? ''

        // Tab login
        if (dataLocales['auto'] && dataLocales['error'] === false) {
          const provider = dataLocales['auto'].slice(0)
          const index = await getIndexProvider(
            provider,
            'codigo',
            localSession?.codigo ?? -1,
          )
          if (index > -1) {
            dataLogin.lugarCC = provider[index]
          } else {
            dataLogin.lugarCC = provider[0]
          }
        }
        let fcreacion = ''
        if (dataPersona?.fcreacion) {
          fcreacion = DateUtils.format(
            dataPersona?.fcreacion,
            'yyyy-MM-dd',
            'dd/MM/yyyy',
          )
        }
        dataLogin.creado = fcreacion
        dataLogin.usuario = personalData?.usuario ?? ''
        dataLogin.codigoPase = personalData?.pase ?? ''
        dataLogin.password_old = ''
        dataLogin.password = ''
        dataLogin.confirmarPassword = ''
        if (personalData?.codigo) {
          dataLogin.maintenanceCredentials = false
        }

        dataEdit.personales = dataPersonales
        dataEdit.estudios = dataEstudios

        // dataEdit.extras = dataExtras
        dataEdit.login = dataLogin
        // dataEdit.rentas = dataRentas

        dataEdit.loading = false
        let telefonosUserBasic = ''

        if (dataPersonales?.telefonos && dataPersonales?.telefonos.length > 0) {
          telefonosUserBasic = dataPersonales?.telefonos[0]?.numero
        }
        for (const item of prefijos) {
          if (telefonosUserBasic.startsWith(item?.value) === true) {
            telefonosUserBasic = telefonosUserBasic.replace(item?.value, '')
            break
          }
        }

        // Tab Basic Info
        let localUsuarioBasico = null
        if (
          dataLocales?.auto.length > 0 &&
          localesUsuario?.auto &&
          localesUsuario?.auto.length > 0
        ) {
          if (localesUsuario.auto.length === 1) {
            const provider = dataLocales['auto'].slice(0)
            const index = await getIndexProvider(
              provider,
              'codigo',
              localesUsuario?.auto[0]?.codigo ?? -1,
            )
            if (index > -1) {
              localUsuarioBasico = provider[index]
            } else {
              localUsuarioBasico = provider[0]
            }
          }
        }

        let perfijoFiltrado = prefijos[149]
        let telefonoFiltrado = ''
        if (dataPersonales?.telefonos && dataPersonales?.telefonos.length > 0) {
          telefonoFiltrado = dataPersonales?.telefonos[0]['numero']

          for (const item of prefijos) {
            if (
              dataPersonales?.telefonos[0]['numero'].startsWith(item?.value) ===
              true
            ) {
              perfijoFiltrado = item
              telefonoFiltrado = dataPersonales?.telefonos[0]['numero'].replace(
                item?.value,
                '',
              )
              break
            }
          }
        }

        dataEdit.usuarioBasico = {
          codigo: dataPersona?.codigo ?? 0,
          identificacion: dataPersona?.identificacion ?? '',
          nombres: dataPersona?.nombres ?? '',
          apellidos: dataPersona?.apellidos ?? '',
          email: dataPersona?.email ?? '',
          usuario: dataPersona?.email ?? '',
          password: '********',
          ciudadReferencia: dataPersonales.ciudadReferencia ?? null,
          telefono: telefonoFiltrado,
          telefonos: dataPersonales.telefonos ?? [],
          local: localUsuarioBasico,
          prefijo: perfijoFiltrado,
        }

        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...dataEdit },
          }),
        )
        dispatch(
          setDatosEdicionBackup({
            key: tabId,
            formulario: { ...dataEdit },
          }),
        )
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }

      onLoader(false, '')
      onSetButtonAction(undefined)
    },
    [dispatch, tabId, localSession, onLoader, onSetButtonAction, setToast],
  )

  const onUndo = React.useCallback(() => {
    if (usuarioState?.codigo === 0) {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...defaultDatosEdicionUsuario },
        }),
      )
    } else if (usuarioState?.codigo > 0) {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...usuarioBackupState },
        }),
      )
    }
  }, [usuarioBackupState, dispatch, tabId, usuarioState])

  const onValidateUser = React.useCallback(() => {
    const errors: Array<string> = []

    if (
      usuarioBasicoState?.telefono === null ||
      usuarioBasicoState?.telefono === undefined ||
      usuarioBasicoState?.telefono === ''
    ) {
      errors.push('Se requiere el número de teléfono del usuario.')
    }

    return errors
  }, [usuarioBasicoState])

  const onValidateSAdm = React.useCallback(() => {
    const errors: Array<string> = []
    // if (
    //   usuarioState.personales?.tipoIdentificacion === null ||
    //   usuarioState.personales?.tipoIdentificacion === undefined ||
    //   parseInt(usuarioState.personales?.tipoIdentificacion.codigo) <= 0
    // ) {
    //   errors.push('Personales - Tipo de Identificacion: elija un tipo.')
    // }
    // if (
    //   usuarioState.personales?.identificacion === null ||
    //   usuarioState.personales?.identificacion === undefined ||
    //   usuarioState.personales?.identificacion === ''
    // ) {
    //   errors.push('Personales - Identificación: ingrese la identificación.')
    // }
    // if (
    //   usuarioState.personales?.nombres === null ||
    //   usuarioState.personales?.nombres === undefined ||
    //   usuarioState.personales?.nombres === ''
    // ) {
    //   errors.push('Personales - Nombres: ingrese la información.')
    // }
    // if (
    //   usuarioState.personales?.apellidos === null ||
    //   usuarioState.personales?.apellidos === undefined ||
    //   usuarioState.personales?.apellidos === ''
    // ) {
    //   errors.push('Personales - Apellidos: ingrese la información.')
    // }
    // if (
    //   usuarioState.personales?.telefonos === null ||
    //   usuarioState.personales?.telefonos === undefined ||
    //   usuarioState.personales?.telefonos.length === 0
    // ) {
    //   errors.push('Personales - Teléfono: ingrese al menos un teléfono.')
    // }
    // if (
    //   usuarioState.personales?.fechaNacimiento === null ||
    //   usuarioState.personales?.fechaNacimiento === undefined ||
    //   usuarioState.personales?.fechaNacimiento === ''
    // ) {
    //   errors.push(
    //     'Personales - Fecha de Nacimiento: ingrese la fecha de nacimiento del usuario.',
    //   )
    // }
    // if (
    //   usuarioState.personales?.estadoCivil === null ||
    //   usuarioState.personales?.estadoCivil === undefined ||
    //   usuarioState.personales?.estadoCivil.codigo <= 0
    // ) {
    //   errors.push('Personales - Estado civil: elija un estado.')
    // }
    // // if (
    // //   usuarioState.estudios?.estudios === null ||
    // //   usuarioState.estudios?.estudios === undefined ||
    // //   usuarioState.estudios?.estudios.codigo <= 0
    // // ) {
    // //   errors.push('Estudios - Estudios: elija un nivel.')
    // // }
    // if (
    //   usuarioState.login?.usuario === null ||
    //   usuarioState.login?.usuario === undefined ||
    //   usuarioState.login?.usuario === ''
    // ) {
    //   errors.push('Login - Usuario: ingrese la clave unica de identificación.')
    // }
    // if (
    //   usuarioState.login?.password === null ||
    //   usuarioState.login?.password === undefined ||
    //   usuarioState.login?.password === ''
    // ) {
    //   errors.push(
    //     'Login - Password: ingrese la password que se utilizará para su verificación.',
    //   )
    // }
    // if (
    //   usuarioState.login?.confirmarPassword === null ||
    //   usuarioState.login?.confirmarPassword === undefined ||
    //   usuarioState.login?.confirmarPassword === ''
    // ) {
    //   errors.push(
    //     'Login - Confirmación: ingrese la password de confirmación que se utilizará para su verificación.',
    //   )
    // } else if (
    //   usuarioState.login?.confirmarPassword !== usuarioState.login?.password
    // ) {
    //   errors.push(
    //     'Login - Confirmación: el password escrito no es igual a la confirmación.',
    //   )
    // }

    // if (
    //   usuarioState.login?.codigoPase === null ||
    //   usuarioState.login?.codigoPase === undefined ||
    //   usuarioState.login?.codigoPase === ''
    // ) {
    //   errors.push('Login - Codigo de Pase: ingrese un codigo de pase.')
    // }
    // if (
    //   usuarioState.personales?.tipoSangre === null ||
    //   usuarioState.personales?.tipoSangre === undefined ||
    //   usuarioState.personales?.tipoSangre.codigo <= 0
    // ) {
    //   errors.push('Personales - Tipo de Sangre: ingrese el tipo de sangre.')
    // }
    // if (
    //   usuarioState.personales?.ciudadReferencia === null ||
    //   usuarioState.personales?.ciudadReferencia === undefined ||
    //   usuarioState.personales?.ciudadReferencia.codigo < 0
    // ) {
    //   errors.push(
    //     'Personales - Lugar de Residencia: Elija un Lugar de Residencia.',
    //   )
    // }
    // if (
    //   usuarioState.login?.lugarCC === null ||
    //   usuarioState.login?.lugarCC === undefined ||
    //   usuarioState.login?.lugarCC.codigo <= 0
    // ) {
    //   errors.push('Login - Local/CC: debe eligir una opción.')
    // }

    // if (usuarioState?.codigo === 0) {
    //   if (usuarioState.login?.tipoUsuario?.codigo <= 0) {
    //     errors.push(
    //       'Login - Tipo de Usuario: Elija un tipo de usuario ya sea ADM | USU.',
    //     )
    //   }
    // }

    if (
      usuarioState.personales?.telefonos &&
      usuarioState.personales?.telefonos.length === 0
    ) {
      errors.push('Se requiere el número de teléfono del usuario.')
    }

    return errors
  }, [usuarioState])

  const onHandleSave = React.useCallback(async () => {
    let errors: Array<string> = []
    const result = validationGroupRef.current.instance.validate()
    const rolUsuario = usuarioSesion.rol

    console.log('errors:', errors)
    console.log('result:', result)
    if (result.isValid) {
      let usuarioSave: SavePersonal

      if (rolUsuario === ERolUser.SADM) {
        errors = onValidateSAdm()
        if (errors.length > 0) {
          setToast(ToastTypes.Warning, 'Por favor revisar los errores')
          setArrayErrors(errors)
          onSetButtonAction(undefined)
          return false
        }

        let telefonos: Array<Contactos> = []
        if (usuarioState.personales?.telefonos?.length > 0) {
          telefonos = usuarioState.personales?.telefonos.map((x) => {
            const telefono: Contactos = {
              numero: x?.numero ?? '',
              descripcion: x?.descripcion ?? '',
              tipo: x?.tipo ?? 0,
            }
            return telefono
          })
        }

        const fechaNa = DateUtils.format(
          usuarioState.personales?.fechaNacimiento,
          'dd/MM/yyyy',
          'yyyy-MM-dd',
        )
        const infoRegistro: any = {
          codigo: usuarioState?.codigo ?? 0,
          nombres: usuarioState.personales?.nombres ?? '',
          apellidos: usuarioState.personales?.apellidos ?? '',
          idsesion: usuarioState.login?.usuario ?? '',
          cedula: usuarioState.personales?.identificacion ?? '',
          codiess: usuarioState.personales?.codigoIess ?? '',
          direccion: usuarioState.personales?.direccion ?? '',
          numero: usuarioState.personales?.numeroCasa ?? '',
          fnacimiento: fechaNa ?? '',
          email: usuarioState.personales?.email ?? '',
          firma: '',
          ciudadCodigo: usuarioState.personales?.ciudadReferencia?.codigo ?? 0,
          ciudadNombre: usuarioState.personales?.ciudadReferencia?.nombre ?? '',
          ideCodigo: usuarioState.personales?.tipoIdentificacion?.codigo ?? '',
          sexo: usuarioState.personales?.genero?.value ?? 0,
          ecivil: usuarioState.personales?.estadoCivil?.codigo ?? 0,
          cargas: usuarioState.personales.cargasFamiliares ?? 0,
          foto: '',
          nivelCodigo: usuarioState.estudios?.estudio?.codigo ?? 0,
          estado: usuarioState.personales?.estado?.codigo ?? 0,
          tiposangre: usuarioState.personales?.tipoSangre?.descripcion ?? '',
          titulos: usuarioState.estudios?.titulos ?? '',
          cursos: usuarioState.estudios?.cursos ?? '',
          ciudadOrigen: usuarioState.personales?.ciudadOrigen?.codigo ?? 0,
          contactos: telefonos,
          registroCognito: true,
          tipo: ERolUser.ADM,
          mantenimientoCredenciales: usuarioState.login?.maintenanceCredentials,
        }

        if (usuarioState.login?.maintenanceCredentials) {
          infoRegistro.password_old = encriptarConKey(
            usuarioState.login?.password_old,
            config['key'],
          )
          infoRegistro.password = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
          infoRegistro.pase = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
        } else {
          infoRegistro.password = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
          infoRegistro.pase = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
          infoRegistro.pase = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
        }

        usuarioSave = {
          infoRegistro: infoRegistro,
          infoLocal: {
            codigo: usuarioState.login?.lugarCC?.codigo ?? 0,
          },
        }
      } else if (rolUsuario === ERolUser.ADM || rolUsuario === null) {
        errors = onValidateUser()
        if (errors.length > 0) {
          setToast(ToastTypes.Warning, 'Por favor revisar los errores')
          setArrayErrors(errors)
          onSetButtonAction(undefined)
          return false
        }
        if (contratoEmpresa?.numUsuariosContrato !== 0) {
          if (
            usuarioState?.maximoUsuarios >= contratoEmpresa?.numUsuariosContrato
          ) {
            setToast(
              ToastTypes.Info,
              'Usted ha alcanzado el número máximo de usuarios permitidos para este plan.',
            )
            return false
          }
        }

        const telefonos: Array<Contactos> = []
        if (usuarioBasicoState?.telefono != null) {
          telefonos.push({
            numero:
              usuarioBasicoState?.prefijo?.value +
              '' +
              usuarioBasicoState?.telefono,
            descripcion: '',
            tipo: 0,
          })
        }
        const infoRegistro: any = {
          codigo: usuarioBasicoState?.codigo ?? 0,
          nombres: usuarioBasicoState?.nombres ?? '',
          apellidos: usuarioBasicoState?.apellidos ?? '',
          idsesion: usuarioBasicoState?.usuario ?? '',
          cedula: usuarioBasicoState.identificacion ?? '',
          codiess: '',
          direccion: '',
          numero: '',
          fnacimiento: DateUtils.getCurrentDateAsString(formatoFechasApi) ?? '',
          email: usuarioBasicoState?.usuario ?? '',
          firma: '',
          ciudadCodigo: usuarioBasicoState?.ciudadReferencia?.codigo ?? 0,
          ciudadNombre: usuarioBasicoState?.ciudadReferencia?.nombre ?? '',
          ideCodigo: '',
          sexo: 0,
          ecivil: 0,
          cargas: 0,
          foto: '',
          nivelCodigo: 0,
          estado: 1,
          tiposangre: '',
          titulos: '',
          cursos: '',
          ciudadOrigen: 0,
          contactos: telefonos,
          registroCognito: true,
          tipo: ERolUser.ADM,
          mantenimientoCredenciales: usuarioState.login?.maintenanceCredentials,
          password_old: '',
        }
        // no se envia para que no se los actualice
        if (usuarioBasicoState?.codigo > 0) {
          infoRegistro.perfilBasico = true
        } else {
          infoRegistro.pase = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
          infoRegistro.password = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
        }

        if (usuarioState.login?.maintenanceCredentials) {
          infoRegistro.password_old = encriptarConKey(
            usuarioState.login?.password_old,
            config['key'],
          )
          infoRegistro.password = encriptarConKey(
            usuarioState.login?.password,
            config['key'],
          )
        }

        usuarioSave = {
          infoRegistro: infoRegistro,
          infoLocal: {
            codigo: usuarioBasicoState?.local?.codigo ?? 0,
          },
        }
      }

      onLoader(true, 'Guardando...')

      try {
        const dataSave = await cuentaService.usuarioActualizar(usuarioSave)
        console.log('dataSave:', dataSave)

        if (
          dataSave !== null &&
          dataSave !== undefined &&
          dataSave['error'] === false
        ) {
          setToast(ToastTypes.Success, dataSave['message'])
          const asignaciones: Array<Asignacion> = []
          if (usuarioState.nomina?.descuentos.codigoDescuento !== 0) {
            asignaciones.push({
              codigo: 0,
              vinculacion: dataSave['auto'],
              tipo: 'NOMINA EMPLEADO',
              planCuentasCodigo:
                usuarioState.nomina?.descuentos?.codigoDescuento ?? 0,
              transaccion: String('DESCUENTO NOMINA'),
            })
          }

          // const asigData = await asignacionesService.registrarAsignacion(asignaciones)
          const asigNomina = await PersonalServices.saveAssigmentNomina(
            usuarioState.nomina?.descuentos.nomina,
            usuarioState.nomina?.descuentos.descuentoNomina,
          )
          dispatch(
            setNameTab({
              key: tabId,
              codigo: dataSave?.auto?.usuario ?? 0,
              nombre: usuarioState?.personales?.nombres ?? '',
            }),
          )
          if (dataSave?.auto?.usuario?.cognito?.status === 'danger_cognito') {
            setToast(
              ToastTypes.Warning,
              'No se pudo registrar el usuario en AWS - Cognito',
            )
          }
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...EUsuarioButtons,
                Guardar: false,
                Imprimir: true,
              },
            }),
          )
        } else {
          setToast(ToastTypes.Danger, dataSave['message'])
        }
        onSetButtonAction(undefined)
      } catch (error) {
        setToast(ToastTypes.Danger, error.message)
      }
      onSetButtonAction(undefined)
      onLoader(false, '')
    } else {
      onSetButtonAction(undefined)
      result?.brokenRules.forEach(function (item: any) {
        const limpiaTexto: any = item?.message.replace('-', '')
        errors.push(limpiaTexto)
      })

      setToast(ToastTypes.Warning, 'Por favor revisar los errores')
      setArrayErrors(errors)
      setShowErrorPopup(true)
    }
  }, [
    onValidateUser,
    onSetButtonAction,
    onValidateSAdm,
    setToast,
    contratoEmpresa,
    usuarioState,
    usuarioSesion,
    onLoader,
    dispatch,
    tabId,
    usuarioBasicoState,
  ])

  const handleButtonClick = React.useCallback(
    (buttonAction: ButtonTypes) => {
      switch (buttonAction) {
        case ButtonTypes.undo:
          if (tabs.current === tabId) onUndo()
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) onHandleSave()
          break
        case ButtonTypes.edit:
          modoEdicion(loading, info?.info)
          break
        default:
          break
      }

      dispatch(clearButtonClick(tabId))
    },
    [dispatch, tabId, tabs, onUndo, onHandleSave, modoEdicion, info, loading],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(loading, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <Dialog ref={dialogRef} />
      {showErrorPopup && (
        <VisualizaError
          titulo="Acatha"
          mensaje={'Error al guardar'}
          content={
            <ListErrosValidationForm
              mainMessage={'Revise los siguientes errores:'}
              errorsList={arrayErrors}
              color={'danger'}
            />
          }
          onOk={() => setShowErrorPopup(false)}
        />
      )}
      <BlockUi
        loader={LoadingIndicator}
        tag="div"
        blocking={loader?.show}
        message={loader?.mensaje}
      >
        <fieldset>
          <div>
            <RowContainer>
              <CustomCol xs="12" md="12">
                <ValidationGroup id={'formClientes'} ref={validationGroupRef}>
                  <ValidationSummary />
                  {usuarioSesion?.rol === ERolUser.SADM && (
                    <CNav variant="tabs" role="tablist" className="mt-2">
                      <CNav variant="tabs">
                        <CNavItem>
                          <CNavLink
                            onClick={() => {
                              setShowTab('personales')
                            }}
                            active={showTab === 'personales'}
                          >
                            {'Personales'}
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            onClick={() => {
                              setShowTab('estudios')
                            }}
                            active={showTab === 'estudios'}
                          >
                            {'Estudios'}
                          </CNavLink>
                        </CNavItem>
                        {/* <CNavItem>
                        <CNavLink
                          onClick={() => {
                            setShowTab('extras')
                          }}
                          active={showTab === 'extras'}
                        >
                          {'Extras'}
                        </CNavLink>
                      </CNavItem> */}
                        <CNavItem>
                          <CNavLink
                            onClick={() => {
                              setShowTab('login')
                            }}
                            active={showTab === 'login'}
                          >
                            {'Login'}
                          </CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink
                            onClick={() => {
                              setShowTab('puntoVenta')
                            }}
                            active={showTab === 'puntoVenta'}
                          >
                            {'Punto Venta'}
                          </CNavLink>
                        </CNavItem>
                        {/* <CNavItem>
                        <CNavLink
                          onClick={() => {
                            setShowTab('rentas')
                          }}
                          active={showTab === 'rentas'}
                        >
                          {'Rentas'}
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          onClick={() => {
                            setShowTab('nomina')
                          }}
                          active={showTab === 'nomina'}
                        >
                          {'Nomina'}
                        </CNavLink>
                      </CNavItem> */}
                      </CNav>

                      <CTabContent
                        style={{
                          overflowY: 'unset',
                          width: '100%',
                          marginBottom: '10px',
                        }}
                      >
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'personales'}
                        >
                          <TPersonales tabId={tabId} />
                        </CTabPane>
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'estudios'}
                        >
                          <TEstudios tabId={tabId} />
                        </CTabPane>
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'extras'}
                        >
                          <TExtras tabId={tabId} />
                        </CTabPane>
                        <CTabPane role="tabpanel" visible={showTab === 'login'}>
                          <TLogin tabId={tabId} />
                        </CTabPane>
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'rentas'}
                        >
                          <TRenta tabId={tabId} />
                        </CTabPane>
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'nomina'}
                        >
                          <TNomina tabId={tabId} />
                        </CTabPane>
                        <CTabPane
                          role="tabpanel"
                          visible={showTab === 'puntoVenta'}
                        >
                          <TPuntoVenta tabId={tabId} />
                        </CTabPane>
                      </CTabContent>
                    </CNav>
                  )}
                  {(usuarioSesion?.rol === ERolUser.ADM ||
                    usuarioSesion?.rol === null) && (
                    <TUsuarioBasico tabId={tabId} setToast={setToast} />
                  )}
                </ValidationGroup>
              </CustomCol>
            </RowContainer>
          </div>
        </fieldset>
      </BlockUi>
    </div>
  )
}
export default React.memo(Usuario)

export const EUsuarioButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
  Eliminar: false,
}
export const defaultDatosEdicionConfig: TMainEdition = {
  imprimir: false,
  loader: {
    mensaje: 'Cargando...',
    show: false,
  },
  mensajeError: '',
  mensajesErrors: [],
  tieneError: false,
  loading: true,
  desdePlantilla: false,
  codigo: 0,
  maximoUsuarios: 0,
}
export const defaultDatosEdicionUsuario: UsuarioDatosEdicion = {
  ...defaultDatosEdicionConfig,
  personales: { ...defaulDatosEdicionTabDatosPersonales },
  login: { ...defaulDatosEdicionTabDatosLogin },
  estudios: { ...defaultEstudiosUsuarioData },
  nomina: {
    descuentos: {
      numeroDescuento: '',
      codigoDescuento: 0,
      descripcionDescuento: '',
      descuentoNomina: [],
      nomina: [],
      radioOption: null,
      seleccionadoDescuento: null,
    },
  },
  usuarioBasico: {
    codigo: 0,
    identificacion: '',
    nombres: '',
    apellidos: '',
    email: '',
    usuario: '',
    password: '',
    ciudadReferencia: null,
    telefono: '',
    telefonos: [],
    local: null,
  },
}
