import { CCol, CRow, CSpinner } from '@coreui/react-pro'
import React from 'react'
import './LoadingIndicator.scss'

interface ILoadingIndicatorProps extends React.PropsWithChildren {
  mensaje: string
}

const SmallLoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = (
  props,
) => {
  return (
    <>
      <CRow>
        <CCol className="d-flex justify-content-center">
          <CSpinner color="primary" size="sm" />
        </CCol>
      </CRow>
      <CRow>
        <CCol className="d-flex justify-content-center">
          <strong>{props.mensaje ?? 'Cargando...'}</strong>
        </CCol>
      </CRow>
    </>
  )
}

const LoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = (
  props,
) => {
  return (
    <>
      <div className="container fluid">
        <div className="row justify-content-center">
          {/*
          <div className='loader'>
          </div>
          */}

          <div className="sk-flow">
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
          </div>
        </div>
        <div className="row justify-content-center">
          {/*
          <strong className="textColor" >
            {props.mensaje ?? 'Cargando...'}
          </strong>
          */}
        </div>
      </div>
    </>
  )
}

export default LoadingIndicator
export { SmallLoadingIndicator }
