import React, { useEffect } from 'react'
import { ValidationGroup } from 'devextreme-react/validation-group'
import CustomCol from '../../../../../views/componentes/colContainer'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { Cliente, TipoIdentificacion } from '../../../../ventas/types/types'
import LocalesLookUp from '../../../localesLookUp'
import { LocalOption } from '../../../localesLookUp/store/type'
import { CAlert, CCard, CCardBody, CCol, CForm, CLink, CTooltip } from '@coreui/react-pro'
import {
  CheckBox,
  FileUploader,
  NumberBox,
  TextArea,
  ValidationSummary,
} from 'devextreme-react'
import {
  Validator,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
} from 'devextreme-react/validator'
import { localidadesService } from '../../../../../services/localidades.service'
import { Ciudad } from '../../../../ventas/store/types'
import { Pais, Provincia, ToastTypes } from '../../../../../store/types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { setCurrentFunction } from '../../store/modalAdminLocalesReducer'
import TiposLocalesLookUp from '../../../tiposLocalesLookUp'
import { utilidades } from '../../../../../helpers/utilidades'
import LocalidadesLookUp from '../../../localidadesLookUp/localidadesLookUp'
import { GlobalLabel } from '../../../../../views/componentes/globalLabel/globalLabel'
import { OptionCiudad } from '../../../../../containers/component/formLocal/types/types'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import { isMobile } from 'react-device-detect'
import ImagenEmpresa from '../../../../../containers/component/formEmpresa/component/imagenEmpresa'
import { v4 as uuidv4 } from 'uuid';
import ModalCuentas from '../../../../contabilidad/pages/planCuentas/modalCuentas'
import { LocalEdicion } from '../../store/types'
import EstadosLookUp from '../../../estadosLookUp'
import { EstadosOption } from '../../../estadosLookUp/store/type'
import { setDpCiudades } from '../../../../bancos/general/generalBancosReducer'
import { OptionTiposLocales } from '../../../tiposLocalesLookUp/types/types'
import RadioGroup from 'devextreme-react/radio-group';
import CIcon from '@coreui/icons-react';
import { cilPencil } from '@coreui/icons';
import AtributosLocal, { TAtributos } from './atributos'
import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';
import { sidebarService } from '../../../../../services/sidebar.service'
import { adminLocales } from '../../service/locales.service'

const FILE_SIZE = 1000000

const dpClases = [
  {
    value: 0,
    label: 'Almacen',
  },
  {
    value: 1,
    label: 'Bodega general',
  },
  {
    value: 2,
    label: 'Bodega sucursales',
  },
]

const transaccionesGrupos = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Negativos',
  },
]

const dpListaPrecios = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Si',
  },
]

interface INuevoProps extends React.PropsWithChildren {
  onChanged: (data) => void
  onCancel?: () => void
  funcion: string
  currentTab: string
  setCurrenTab: (currentTab: string) => void
  resetFunction: () => void
  setToast: (sms: string, type: ToastTypes) => void
  playLoader: () => void
  stopLoader: () => void
  cliente?: Cliente | null,
  modulo: number
}

const Nuevo = (props: INuevoProps) => {
  const {
    onChanged,
    funcion,
    setToast,
    resetFunction,
    playLoader,
    stopLoader,
    modulo,
    cliente
  } = props
  const dispatch = useDispatch()

  const allowExtention = ['.jpg', '.jpeg']
  const imageLogo = React.useRef<any>()
  const validationGroupRef = React.useRef<any>()

  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => state.global.session?.local);

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [modalPlanCuentas, setModalPlanCuentas] = React.useState<boolean>(false)

  const [imageSource, setImageSource] = React.useState<string>('')
  const [nombre, setNombre] = React.useState<string>('')
  const [codigoUAF, setCodigoUAF] = React.useState<string>('')
  const [codigo, setCodigo] = React.useState<number>(0)
  const [direccion, setDireccion] = React.useState<string>('')
  const [telefono, setTelefono] = React.useState<string>('')
  const [gerente, setGerente] = React.useState<string>('')
  const [stockweb, setStockWeb] = React.useState<boolean>(false)
  const [siglas, setSiglas] = React.useState<string>('')
  const [desc, setDesc] = React.useState<number>(0)
  const [clase, setClase] = React.useState<any>(dpClases[0])
  const [file, setFile] = React.useState<File | null>(null)
  const [errorsLogo, setErrorsLogo] = React.useState<any>([])
  const [ciudades, setCiudades] = React.useState<Array<OptionCiudad>>([])
  const [ciudad, setCiudad] = React.useState<OptionCiudad | null>(null)
  const [transaccionesStock, setTransaccionesStock] = React.useState<any>(
    transaccionesGrupos[0],
  )
  const [estado, setEstado] = React.useState<EstadosOption | null>()
  const [tipos, setTipos] = React.useState<Array<any>>([])
  const [tipo, setTipo] = React.useState<OptionTiposLocales | null>()
  const [ccInventariosCod, setCCInventariosCod] = React.useState<any | null>()
  const [ccInventarios, setCCInventarios] = React.useState<any | null>()
  const [pertenece, setPertenece] = React.useState<LocalOption | null>()
  const [dpPertenece, setDpPertenece] = React.useState<Array<LocalOption> | []>([])
  const [estados, setEstados] = React.useState<Array<EstadosOption> | []>([])
  const [siglasBOD, setSiglasBOD] = React.useState<string>("")
  const [utilizarListaPrecios, setUtilizarListaPrecios] = React.useState<{ value: number, label: string } | null>(null)
  const [openAtributos, setOpenAtributos] = React.useState<boolean>(false);
  const [atributos, setAtributos] = React.useState<Array<TAtributos>>([]);

  const validarFormatoPermitido = React.useCallback((file) => {
    let resRetorno: boolean = false
    let smsErrorsLogo: any = []
    let extention: string = ''
    if (file && file.length > 0) {
      extention = utilidades.getExtension(file[0].name)

      extention = extention.toLowerCase()

      if (allowExtention.includes('.' + extention)) {
        smsErrorsLogo = []
        resRetorno = true
      } else {
        const sms: string = 'El formato permitido es .jpg'
        smsErrorsLogo.push({ sms })
        resRetorno = false
      }
      setErrorsLogo(smsErrorsLogo)
    }
    return resRetorno
  }, [allowExtention])

  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])



  const initDatos = React.useCallback(async () => {
    const dataLocalEdition = { ...localInit }

    const infoParse: LocalEdicion = {
      ...dataLocalEdition,
    }
    if (estados.length > 0) {
      infoParse.estado = estados[1]
    }
    if (tipos.length > 0) {
      infoParse.tipo = tipos[1]
    }
    if (ciudades.length > 0) {
      infoParse.ciudad = ciudades[1]
    }
    console.log('infoParse:', infoParse)

    if (modulo === 5 && cliente !== null) {
      infoParse.nombre = cliente.nombres + " - " + cliente.identificacion
      infoParse.direccion = cliente.direccion
      infoParse.telefono = cliente.telefono
      infoParse.gerente = cliente.nombres
      infoParse.tipo = tipos[1]
    }

    if (infoParse.codigo === 0 && infoParse.nombre !== "") {
      const defaultSiglas = infoParse.nombre.substring(-1, 3);
      infoParse.siglas = String(defaultSiglas).toUpperCase() ?? ""
    }

    setNombre(infoParse.nombre)
    setSiglas(infoParse.siglas)
    setSiglasBOD(infoParse.siglasBOD)
    setDesc(infoParse.descuento)
    setCiudad(infoParse.ciudad)
    setDireccion(infoParse.direccion)
    setTelefono(infoParse.telefono)
    setGerente(infoParse.gerente)
    setTipo(infoParse.tipo)
    setTransaccionesStock(infoParse.controlaKardex)
    setCodigoUAF(infoParse.uaf)
    setEstado(infoParse.estado)
    setStockWeb(infoParse.web)
    setCodigo(infoParse.codigo)
    setTransaccionesStock(transaccionesGrupos[0])
    setUtilizarListaPrecios(dpListaPrecios[1])
  }, [estados, tipos, ciudades, cliente, modulo])

  const onValueChangeEstados = React.useCallback((data) => {
    if (data.value) {
      setClase(data.value)
    }
  }, [])

  const onValueChangeTStock = React.useCallback((data) => {
    if (data.value) {
      setTransaccionesStock(data.value)
    }
  }, [])

  const onValueChangeListaPrecios = React.useCallback((data) => {
    if (data.event !== undefined) {
      setUtilizarListaPrecios(data.value)
    }
  }, [])

  const onValueChangeSiglasBOD = React.useCallback((data) => {
    if (data.value) {
      setSiglasBOD(data.value)
    }
  }, [])

  const onValueChangeNombre = React.useCallback((data) => {
    if (data.value) {
      setNombre(data.value)
      const defaultSiglas = data.value.substring(-1, 3);
      setSiglas(String(defaultSiglas).toUpperCase() ?? "")
    }
  }, [])

  const onValueChangeSiglas = React.useCallback((data) => {
    if (data.value) {
      setSiglas(data.value)
    }
  }, [])

  const onValueChangeDesc = React.useCallback((data) => {
    if (data.value) {
      setDesc(data.value)
    }
  }, [])

  const onValueChangeCiudad = React.useCallback((data) => {
    if (data) {
      setCiudad(data)
    }
  }, [])

  const onValueChangeDireccion = React.useCallback((data) => {
    if (data.value) {
      setDireccion(data.value)
    }
  }, [])

  const onValueChangeTelefono = React.useCallback((data) => {
    if (data.value) {
      setTelefono(data.value)
    }
  }, [])

  const onValueChangeGerente = React.useCallback((data) => {
    if (data.value) {
      setGerente(data.value)
    }
  }, [])

  const onValueChangeTipos = React.useCallback((data) => {
    if (data) {
      setTipo(data)
    }
  }, [])

  const onValueChangePertenece = React.useCallback((data) => {
    if (data) {
      setPertenece(data)
    }
  }, [])

  const onValueChangeUAF = React.useCallback((data) => {
    if (data.value) {
      setCodigoUAF(data.value)
    }
  }, [])

  const onValueChangeestado = React.useCallback((data) => {
    if (data) {
      setEstado(data)
    }
  }, [])

  const onValueChangeStockWeb = React.useCallback((data) => {
    console.log("onValueChangeStockWeb", data)
    if (data.event !== undefined) {
      setStockWeb(data.value)
    }
  }, [])

  const onValueCcInventarios = React.useCallback((data) => {
    if (data.value) {
      setCCInventarios(data.value)
    }
  }, [])

  const onReset = React.useCallback(() => {
    resetFunction()
  }, [resetFunction])


  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Warning)

      setShowErrorPopup(true)
      setShowErrorMessages(result.brokenRules)

      return false
    } else {

      const dataLocal = {
        codigo: codigo,
        clase: clase.value,
        nombre: nombre,
        siglas: siglas,
        ciudad: ciudad?.codigo ?? -1,
        direccion: direccion,
        telefono: telefono,
        gerente: gerente,
        tipo: tipo.value ?? -1,
        pertenece: pertenece?.codigo ?? -1,
        kardex: transaccionesStock?.value, //false
        estado: estado.codigo ?? -1,
        siglasBOD: siglasBOD,
        web: stockweb,
        empresaCodigo: empresa?.codigo,
        uaf: codigoUAF,
        descuento: desc,
        usuarioDokan: '',
        passwordDokan: '',
        nameLogo: null,
        listaPrecios: utilizarListaPrecios?.value ?? -1,
        codeFileLogo: '',
      }
      console.log("dataLocal : ", dataLocal)
      console.log("file : ", file)

      if (file !== null) {

        const extentionFile: string = file['name'].split('.').pop() ?? "";

        if (allowExtention.some(w => extentionFile.includes(w))) {
          stopLoader()
          setToast("El formato del logo ingresado no es permitido.", ToastTypes.Info);
          return false;
        }

        dataLocal.nameLogo = (empresa && empresa['codigo']) + '_' + (local && local['codigo']) + "." + extentionFile;
        let fileCoded: any = "";

        if (typeof (file) === 'object') {
          await convertBase64(file).then(data => {
            fileCoded = data;
          }).catch(data => {
            fileCoded = data;
          });
        } else {
          fileCoded = file
        }
        dataLocal.codeFileLogo = fileCoded;
      }

      if (dataLocal?.nameLogo) {
        if (dataLocal.nameLogo.includes("null")) {
          dataLocal.nameLogo = '';
        } else if (dataLocal.nameLogo.includes("/")) {
          const posicionBarra = dataLocal.nameLogo.indexOf("/");
          if (posicionBarra !== -1) {
            dataLocal.nameLogo = dataLocal.nameLogo.substring(posicionBarra + 1).trim();
          }
        }
      }

      console.log("dataLocal :", dataLocal)

      try {
        playLoader()
        const data = await sidebarService.datosLocalActualizar(dataLocal);
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning);
        } else {
          setToast(data['message'], ToastTypes.Success);
          const dataLocal = await adminLocales.getLocal(data.auto);
          onChanged(dataLocal['auto'])
          resetFunction()
        }
      } catch (error) {
        console.error(error);
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }

    }
  }, [
    playLoader,
    onChanged,
    stopLoader,
    setToast,
    resetFunction,
    ciudad,
    codigoUAF,
    desc,
    direccion,
    estado,
    clase,
    gerente,
    siglasBOD,
    nombre,
    siglas,
    stockweb,
    pertenece,
    telefono,
    tipo,
    transaccionesStock,
    codigo,
    utilizarListaPrecios,
    empresa,
    convertBase64, file, local, allowExtention
  ])

  const onSelectedFilesChangedDec = React.useCallback(
    async (e) => {
      if (e.value && e.value.length > 0) {
        setFile(e.value[0])
        for (const item of e.value) {
          let fileCoded: any = ''
          await convertBase64(item)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })
          setImageSource(fileCoded)
        }
      }
    },
    [convertBase64],
  )

  const onChangeFile = React.useCallback(
    (data) => {
      if (data.value) {
        const validado: any = validarFormatoPermitido(data.value);
        if (validado) {
          onSelectedFilesChangedDec(data)
        }
      }
    },
    [validarFormatoPermitido, onSelectedFilesChangedDec],
  )

  React.useEffect(() => {
    if (funcion === 'Guardar') {
      handleSubmit()
    } else if (funcion === 'Limpiar') {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcion])

  React.useEffect(() => {
    initDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estados, tipos, ciudades])

  return (
    <>
      {showErrorPopup && (
        <PopupContent
          show={showErrorPopup}
          title={'Acatha'}
          subTitle={'Por favor revise las siguientes advertencias:'}
          onClose={() => {
            setShowErrorPopup(false)
            setShowErrorMessages([])
          }}
          size={'sm'}
          height={'auto'}
          canClose={true}
        >
          <CAlert color="danger" className="mt-2">
            <ul style={{ listStyle: 'none' }}>
              {showErrorMessages.map(function (item, id) {
                return <li key={id}>{item['message']}</li>
              })}
            </ul>
          </CAlert>
        </PopupContent>
      )}
      {/* {openAtributos === true &&
        <AtributosLocal
          show={openAtributos}
          onClose={() => { setOpenAtributos(!openAtributos); }}
          onChange={() => {
            setOpenAtributos(false)
            // initDatosLocalEmpresa(true)
          }}
          atributos={}
          setToast={setToast}
        />
      } */}
      {modalPlanCuentas === true &&
        <ModalCuentas
          cargarCuentas={(data) => {
            if (data.codigo) {
              setCCInventariosCod(data.codigo)
              setCCInventarios(data.numero + data.descripcion)
              setModalPlanCuentas(false)
            }
          }}
          onClose={() => setModalPlanCuentas(!modalPlanCuentas)}
          show={modalPlanCuentas}
        />
      }
      {openAtributos === true &&
        <AtributosLocal
          show={openAtributos}
          onClose={() => { setOpenAtributos(!openAtributos); }}
          onChange={(data) => {
            console.log("data", data)
            setAtributos(data.agregar ?? [])
            setOpenAtributos(false)
          }}
          atributos={atributos}
          setToast={setToast}
        />
      }
      <fieldset>
        <ValidationGroup ref={validationGroupRef}>
          <CCardBody>
            <RowContainer>
              <CustomCol>
                <CForm className="row mb-2">
                  <div className="dx-field-label col-lg-2 col-md-3 col-sm-12">
                    {'Clase:'}
                  </div>
                  <CustomCol className="dx-field-value mt-1">
                    <CCol lg="9" md="6" sm="12">
                      <RadioGroup
                        name="clase"
                        items={dpClases}
                        defaultValue={dpClases[0]}
                        value={clase}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onValueChangeEstados}
                      />
                    </CCol>
                  </CustomCol>
                </CForm>
              </CustomCol>
            </RowContainer>

            <hr />
            {
              !isMobile &&
              <RowContainer  >
                <CustomCol lg='12'  >
                  <ValidationSummary id='summary'></ValidationSummary>
                </CustomCol>
              </RowContainer>
            }

            <RowContainer>
              <CustomCol md={6}>
                <RowContainer>


                  <CustomCol md={8}>
                    <Labeled label='Nombre:' >
                      <TextBox
                        name="nombre"
                        value={nombre}
                        onValueChanged={onValueChangeNombre}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <Validator>
                          <RequiredRule message="- Nombre Local: Este campo es requerido" />
                          <StringLengthRule
                            min="4"
                            message="- Nombre Local: Este campo debe tener al menos 4 caracteres"
                          />
                          <StringLengthRule
                            max="250"
                            message="- Nombre Local: Este campo no puede tener mas de 250 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={4}>
                    <Labeled label='Siglas:' >
                      <TextBox
                        value={siglas}
                        placeholder=""
                        onValueChanged={onValueChangeSiglas}
                        showClearButton={true}
                        width="100%"
                        onEnterKey={() => {
                          handleSubmit()
                        }}
                      >
                        <Validator>
                          <RequiredRule message="- Siglas: Este campo es requerido" />
                          <StringLengthRule
                            min="3"
                            message="- Siglas: Este campo debe tener al menos 3 caracteres"
                          />
                          <StringLengthRule
                            max="10"
                            message="- Siglas: Este campo no puede tener mas de 10 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={3}>
                    <Labeled label='Inv:' >
                      <TextBox
                        name="siglasBOD"
                        value={siglasBOD}
                        onValueChanged={onValueChangeSiglasBOD}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <Validator>
                          <StringLengthRule
                            max="10"
                            message="- Inv: Este campo no puede tener mas de 10 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={3}>
                    <Labeled label='Desc:' >
                      <NumberBox
                        name="desc"
                        value={desc}
                        onValueChanged={onValueChangeDesc}
                        min={0}
                        max={100}
                        step={1}
                        showSpinButtons={true}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <Validator>
                          <RequiredRule message="- Descuento: Ingrese un valor en descuento o al menos (0)" />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={6}>

                    <Labeled label='Ciudad:' >
                      <LocalidadesLookUp
                        onChanged={onValueChangeCiudad}
                        onChangedOptions={(opt) => { setCiudades(opt) }}
                        selected={ciudad}
                        allowEdit
                        allowClear
                      />
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={12}>
                    <Labeled label='Direccion:' >
                      <TextArea
                        value={direccion}
                        placeholder=""
                        onValueChanged={onValueChangeDireccion}
                        showClearButton={true}
                        width="100%"
                        onEnterKey={() => {
                          handleSubmit()
                        }}
                      >
                        <Validator>
                          <RequiredRule message="- Dirección: Este campo es requerido" />
                          <StringLengthRule
                            min="8"
                            message="- Dirección: Este campo debe tener al menos 8 caracteres"
                          />
                          <StringLengthRule
                            max="200"
                            message="- Dirección: Este campo no puede tener mas de 200 caracteres"
                          />
                        </Validator>
                      </TextArea>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={5}>
                    <Labeled label='Teléfono:' >
                      <TextBox
                        value={telefono}
                        placeholder=""
                        onValueChanged={onValueChangeTelefono}
                        showClearButton={true}
                        width="100%"
                        onEnterKey={() => {
                          handleSubmit()
                        }}
                      >
                        <Validator>
                          <RequiredRule message="- Teléfono: Este campo es requerido" />
                          <StringLengthRule
                            min="6"
                            message="- Teléfono: Este campo debe tener al menos 6 caracteres"
                          />
                          <StringLengthRule
                            max="15"
                            message="- Teléfono: Este campo no puede tener mas de 15 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={7}>
                    <Labeled label='Gerente:' >
                      <TextBox
                        name="gerente"
                        value={gerente}
                        onValueChanged={onValueChangeGerente}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <Validator>
                          <RequiredRule message="- Gerente: Este campo es requerido" />
                          <StringLengthRule
                            max="250"
                            message="- Gerente: Este campo no puede tener mas de 250 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>


                  <CustomCol md={6}>
                    <Labeled label='Tipo:' >
                      <TiposLocalesLookUp
                        selected={tipo}
                        onChanged={onValueChangeTipos}
                        onChangedOptions={(data) => setTipos(data)}
                      >
                        <Validator>
                          <RequiredRule message="- Tipo: Este campo es requerido" />
                        </Validator>
                      </TiposLocalesLookUp>
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={6}>
                    <Labeled label='Pertenece:' >
                      <LocalesLookUp
                        disabled={clase.value === 0}
                        selected={pertenece}
                        onChanged={onValueChangePertenece}
                        onChangedOptions={(data) => setDpPertenece(data)}
                      >
                        <Validator>
                          <RequiredRule message="- Pertenece: Este campo es requerido" />
                        </Validator>
                      </LocalesLookUp>
                    </Labeled>
                  </CustomCol>



                  <CustomCol md={6}>
                    <Labeled label='Codigo UAF Age:' >
                      <TextBox
                        name="Codigo UAF Age"
                        value={codigoUAF}
                        onValueChanged={onValueChangeUAF}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <Validator>
                          <StringLengthRule
                            max="9"
                            message="- Codigo UAF Age: Este campo no puede tener mas de 9 caracteres"
                          />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CustomCol>


                  <CustomCol md={6}>
                    <Labeled label='Estado:' >
                      <EstadosLookUp
                        onChanged={onValueChangeestado}
                        onChangedOptions={(data) => {
                          setEstados(data)
                        }}
                        selected={estado}
                      >
                        <Validator>
                          <RequiredRule message="- Estado: Este campo es requerido" />
                        </Validator>
                      </EstadosLookUp>
                    </Labeled>
                  </CustomCol>

                </RowContainer>
              </CustomCol>



              <CustomCol md={6}>

                <RowContainer>


                  <CustomCol md={7} sm={12}>
                    <Labeled label='Transacciones con stock cero (0):' >
                      <RadioGroup
                        name="estado"
                        items={transaccionesGrupos}
                        defaultValue={transaccionesStock}
                        value={transaccionesStock}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onValueChangeTStock}
                      />
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={5} sm={12}>
                    <Labeled label='Utilizar Lista de Precios:' >
                      <RadioGroup
                        name="dpListaPrecios"
                        items={dpListaPrecios}
                        defaultValue={utilizarListaPrecios}
                        value={utilizarListaPrecios}
                        layout="horizontal"
                        displayExpr="label"
                        onValueChanged={onValueChangeListaPrecios}
                      />
                    </Labeled>
                  </CustomCol>

                  <CustomCol md={4}>
                    <Labeled label='Stock web:' >
                      <CheckBox
                        value={stockweb}
                        onValueChanged={onValueChangeStockWeb}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol md={8}>
                    <Labeled label='CC Inventarios:' >
                      <TextBox
                        name="ccInventarios"
                        value={ccInventarios}
                        onValueChanged={onValueCcInventarios}
                      // onEnterKey={() => capturarEnter()}
                      >
                        <TextBoxButton
                          name='agregarCuenta'
                          location='after'
                          options={{
                            icon: 'plus',
                            text: '',
                            onClick: () => setModalPlanCuentas(true)
                          }}
                        />

                      </TextBox>
                    </Labeled>
                  </CustomCol>
                  {file !== null && (
                    <CustomCol md={12}>
                      <CLink className="card-header-action sectionErrorsLogo" onClick={() => {
                        setFile(null);
                        setImageSource('');
                        setErrorsLogo([]);
                        imageLogo.current.instance.reset();
                      }}  >
                        {"Reset Logo"}
                      </CLink>
                    </CustomCol>
                  )}
                  <CustomCol md={12}>
                    <CCard className="p-2 mt-2 mb-2">
                      <Labeled label='Logo:' >
                        <FileUploader
                          showFileList={false}
                          multiple={false}
                          accept={'.jpg,.jpeg'}
                          ref={imageLogo}
                          id="subirLogo"
                          labelText=""
                          selectButtonText="Seleccionar logo"
                          uploadMode="useForm"
                          onValueChanged={onChangeFile}
                          maxFileSize={FILE_SIZE}
                          allowedFileExtensions={['.jpg', '.jpeg']}
                        />
                        <div className="fileuploader-container" key={uuidv4()}>

                          {imageSource &&
                            <ImagenEmpresa
                              imagenBlob={imageSource}
                            />
                          }

                        </div>
                      </Labeled>
                    </CCard>
                  </CustomCol>

                </RowContainer>
                <RowContainer>
                  <CustomCol className='mt-2' md={6}>
                    <strong >{"ATRIBUTOS"}</strong>
                    <CTooltip
                      key="title"
                      placement="top"
                      content={"Editar Atributos"}
                    >
                      <CIcon
                        onClick={() => {
                          setOpenAtributos(true);
                        }}
                        className="ms-3" size="sm" icon={cilPencil} />
                    </CTooltip>
                  </CustomCol>
                </RowContainer>

                <RowContainer>
                  <CustomCol className='mt-2' md={6}>
                    <DataGrid
                      selection={{ mode: 'single' }}
                      allowColumnResizing={true}
                      columnResizingMode={'nextColumn'}
                      focusedRowEnabled={true}
                      keyExpr='idAtributoValor'
                      focusedRowKey='idAtributoValor'
                      dataSource={atributos}
                      showBorders={true}
                    >
                      <Editing
                        mode="form"
                        allowDeleting={true}
                      />
                      <Column dataField='idAtributoValor' caption='Código' width='80px' allowEditing={false} visible={false} allowSearch={false} allowFiltering={false} />
                      <Column dataField='atributo' caption='Atributo' width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
                      <Column dataField='valor' caption='Valor' cellRender={ItemCell} width='80px' allowEditing={false} visible={true} allowSearch={false} allowFiltering={false} />
                    </DataGrid>

                  </CustomCol>
                </RowContainer>




              </CustomCol>
            </RowContainer>

            {
              isMobile &&
              <RowContainer  >
                <CustomCol lg='12'  >
                  <ValidationSummary id='summary'></ValidationSummary>
                </CustomCol>
              </RowContainer>
            }

          </CCardBody>
        </ValidationGroup >
      </fieldset >
    </>
  )
}
export default Nuevo
export const localInit: LocalEdicion = {
  codigo: 0,
  clase: null,
  nombre: '',
  siglas: '',
  ciudad: null,
  direccion: '',
  telefono: '',
  gerente: '',
  tipo: null,
  pertenece: null,
  controlaKardex: false,
  estado: null,
  siglasBOD: '',
  web: false,
  empresaCodigo: 0,
  uaf: '',
  descuento: 0,
  usuarioDokan: '',
  passwordDokan: '',
  listaPrecios: false,
  logo: '',
  dpCiudad: [],
  atributos: [],
  inventario: null
}
function ItemCell(data) {
  return (
    <div className="custom-item" style={{ backgroundColor: String(data.data.atributo).toLocaleLowerCase().includes("color") ? String(data.data.valor) : "" }}>
      <div className="product-name">{data.data.valor}</div>
    </div>
  );
}