import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { zonasService } from './service/zonas.service';
import { TOptionGenericoSelect } from '../../clientes/pages/clientes/types/types';

interface ZonasLookUpProps extends React.PropsWithChildren {
  id: string
  selected: TOptionGenericoSelect | null,
  onChanged?: (newValue: TOptionGenericoSelect | null) => void,
  disabled?: boolean,
  provider?: Array<TOptionGenericoSelect> | []
}

const ZonasLookUp: React.FC<ZonasLookUpProps> = (props) => {
  const { id, provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TOptionGenericoSelect> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TOptionGenericoSelect) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await zonasService.getZonas("Elija una opción");
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map(x => {
            const item: TOptionGenericoSelect = {
              codigo: parseInt(x.codigo) ?? -1,
              descripcion: x.descripcion ?? '',
              estado: x.estado ?? 0,
            };
            return item;
          });
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: TOptionGenericoSelect) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex((option: TOptionGenericoSelect) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (selected !== null) {
      refreshSelected()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])


  return (
    <SelectBox
      id='zonasSelect'
      key={id}
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(ZonasLookUp);
