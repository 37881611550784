import React, { PureComponent } from 'react'
import { Highlighter } from 'react-bootstrap-typeahead'
import AsyncSelectBox from '../../../../../views/componentes/asyncSelectBox/asyncSelectBox'
import {
  ENTER_KEY,
  ESCAPE_KEY,
  TAB_KEY,
} from '../../../../../views/componentes/customSheet/constants'
import { VentasService } from '../../../services/ventas.service'
import './sheetDescripcionItemSelectEditor.scss'
import { DateUtils } from '../../../../../helpers/dateUtils'
import { utilidades } from '../../../../../helpers/utilidades'
import { consoleService } from '../../../../../services/console.service'

const getTipoIva = (tipo) => {
  if (tipo >= 0) {
    return 'IVA ' + tipo + ' %'
  } else {
    return 'No Objeto de Impuesto'
  }
}
class SheetDescripcionItemSelectEditor extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.onSearch = this.onSearch.bind(this)
    const value = []
    this.state = { isFirst: true, value: value }
  }

  async onSearch(text) {
    const fechaFacturar = sessionStorage.getItem('fechaFacturar')

    let date = DateUtils.getCurrentDateAsString('dd/MM/yyyy'); // '26/02/2022'
    try {
      if (fechaFacturar !== null || fechaFacturar !== undefined) {
        let re = fechaFacturar.replaceAll('-', '/');
        const arrayResultado = re.split('/');
        date = arrayResultado[2] + '/' + arrayResultado[1] + '/' + arrayResultado[0];
        date = date.replaceAll('"', '');

      }
    } catch (e) {
      consoleService.error(e)
      date = DateUtils.getCurrentDateAsString('dd/MM/yyyy') ?? '26/02/2022';
    }
    // Expresión regular para buscar "nan" o "Nan"

    const cantidadN: number = utilidades.contarLetraN(date);

    // Verificar si la cadena contiene "nan" o "Nan"
    if (cantidadN === true) {
      date = DateUtils.getCurrentDateAsString('dd/MM/yyyy')
    }

    const items = await VentasService.buscarItem(
      text,
      '',
      '9999999999999',
      date,
    )
    this.setState({ isFirst: false })
    return items
  }

  handleChange(opt) {
    if (this.state.isFirst) {
      return
    }
    const { onCommit, onRevert } = this.props
    if (!opt) {
      return onRevert()
    }
    onCommit(opt && opt.length > 0 ? opt[0] : null, null)
  }

  handleKeyDown(e) {
    if (e.which === ESCAPE_KEY) {
      this.setState({ e, isFirst: false })
      this.handleChange(null)
      return
    }
    if (
      (e.which === ENTER_KEY || e.which === TAB_KEY) &&
      this.state.e &&
      e.which === this.state.e.which
    ) {
      e.persist()
      this.setState({ e, isFirst: false })
    } else {
      this.setState({ e })
    }
  }

  render() {
    const value = this.state.value
    return (
      <AsyncSelectBox
        id="selectBusquedaItemsDetalle"
        selected={value ? value : []}
        minSearchlength={3}
        onSearch={this.onSearch}
        labelKey="descripcion"
        placeholder=""
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        autofocus={true}
        renderMenuItemChildren={(option, { text }, index) => {
          return (
            <div>
              <Highlighter search={text}>{option.descripcion}</Highlighter>,
              <div className="buquedaDetailsContainer">
                <small className="buquedaDetailsItem">
                  {'Código:'} {option.codigoBarras}
                </small>
                <small
                  className={`buquedaDetailsItem ${option.existencia < 0
                    ? 'existenciaNegativa'
                    : option.existencia === 0
                      ? 'existenciaCero'
                      : ''
                    }`}
                >
                  {'Existencia:'} {option.existencia}
                </small>
                <small
                  className={`buquedaDetailsItem ${option.existencia < 0
                    ? 'existenciaNegativa'
                    : option.existencia === 0
                      ? 'existenciaCero'
                      : ''
                    }`}
                >
                  {'Impuesto:'} {getTipoIva(option.porcentajeIva)}
                </small>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { SheetDescripcionItemSelectEditor }
