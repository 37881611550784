import React from 'react'
import { TipoIdentificacion, TipoTransaccion } from './store/types'
import { tipoIdentificacionService } from './service/tipoId.service'
import { v4 as uuidv4 } from 'uuid'
import { SelectBoxDE } from '../../../views/componentes/selectBox-DE/selectBox-DE'

interface ITipoIdentificacionLookUpProps extends React.PropsWithChildren {
  selected: TipoIdentificacion | null
  onChanged: (newValue: TipoIdentificacion | null) => void
  transaccion: TipoTransaccion
  disabled?: boolean
  provider?: Array<TipoIdentificacion> | []
  id?: string
  codigoPais?: number
}

const TipoIdentificacionLookUp: React.FC<ITipoIdentificacionLookUpProps> = (
  props,
) => {
  const {
    codigoPais,
    id,
    selected,
    onChanged,
    transaccion,
    disabled,
    provider,
  } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TipoIdentificacion> | []>(
    [],
  )

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TipoIdentificacion) => {
        return select.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      } else {
        onChanged(provider[0])
      }
    } else {
      const data = await tipoIdentificacionService.getTipoIdetificacion(
        transaccion,
        codigoPais,
      )
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TipoIdentificacion = {
              codigo: String(x.codigo).toString() ?? '',
              descripcion: x.descripcion ?? '',
              codTributario: x.codTributario ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((select) => {
          return select.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        } else {
          onChanged(opt[0])
        }
      }
    }
    selectRef?.current?.instance?.repaint()
  }, [codigoPais, transaccion, provider, selected, onChanged])

  const getItemDisplayExpr = React.useCallback((item: TipoIdentificacion) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      onChanged(evt)
    },
    [onChanged],
  )

  const refreshSelected = React.useCallback(async () => {
    if (options && options.length > 0) {
      const selectedIndex = await options.findIndex(
        (option: TipoIdentificacion) => {
          return option.codigo === selected?.codigo
        },
      )
      onChanged(options[selectedIndex] ?? options[0])
    }
    selectRef?.current?.instance?.repaint()
  }, [onChanged, options, selected])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  React.useEffect(() => {
    if (selectRef?.current) {
      selectRef?.current?.instance.repaint()
    }
  }, [selected])

  return (
    <SelectBoxDE
      id="tiposIdentificacionSelect"
      key={id ?? uuidv4()}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChange={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBoxDE>
  )
}
export default React.memo(TipoIdentificacionLookUp)
