import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EdicionPayload } from '../../../../clientes/store/types'
import {
  DatosEdicionGenerales,
  DatosEdicionCompra,
  DatosEdicionAdicionales,
  DatosEdicionHistorico,
  ProveedoresDatosEdicion,
  DatosEdicionContribuyente,
} from '../types/types'
import { TDatosPersona } from '../../../../clientes/pages/clientes/types/types'
import { CustomDictionary } from '../../../../../store/types'

const initialState: CustomDictionary<ProveedoresDatosEdicion> = {}

const datosEdicionProveedoresSlice = createSlice({
  name: 'suppliersEditData',
  initialState: initialState,
  reducers: {
    setDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ProveedoresDatosEdicion>>,
    ) {
      state[action.payload.key] = action.payload.data
    },
    initDatosEdicion(
      state,
      action: PayloadAction<EdicionPayload<ProveedoresDatosEdicion>>,
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data
      }
    },
    setTabContribuyente(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionContribuyente>>,
    ) {
      state[action.payload.key].tabs.contribuyente = action.payload.data
    },
    setTabAdicionales(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionAdicionales>>,
    ) {
      state[action.payload.key].tabs.adicionales = action.payload.data
    },
    setTabGenerales(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionGenerales>>,
    ) {
      state[action.payload.key].tabs.generales = action.payload.data
    },
    setTabCompra(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionCompra>>,
    ) {
      state[action.payload.key].tabs.compras = action.payload.data
    },
    setTabHistorico(
      state,
      action: PayloadAction<EdicionPayload<DatosEdicionHistorico>>,
    ) {
      state[action.payload.key].tabs.historicos = action.payload.data
    },
    deleteEditData(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    updateLoader(
      state,
      action: PayloadAction<{
        key: string
        loader: { mensaje: string; show: boolean }
      }>,
    ) {
      state[action.payload.key].loader.mensaje = action.payload.loader.mensaje
      state[action.payload.key].loader.show = action.payload.loader.show
    },
    setTabPerson(
      state,
      action: PayloadAction<{
        key: string
        datosPersona: TDatosPersona
      }>,
    ) {
      state[action.payload.key].tabs.generales.direccion =
        action.payload.datosPersona.direccion
      state[action.payload.key].tabs.generales.email =
        action.payload.datosPersona.email
      state[action.payload.key].tabs.contribuyente.nombre =
        action.payload.datosPersona.nombre
      state[action.payload.key].tabs.contribuyente.razonComercial =
        action.payload.datosPersona.razonComercial
      state[action.payload.key].tabs.generales.telefonos =
        action.payload.datosPersona.telefono
    },
    // updateCode(state, action: PayloadAction<{ key: string; codigo: number }>) {
    //   state[action.payload.key].codigo = action.payload.codigo
    // },
    // updateName(state, action: PayloadAction<{ key: string; nombre: string }>) {
    //   state[action.payload.key].nombre = action.payload.nombre
    // },
    // updateBusinessReason(
    //   state,
    //   action: PayloadAction<{ key: string; razonComercial: string }>,
    // ) {
    //   state[action.payload.key].razonComercial = action.payload.razonComercial
    // },
    // setDataEditionGenerals(
    //   state,
    //   action: PayloadAction<{ key: string; data: DatosEdicionGenerales }>,
    // ) {
    //   state[action.payload.key].generales = action.payload.data
    // },
    // setDataEditionPruchases(
    //   state,
    //   action: PayloadAction<{ key: string; data: DatosEdicionCompra }>,
    // ) {
    //   state[action.payload.key].compras = action.payload.data
    // },
    // setDataEditionHistorical(
    //   state,
    //   action: PayloadAction<{ key: string; data: DatosEdicionHistorico }>,
    // ) {
    //   state[action.payload.key].historicos = action.payload.data
    // },
    // setDataEditionAditionals(
    //   state,
    //   action: PayloadAction<{ key: string; data: DatosEdicionAdicionales }>,
    // ) {
    //   state[action.payload.key].adicionales = action.payload.data
    // },
  },
})

export const {
  deleteEditData,
  setDatosEdicion,
  initDatosEdicion,
  updateLoader,
  // updateCode,
  // updateName,
  // updateBusinessReason,
  // setDataEditionGenerals,
  // setDataEditionPruchases,
  // setDataEditionHistorical,
  // setDataEditionAditionals,
  setTabContribuyente,
  setTabAdicionales,
  setTabGenerales,
  setTabCompra,
  setTabHistorico,
  setTabPerson,
} = datosEdicionProveedoresSlice.actions
export const datosEdicionProveedoresReducer =
  datosEdicionProveedoresSlice.reducer
