import React from 'react'
import { CCard, CCardBody } from '@coreui/react-pro'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../views/componentes/colContainer'
import InputTextDE from '../../../../../../views/componentes/inputText-DE/inputText-DE'
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper'
import {
  AsyncRule,
  RequiredRule,
  StringLengthRule,
} from 'devextreme-react/validator'
import { PersonalServices } from '../../../../../nomina/pages/personal/services/personal.service'
import { ToastTypes } from '../../../../../../store/types'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import { Validator } from 'devextreme-react'
import {
  setBEDatosEdicion,
  setChangeLoaderEdit,
  setUsuarioBasicoDatosEdicion,
} from '../../store/editDataReducer'
import { useDispatch, useSelector } from 'react-redux'
import validations from '../../../../../../config/validations'
import { SelectBoxDE } from '../../../../../../views/componentes/selectBox-DE/selectBox-DE'
import { utilidades } from '../../../../../../helpers/utilidades'
import { RootState } from '../../../../../../store/store'
import prefijos from '../../../../../../views/pages/registrarUsuario/prefijosTelefonos'
import { ECountry } from '../../../../../../store/enum/countries'
import LocalidadesLookUp from '../../../../../componentes/localidadesLookUp/localidadesLookUp'
import LocalesLookUp from '../../../../../componentes/localesLookUp'

interface IUsuarioBasicoProps extends React.PropsWithChildren {
  tabId: string
  setToast?: (type: ToastTypes, error: string) => void
}

type Contactos = {
  codigo: number
  numero: string
  descripcion: string
  tipo?: number
  tipoDescripcion?: string
  telefonoCodigo?: number
  telefonoDescripcion?: string
  principal: boolean
  asociadoTipo: string
}

const spetialCharNotAllowed =
  'Teléfono: Valor Inválido, no puede contener caracteres especiales'
const lugarResidenciaMessage: string =
  'Lugar de Residencia: Elija un Lugar de Residencia'
const lugarMessage: string = 'Local/CC: debe eligir una opción'
const passwordStrong = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/g
const politicaContrasena =
  'La contraseña no se ajusta a la política: la contraseña debe tener al menos un número, una letra minúscula, letra mayúscula, al menos un símbolo y de al menos 8 caracteres.'

export default function TUsuarioBasico(props: IUsuarioBasicoProps) {
  const { tabId, setToast } = props
  const dispatch = useDispatch()
  const sms = validations['es']
  const empresa = useSelector((state: RootState) => {
    return state.global.session?.empresa
  })
  const mantenimientoState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
  })
  const userState = useSelector((state: RootState) => {
    return state.administracion.configuraciones.usuarios.editData[tabId]
      ?.usuarioBasico
  })
  const contratoEmpresa = useSelector(
    (state: RootState) => state.pagosSuscripcion.contratoEmpresa,
  )

  const [element] = React.useState('1')
  const [passwordMode, setPasswordMode] = React.useState<'text' | 'password'>(
    'password',
  )
  const [prefijoTelefono, setPrefijoTelefono] = React.useState<any>(
    prefijos[148],
  )
  const [esNuevo, setEsNuevo] = React.useState(false)

  const onChangeValuePersonal = React.useCallback(
    (data, key: string) => {
      dispatch(
        setUsuarioBasicoDatosEdicion({
          key: tabId,
          data: {
            ...userState,
            [key]: data,
          },
        }),
      )
    },
    [userState, dispatch, tabId],
  )

  const onChangeValueLogin = React.useCallback(
    (data, key: string) => {
      dispatch(
        setUsuarioBasicoDatosEdicion({
          key: tabId,
          data: {
            ...userState,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, userState, tabId],
  )

  const onLoader = React.useCallback(
    (showLoader: boolean, text: string) => {
      dispatch(
        setChangeLoaderEdit({
          key: tabId,
          data: {
            show: showLoader,
            mensaje: text,
          },
        }),
      )
    },
    [dispatch, tabId],
  )

  const validateId = React.useCallback(
    async (params, esNuevo: boolean) => {
      if (esNuevo) {
        const data = await PersonalServices.getPersonalById(params?.value ?? '')
        if (data !== null && data !== undefined && data['error'] === false) {
          if (data['auto'] !== 'NOT_FOUND_USER') {
            setToast(ToastTypes.Warning, data['message'])
            return Promise.reject(
              `La persona con identificaión ${
                params.value ?? ''
              } ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
            )
          } else {
            return Promise.resolve()
          }
        } else {
          return Promise.reject('Error al validar identificación')
        }
      }
    },
    [setToast],
  )

  const generarNewPassword = React.useCallback(async () => {
    onLoader(true, 'Generando Password...')
    try {
      const dataGenerated = await utilidades.generarClave()
      if (dataGenerated) {
        onChangeValueLogin(dataGenerated, 'password')
      }
    } catch (error) {
      setToast(ToastTypes.Danger, error.message)
    }
    onLoader(false, '')
  }, [setToast, onLoader, onChangeValueLogin])

  const onValidateSpetialChar = (value) => {
    const regex = /[^\d]/
    if (!regex.test(value?.value)) {
      return Promise.resolve()
    } else {
      return Promise.reject(spetialCharNotAllowed)
    }
  }

  const validateAsyncSelect = React.useCallback(
    (datoSeleccion: any, message: string) => {
      if (datoSeleccion.value.codigo > -1) {
        return Promise.resolve()
      } else {
        return Promise.reject(message)
      }
    },
    [],
  )

  const validateCiudad = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(datoSeleccion, lugarResidenciaMessage)
    },
    [validateAsyncSelect],
  )

  const onHandleddPhone = React.useCallback(
    async (value) => {
      const numeros: Contactos = {
        codigo: 0,
        numero: prefijoTelefono?.value + '' + value,
        descripcion: '',
        tipo: 0,
        tipoDescripcion: '',
        telefonoCodigo: 0,
        telefonoDescripcion: '',
        principal: true,
        asociadoTipo: '',
      }
      const contactosCopy = userState?.telefonos.slice()
      contactosCopy.push(numeros)

      onChangeValuePersonal(contactosCopy, 'telefonos')
    },
    [prefijoTelefono, userState, onChangeValuePersonal],
  )

  const onLoadUser = React.useCallback(() => {
    if (userState?.codigo > 0) {
      setEsNuevo(true)
    }
  }, [userState])

  const validateAsyncSeleccionLugar = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        'Local/CC: debe eligir una opción',
      )
    },
    [validateAsyncSelect],
  )

  const validarClave = async (value) => {
    const regex = new RegExp(passwordStrong)
    if (regex.test(value)) {
      return await true
    } else {
      return await false
    }
  }

  const asyncValidationPasswordStrong = (params) => {
    return validarClave(params.value)
  }

  React.useEffect(() => {
    if (userState !== null) {
      onLoadUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // React.useEffect(() => {
  //   if (userState?.telefono && userState.telefono !== '') {
  //     onHandleddPhone(userState?.telefono)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userState?.telefono])

  return (
    <CCard style={{ border: 1 }} className="m-0">
      <CCardBody>
        <div style={{ padding: '10px', overflowX: 'hidden' }} className="mb-4">
          <RowContainer>
            <CustomCol xs="12" md="6">
              <RowContainer>
                <CustomCol xs="12" md="12">
                  <Labeled label="Identificación*:">
                    <InputTextDE
                      name="identificacionInput"
                      placeholder="Identificación"
                      value={userState?.identificacion ?? ''}
                      onValueChange={(e) => {
                        dispatch(
                          setBEDatosEdicion({
                            key: tabId,
                            identificacion: e,
                          }),
                        )
                        // onChangeValuePersonal(e, 'identificacion')
                      }}
                      id={element}
                      inputAttr={{ autocomplete: 'nope' }}
                    >
                      <Validator>
                        <RequiredRule
                          trim
                          message={lh.getMessage(
                            MessagesKeys.ClienteNumeroIdentificacionRequerido,
                          )}
                        />
                        <AsyncRule
                          message={lh.getMessage(
                            MessagesKeys.ClienteNumeroIdentificacionIncorrecto,
                            '',
                          )}
                          validationCallback={(params) =>
                            validateId(params, esNuevo)
                          }
                        />
                      </Validator>
                    </InputTextDE>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Nombres*:">
                    <InputTextDE
                      value={userState?.nombres ?? ''}
                      placeholder=""
                      onValueChange={(data) => {
                        onChangeValuePersonal(data, 'nombres')
                      }}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    >
                      <Validator>
                        <RequiredRule message="Nombres: Este campo es requerido" />
                        <StringLengthRule
                          min="4"
                          message="Nombres: Este campo debe tener al menos 4 caracteres"
                        />
                        <StringLengthRule
                          max="100"
                          message="Nombres: Este campo no puede tener mas de 100 caracteres"
                        />
                      </Validator>
                    </InputTextDE>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Apellidos*:">
                    <InputTextDE
                      value={userState?.apellidos ?? ''}
                      placeholder=""
                      onValueChange={(data) => {
                        onChangeValuePersonal(data, 'apellidos')
                      }}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    >
                      <Validator>
                        <RequiredRule message="Apellidos: Este campo es requerido" />
                        <StringLengthRule
                          min="4"
                          message="Apellidos: Este campo debe tener al menos 4 caracteres"
                        />
                        <StringLengthRule
                          max="100"
                          message="Apellidos: Este campo no puede tener mas de 100 caracteres"
                        />
                      </Validator>
                    </InputTextDE>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Email*:">
                    <InputTextDE
                      value={userState?.usuario ?? ''}
                      placeholder=""
                      onValueChange={(data) => {
                        onChangeValueLogin(data, 'usuario')
                      }}
                      showClearButton={true}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      readOnly={userState?.codigo !== 0}
                    >
                      <Validator>
                        <RequiredRule message={'Usuario: ' + sms['required']} />
                        <StringLengthRule
                          max="50"
                          message="Email: Este campo no puede tener mas de 50 caracteres"
                        />
                      </Validator>
                    </InputTextDE>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {userState?.codigo === 0 && (
                <RowContainer>
                  <CustomCol xs="12" md="12">
                    <Labeled label="Password*:">
                      <InputTextDE
                        value={userState?.password ?? ''}
                        showClearButton={true}
                        mode={passwordMode}
                        width="100%"
                        inputAttr={{ autocomplete: 'nope' }}
                        onValueChange={(data) => {
                          onChangeValueLogin(data, 'password')
                        }}
                        readOnly={userState?.codigo !== 0}
                      >
                        <TextBoxButton
                          name="btnEye"
                          location="after"
                          options={{
                            type: 'normal',
                            stylingMode: 'contained',
                            icon:
                              passwordMode === 'text'
                                ? 'images/eye.png'
                                : 'images/eye-slash.png',
                            onClick: () => {
                              setPasswordMode(
                                passwordMode === 'text' ? 'password' : 'text',
                              )
                            },
                          }}
                        />
                        <TextBoxButton
                          name="btnPasswordGenerate"
                          location="after"
                          options={{
                            type: 'normal',
                            stylingMode: 'contained',
                            text: 'Generar Password',
                            onClick: () => {
                              generarNewPassword()
                            },
                          }}
                        />
                        <Validator>
                          <RequiredRule
                            message={'Password: ' + sms['required']}
                          />
                          <StringLengthRule
                            max="15"
                            message="Password: Este campo no puede tener mas de 15 caracteres"
                          />
                          <AsyncRule
                            message={politicaContrasena}
                            validationCallback={asyncValidationPasswordStrong}
                          />
                        </Validator>
                      </InputTextDE>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              )}
              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Ciudad*:">
                    <LocalidadesLookUp
                      onChanged={(data) => {
                        onChangeValuePersonal(data, 'ciudadReferencia')
                      }}
                      onChangedOptions={() => {}}
                      selected={userState?.ciudadReferencia ?? null}
                      allowEdit
                      allowClear
                    >
                      <Validator>
                        <RequiredRule message={lugarResidenciaMessage} />
                        <AsyncRule
                          message={lugarResidenciaMessage}
                          validationCallback={validateCiudad}
                        />
                      </Validator>
                    </LocalidadesLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Teléfono*:">
                    <RowContainer>
                      <CustomCol xs="3">
                        <SelectBoxDE
                          items={prefijos}
                          value={userState?.prefijo}
                          displayExpr="name"
                          defaultValue={prefijoTelefono}
                          searchEnabled={true}
                          onValueChange={(d) => {
                            onChangeValuePersonal(d, 'prefijo')
                          }}
                          id="prefijo"
                          key="prefijo"
                          inputAttr={{
                            autocomplete: 'nope',
                          }}
                        />
                      </CustomCol>
                      <CustomCol xs="9">
                        <InputTextDE
                          name="telefono"
                          placeholder="Teléfono"
                          value={userState?.telefono ?? ''}
                          onValueChange={(d) => {
                            onChangeValuePersonal(d, 'telefono')
                          }}
                          inputAttr={{
                            autoComplete: 'nope',
                          }}
                          onKeyDown={utilidades.filtraTeclasNoNumericas}
                        >
                          <Validator>
                            <RequiredRule
                              trim
                              message={lh.getMessage(
                                MessagesKeys.GlobalCampoRequerido,
                                'Teléfono',
                              )}
                            />
                            <StringLengthRule
                              min={
                                empresa?.codigoPais === ECountry.ElSalvador
                                  ? 8
                                  : 6
                              }
                              message={
                                'Teléfono: Este campo debe tener un mínimo de ' +
                                (empresa?.codigoPais === ECountry.ElSalvador
                                  ? 8
                                  : 6) +
                                ' caracteres'
                              }
                            />
                            <AsyncRule
                              message={spetialCharNotAllowed}
                              validationCallback={onValidateSpetialChar}
                            />
                          </Validator>
                        </InputTextDE>
                      </CustomCol>
                    </RowContainer>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol xs="12">
                  <Labeled label="Local o CC*:">
                    <LocalesLookUp
                      onChanged={(data) => onChangeValueLogin(data, 'local')}
                      onChangedOptions={() => {}}
                      selected={userState?.local ?? null}
                    >
                      <Validator>
                        <RequiredRule message={lugarMessage} />
                        <AsyncRule
                          message={lugarMessage}
                          validationCallback={validateAsyncSeleccionLugar}
                        />
                      </Validator>
                    </LocalesLookUp>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </CustomCol>
            <CustomCol xs="12" md="6" className="d-flex justify-content-end  ">
              <strong style={{ fontSize: '16px' }}>
                {'Usuarios ' +
                  (mantenimientoState?.maximoUsuarios ?? 0) +
                  ' / ' +
                  contratoEmpresa?.numUsuariosContrato}{' '}
              </strong>
            </CustomCol>
          </RowContainer>
        </div>
      </CCardBody>
    </CCard>
  )
}
