import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { utilidades } from "../../../../helpers/utilidades";
import { SesionService } from "../../../../services/sesion.service";
import { ESubtipo } from "../../../../store/enum/subtypes";
import { TipoIdentificacion, TipoIdentificacionSub, TipoTransaccion } from "../store/types";

export const tipoIdentificacionService = { getTipoIdetificacion, getTipoIdetificacionSub };

async function getTipoIdetificacion(transaccion: TipoTransaccion, paisCodigo: number = -1): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    tipo: transaccion ?? TipoTransaccion.venta,
    paisCodigo: sesion?.local?.ciudad?.paisCodigo ?? 1 // 1 -  Ecuador => por defecto
  };
  if (paisCodigo > 0) {
    data.paisCodigo = paisCodigo;
  }
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  const cacheKey = 'tipoIdetificacion' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const clientes: any = await RequestHelper.getAll('cuenta', 'tipoId/listar', "", data);
    const opt: Array<TipoIdentificacion> = [];
    if (clientes?.auto && clientes?.auto.length > 0) {
      for (const x of clientes.auto) {
        opt.push({
          codigo: String(x.codigo).toString() ?? '',
          descripcion: utilidades.capText(x.descripcion) ?? '',
          codTributario: x.codTributario ?? '',
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    return clientes;
  }
}

// getTipoIdetificacionSub => obtiene de la tabla subtipos
async function getTipoIdetificacionSub(paisCodigo: number, mensaje: string): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data: any = {
    tipoCodigo: ESubtipo.IdetificacionSub, // 121 tipos de identificacion para el salvador
    paisCodigo: paisCodigo,
    mensaje: mensaje
  };
  const keysToCache: any = {
    ...data,
    dateKey: DateUtils.getCurrentDateAsString(formatoFechasApi),
    empresa: sesion?.empresa?.codigo,
  }
  const cacheKey = 'tipoIdetificacionSub' + JSON.stringify(keysToCache);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return { error: false, auto: JSON.parse(cached) };
  } else {
    const res: any = await RequestHelper.getAll('subtypes', 'cboxLoad', "", data);
    const opt: Array<TipoIdentificacionSub> = [];
    if (res?.auto && res?.auto.length > 0) {
      for (const x of res.auto) {
        opt.push({
          codigo: x.codigo ?? 0,
          valor: utilidades.capText(x.valor) ?? '',
          tipoCodigo: utilidades.capText(x.tipoCodigo) ?? '',
          grupo: utilidades.capText(x.grupo) ?? '',
          orden: x.orden ?? 1,
          estado: utilidades.capText(x.estado) ?? '',
        })
      }
    }
    sessionStorage.setItem(cacheKey, JSON.stringify(opt));
    return res;
  }
}

