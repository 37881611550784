import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { OptionCiudad } from '../../../containers/component/formLocal/types/types'
import RowContainer from '../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../views/componentes/colContainer'
import { CTooltip } from '@coreui/react-pro'
import PopupLocalidades from '../../../views/componentes/popupLocalidades'
import { ToastTypes } from '../../../store/types'
import { addToast } from '../../../store/toasterReducer'
import { localidadesService } from '../../../services/localidades.service'
import { useDispatch } from 'react-redux'
import { Button } from 'devextreme-react'
interface LocalidadesLookUpProps extends React.PropsWithChildren {
  selected: OptionCiudad | null
  onChanged: (newValue: OptionCiudad | null) => void
  onChangedOptions?: (newValue: Array<OptionCiudad> | []) => void
  disabled?: boolean
  allowEdit?: boolean
  allowClear?: boolean
}

const LocalidadesLookUp: React.FC<LocalidadesLookUpProps> = (props) => {
  const {
    selected,
    onChanged,
    disabled,
    allowEdit,
    allowClear,
    onChangedOptions,
  } = props
  const selectRef = React.useRef<any>()
  const dispatch = useDispatch()

  const [popupLocalidades, setPopupLocalidades] = React.useState<boolean>(false)
  const [dpCiudad, setDpCiudad] = React.useState<Array<OptionCiudad> | []>([])
  const [ciudadLocal, setCiudadLocal] = React.useState<OptionCiudad>()

  const setToast = React.useCallback(
    (texto, type) => {
      let time: number = 3000

      let tipo: ToastTypes = ToastTypes.Info
      time = texto.length < 50 ? 5000 : 10500

      if (type === 'warning') {
        tipo = ToastTypes.Warning
      } else if (type === 'success') {
        tipo = ToastTypes.Success
      }
      if (type === 'info') {
        tipo = ToastTypes.Info
      }
      if (type === 'danger') {
        tipo = ToastTypes.Danger
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const llenarCiudades = React.useCallback(async (idProvincia) => {
    // por defecto Azuay = 1
    try {
      const ciudades = [] as Array<OptionCiudad>
      const data = await localidadesService.getDpCiudades(
        idProvincia,
        'Elija una opción',
      )

      if (data && data.length > 0) {
        for (const item of data) {
          ciudades.push({
            codigoPais: Number(item?.paisCodigo) ?? 0,
            nombrePais: String(item?.paisNombre) ?? '',
            codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
            nombreProvincia: String(item?.provinciaNombre) ?? '',
            codigo: Number(item?.codigo) ?? 0,
            nombre: String(item?.nombre) ?? '',
          })
        }
      }
      return await ciudades
    } catch (error) {
      return await []
    }
  }, [])

  const loadDatos = React.useCallback(async () => {
    let dpCiudades = [] as Array<OptionCiudad>
    const ciudades = await llenarCiudades(-1)
    dpCiudades = ciudades ?? []
    setDpCiudad(dpCiudades)
    if (onChangedOptions) {
      onChangedOptions(dpCiudades)
    }
    if (ciudades && ciudades.length > 0) {
      const selectedIndex = ciudades.findIndex((ciudad) => {
        return ciudad.codigo === selected?.codigo
      })
      setCiudadLocal(ciudades[selectedIndex] || ciudades[0])
    }
  }, [llenarCiudades, onChangedOptions, selected])

  const getItemDisplayExpr = React.useCallback((item: OptionCiudad) => {
    return item ? `${item.nombre}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    },
    [onChanged],
  )

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, [])

  const onClearCiudad = React.useCallback(() => {
    setCiudadLocal(null)
    onChanged(null)
    llenarCiudades(-1)
  }, [onChanged, llenarCiudades])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dpCiudad && dpCiudad.length > 0) {
      const selectedIndex = dpCiudad.findIndex((ciudad: OptionCiudad) => {
        return ciudad.codigo === selected?.codigo
      })
      setCiudadLocal(dpCiudad[selectedIndex] || dpCiudad[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <RowContainer gutters={false}>
      <CustomCol xs="6" md="9" className="pe-0">
        {popupLocalidades === true && (
          <PopupLocalidades
            show={popupLocalidades}
            ciudad={ciudadLocal}
            onClose={() => setPopupLocalidades(false)}
            onChange={(data) => {
              setDpCiudad(data.ciudades ?? [])
              setCiudadLocal(data?.ciudad ?? null)
              onChanged(data?.ciudad ?? null)
              setPopupLocalidades(false)
            }}
            setToast={setToast}
          />
        )}
        <SelectBox
          id="localidadesSelect"
          ref={selectRef}
          items={dpCiudad ?? []}
          searchEnabled
          displayExpr={getItemDisplayExpr}
          searchExpr={['nombre']}
          searchTimeout={500}
          onEnterKey={(e) => {
            console.log(e)
          }}
          value={ciudadLocal}
          onValueChanged={onLookUpValueChanged}
          disabled={disabled}
          onFocusIn={onSelectBoxFocusIn}
        >
          {props.children}
        </SelectBox>
      </CustomCol>
      <CustomCol md="3" xs="6" className="m-0 p-0">
        <div className="d-flex flex-row align-items-start m-0 p-0">
          {allowEdit && selected && (
            <CTooltip content="Cambiar de ciudad">
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="default"
                  icon="search"
                  onClick={() => {
                    setPopupLocalidades(true)
                  }}
                />
              </div>
            </CTooltip>
          )}
          {allowClear && selected && (
            <CTooltip content="Quitar ciudad">
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="danger"
                  icon="undo"
                  onClick={() => {
                    onClearCiudad()
                  }}
                />
              </div>
            </CTooltip>
          )}
          {allowEdit && selected === null && (
            <CTooltip content="Buscar ciudad">
              <div style={{ marginLeft: '1px' }}>
                <Button
                  stylingMode="contained"
                  type="default"
                  icon="search"
                  onClick={() => {
                    setPopupLocalidades(true)
                  }}
                />
              </div>
            </CTooltip>
          )}
        </div>
      </CustomCol>
    </RowContainer>
  )
}
export default React.memo(LocalidadesLookUp)
