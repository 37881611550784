import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { TextBox } from 'devextreme-react/text-box'
import { Button } from 'devextreme-react/button'
import { CTooltip } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { NumberBox, Validator } from 'devextreme-react'
import { DatosEdicionContribuyente } from '../../../../types/types'
import { AdminModalLocales } from '../../../../../../../componentes/modalAdminLocales/modalAdminLocales'
import { StringLengthRule } from 'devextreme-react/validator'
import { setTabContribuyente } from '../../../../store/editDataReducer'
import { MessagesKeys, lh } from '../../../../../../../../helpers/localizationHelper'
import { utilidades } from '../../../../../../../../helpers/utilidades'

interface IMainProps {
  tabId: string
}

const Main: React.FunctionComponent<IMainProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const datosContribuyente = useSelector((state: RootState) => { return state.clientes.clientes.editData[props.tabId].tabs?.contribuyente })
  const [adminModalLocales, setAdminModalLocales] = React.useState<boolean>(false)
  const [codigo, setCodigo] = React.useState<number>(0);
  const [nombre, setNombre] = React.useState<string>('');
  const [razonComercial, setRazonComercial] = React.useState<string>('');

  const onDataFormulario = React.useCallback(async (dataToFormulario: DatosEdicionContribuyente) => {
    setCodigo(dataToFormulario?.codigo)
    setNombre(dataToFormulario?.nombre)
    setRazonComercial(dataToFormulario?.razonComercial)
  }, []);

  const onChangeNombre = React.useCallback(async (e) => {
    if (e?.event !== null || e?.event !== undefined) {
      dispatch(setTabContribuyente({
        key: tabId,
        data: {
          ...datosContribuyente,
          nombre: e.value
        }
      }));
    }
  }, [dispatch, datosContribuyente, tabId])

  const onChangeRazonComercial = React.useCallback(async (e) => {
    if (e?.event !== null || e?.event !== undefined) {
      dispatch(setTabContribuyente({
        key: tabId,
        data: {
          ...datosContribuyente,
          razonComercial: e.value
        }
      }));
    }
  }, [dispatch, datosContribuyente, tabId])

  React.useEffect(() => {
    onDataFormulario(datosContribuyente)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosContribuyente]);



  return (
    <>
      {adminModalLocales && (
        <AdminModalLocales
          onChanged={(data) => {
            setAdminModalLocales(!adminModalLocales)
          }}
          onCancel={() => setAdminModalLocales(!adminModalLocales)}
          show={adminModalLocales}
          modulo={0}
        />
      )}
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Código:">
            <NumberBox name="codigoCliente" value={codigo ?? 0} readOnly />
          </Labeled>
        </CustomCol>

        <CustomCol xs="12" md="1" className="d-flex align-items-end">
          <CTooltip content="Campo Informativo" placement="right">
            <div>
              <Button id="btnInfo" type="normal" icon="info" stylingMode="contained" />
            </div>
          </CTooltip>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Razón Social:">
            <TextBox
              value={nombre ?? ''}
              onValueChanged={onChangeNombre}
            >
              <Validator>
                <StringLengthRule
                  max="250"
                  min="5"
                  message={utilidades.getMessageValidation(lh.getMessage(MessagesKeys.ClienteLongitudCampoIncorrecto), { label: 'Nombre', minChar: 5, maxChar: 250 })}
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Razon Comercial:">
            <TextBox
              value={razonComercial ?? ''}
              onValueChanged={onChangeRazonComercial}
            >
              <Validator>
                <StringLengthRule
                  max="250"
                  min="5"
                  message={utilidades.getMessageValidation(lh.getMessage(MessagesKeys.ClienteLongitudCampoIncorrecto), { label: 'Razon Comercial', minChar: 5, maxChar: 250 })}
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="3" className="d-flex align-items-end">
          <Button
            id="btnAdd"
            className="ms-1 mt-3"
            icon="add"
            stylingMode="contained"
            type="default"
            disabled={false}
            onClick={() => {
              setAdminModalLocales(!adminModalLocales)
            }}
            text="Asignar Proyecto / CC"
          />
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(Main)

export const defaultDatosEdicionDatosContribuyente: DatosEdicionContribuyente = {
  codigo: 0,
  nombre: '',
  razonComercial: ''
}
