/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-undef */
import 'react-app-polyfill/stable'
import 'core-js'
import './env'
import './styles/style.css'
import React from 'react'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store/store'

import { configAws } from './helpers/dist/awsConfig'
export { configAws }

import { BrowserRouter } from 'react-router-dom'
import AppContainer from './AppContainer'
import { icons } from './assets/icons/index'
import './assets/icons/iconsDuotone'
import './assets/icons/iconsLigth'
import './assets/icons/inconsRegular'
import './assets/icons/inconsSolid'
export { icons }

import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container!)

export const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
console.log('env:', process.env)
root.render(
  <React.Suspense fallback={loading}>
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer
          mantenimiento={
            (process.env['REACT_APP_MODO_MANTENIMIENTO'] ?? 'false') === 'true'
          }
        />
      </BrowserRouter>
    </Provider>
  </React.Suspense>,
)

reportWebVitals()
