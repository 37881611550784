/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import { TabState } from '../../../../../store/genericTabTypes'
import {
  CustomButtons,
  DocumentInfo,
  LocalEmpresa,
  ToastTypes,
} from '../../../../../store/types'
import { RootState } from '../../../../../store/store'
import { LocalDatosEdicion, TLocalData } from '../store/types'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import {
  OptionCiudad,
  OptionPais,
  OptionProvincia,
} from '../../../../../containers/component/formLocal/types/types'
import { TipoEstablecimientoOption } from '../../../../componentes/lookUpTipoEstablecimiento/store/type'
import { addToast } from '../../../../../store/toasterReducer'
import { localidadesService } from '../../../../../services/localidades.service'
import { Ciudad } from '../../../../ventas/store/types'
import { sidebarService } from '../../../../../services/sidebar.service'
import { tipoEstablecimientoService } from '../../../../componentes/lookUpTipoEstablecimiento/service/tipoEstablecimiento.service'
import { utilidades } from '../../../../../helpers/utilidades'
import {
  CAlert,
  CCard,
  CCardBody,
  CCol,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react-pro'
import { PopupContent } from '../../../../../views/componentes/popupContent'
import AtributosLocal from '../../../../componentes/modalAdminLocales/components/nuevo/atributos'
import {
  FileUploader,
  NumberBox,
  Switch,
  TextArea,
  ValidationGroup,
  ValidationSummary,
  Validator,
} from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/data-grid'
import LookUpTipoEstablecimiento from '../../../../componentes/lookUpTipoEstablecimiento'
import ImagenEmpresa from '../../empresa/componentes/imagenEmpresa'
import { v4 as uuidv4 } from 'uuid'
import {
  clearButtonClick,
  setButtons,
  setCurrentExecutingAction,
  setNameTab,
} from '../store/tabsReducer'
import LocalidadesLookUp from '../../../../componentes/localidadesLookUp/localidadesLookUp'
import { LocalesService } from '../services/locales.services'
import { StatesEdition } from '../../../../../store/enums'
import { setDatosEdicion } from '../store/editDataReducer'
import InputTextDE, {
  InputNumberDE,
} from '../../../../../views/componentes/inputText-DE/inputText-DE'
import { establecerSession } from '../../../../../store/reducers'

interface IMLocalProps extends React.PropsWithChildren {
  info: DocumentInfo<TLocalData>
  tabId: string
  tab: TabState<TLocalData>
}

const FILE_SIZE = 1000000
const allowExtention = ['.jpg', '.jpeg']

export type TAtributos = {
  valor: string
  atributo: string
  idAtributo: number
  idAtributoValor: number
  nueva: boolean
}

const MLocal: React.FunctionComponent<IMLocalProps> = (props) => {
  const { info, tabId, tab } = props

  const imageLogo = React.useRef<any>()

  const dialogRef = React.useRef<any>(null)
  const validationGroupRef = React.useRef<any>()
  const tabs = useSelector(
    (state: RootState) => state.administracion.configuraciones.locales.tabs,
  )
  const empresaState = useSelector(
    (state: RootState) => state.global.session?.empresa,
  )
  const usuarioState = useSelector(
    (state: RootState) => state.global.session?.usuario,
  )
  const ingresoState = useSelector(
    (state: RootState) => state.global.session?.ingreso,
  )
  const dpPaisRedux = useSelector(
    (state: RootState) => state.global.sidebar.configLocalEmpresa.dpPais,
  )
  const localesContrato = useSelector(
    (state: RootState) =>
      state.pagosSuscripcion.contratoEmpresa.numLocalesContrato,
  )

  const dataEdicion = useSelector(
    (state: RootState) =>
      state.administracion.configuraciones.locales.editData[tabId],
  )
  const loading = useSelector((state: RootState) => {
    return state.administracion.configuraciones.locales.editData[tabId].loading
  })
  const dispatch = useDispatch()
  const sessionStatus = useSelector((state: RootState) => state.global.session)

  const [openAtributos, setOpenAtributos] = React.useState<boolean>(false)
  const [atributos, setAtributos] = React.useState<Array<TAtributos>>([])
  const [listaCiudad, setListaCiudad] = React.useState<Array<any>>([])

  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>(
    [],
  )
  const [loaderLocal, setLoaderLocal] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [errorsLogo, setErrorsLogo] = React.useState<any>([])
  const [datosEdicion2, setDatosEdicion2] = React.useState<any>([])

  const [imageSource, setImageSource] = React.useState<string>('')
  const [file, setFile] = React.useState<File | null>(null)
  const [nombre, setNombre] = React.useState<string>('')
  const [direccion, setDireccion] = React.useState<string>('')
  const [siglas, setSiglas] = React.useState<string>('')
  const [descuento, setDescuento] = React.useState<number>(0)
  const [telefono, setTelefono] = React.useState<string>('')
  const [pais, setPais] = React.useState<OptionPais | null>(null)
  const [provincia, setProvincia] = React.useState<OptionProvincia | null>(null)
  const [dpProvincia, setDpProvincia] = React.useState<
    Array<OptionProvincia> | []
  >([])
  const [ciudad, setCiudad] = React.useState<OptionCiudad | null>(null)
  const [controlaKardex, setControlaKardex] = React.useState<boolean>(false)

  const [listaPrecios, setListaPrecios] = React.useState<boolean>(false)
  const [dpPais, setDpPais] = React.useState<Array<OptionPais> | []>([])
  const [selectCiu, setSelectCiu] = React.useState<OptionCiudad>(null)
  const [tipoEstablecimiento, setTipoEstablecimiento] =
    React.useState<TipoEstablecimientoOption | null>(null)

  const setToast = React.useCallback(
    (texto, type: ToastTypes) => {
      let time: number = 3000
      if (texto.length < 50) {
        time = 5000
      } else if (texto.length > 50) {
        time = 9000
      }
      dispatch(
        addToast({
          id: '',
          autoHide: time,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const llenarCiudades = React.useCallback(async (idProvincia) => {
    try {
      const ciudades = [] as Array<OptionCiudad>
      const data = await localidadesService.getDpCiudades(
        idProvincia,
        'Elija una opción',
      )

      if (data && data.length > 0) {
        for (const item of data) {
          ciudades.push({
            codigoPais: Number(item?.paisCodigo) ?? 0,
            nombrePais: String(item?.paisNombre) ?? '',
            codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
            nombreProvincia: String(item?.provinciaNombre) ?? '',
            codigo: Number(item?.codigo) ?? 0,
            nombre: String(item?.nombre) ?? '',
          })
        }
      }
      return await ciudades
    } catch (error) {
      return await []
    }
  }, [])

  const llenarProvincias = React.useCallback(async (paisSeleccionado) => {
    const provincias = [] as Array<OptionProvincia>
    if (
      paisSeleccionado['provincias'] &&
      paisSeleccionado['provincias'].length > 0
    ) {
      await paisSeleccionado['provincias'].forEach((item: any) => {
        provincias.push({
          codigo: Number(item.codigo) ?? 0,
          codigoPais: Number(item.codigoPais) ?? 0,
          nombre: String(item.nombre) ?? '',
        })
      })
    }
    return provincias
  }, [])

  const consultaciudad = React.useCallback(async (data: any) => {
    const apiDataCiudadLocal = await localidadesService.consultarCiudades(
      data.ciudad,
    )

    const ciudadOtion: OptionCiudad = {
      codigoPais: apiDataCiudadLocal[0].paisCodigo,
      nombrePais: apiDataCiudadLocal[0].paisNombre,
      codigoProvincia: apiDataCiudadLocal[0].provinciaCodigo,
      nombreProvincia: apiDataCiudadLocal[0].provinciaNombre,
      codigo: apiDataCiudadLocal[0].codigo,
      nombre: apiDataCiudadLocal[0].nombre,
    }
    setSelectCiu(ciudadOtion)
    return ciudadOtion
  }, [])

  const parseSesionLocal = React.useCallback((apiresult: any): LocalEmpresa => {
    const localEmpresa: LocalEmpresa = {
      codigo: apiresult?.codigo ?? 0,
      nombre: apiresult?.nombre ?? '',
      ciudad: apiresult?.ciudad ?? null,
      ciudadCodigo: apiresult?.ciudad?.codigo ?? -1,
      ciudadNombre: apiresult?.ciudad?.nombre ?? '',
      clase: apiresult?.clase ?? 0,
      descuento: apiresult?.descuento ?? 0,
      direccion: apiresult?.direccion ?? '',
      telefono: apiresult?.telefono ?? '',
      tipoRol: apiresult?.tipoRol ?? '',
      establecimiento: apiresult?.establecimiento ?? '',
      siglas: apiresult?.siglas ?? '',
      uaf: apiresult.uaf ?? '',
      tipo: apiresult.tipo ?? 0,
      estado: apiresult?.estado ?? 0,
      controlaKardex: false,
    }
    return localEmpresa
  }, [])

  const parseSesionCiudad = React.useCallback((apiresult: any): Ciudad => {
    const ciudad: Ciudad = {
      codigo: apiresult[0].codigo,
      nombre: apiresult[0].nombre,
      provinciaCodigo: parseInt(apiresult[0].codigoProvincia),
      provinciaNombre: apiresult[0].nombreProvincia,
      paisCodigo: parseInt(apiresult[0].codigoPais),
      paisNombre: apiresult[0].nombrePais,
      rDEPCodigo: 0,
      codigoPaisInternacional: apiresult[0]?.codigoPaisInternacional ?? 1,
      codigoProvinciaOrigen: apiresult[0]?.codigoProvinciaOrigen ?? 1,
      codigoOrigen: apiresult[0]?.codigoOrigen ?? 1,
    }
    return ciudad
  }, [])

  const parseApiLocalEmpresaData = React.useCallback(
    async (apiresult: any): Promise<LocalDatosEdicion> => {
      let ciudad: OptionCiudad = null
      const localCiudad = JSON.parse(localStorage.getItem('ciudad'))

      if (!localCiudad) {
        ciudad = await consultaciudad(apiresult)
        localStorage.setItem('ciudad', JSON.stringify(ciudad))
      } else {
        ciudad = localCiudad
      }
      setSelectCiu(ciudad)
      setCiudad(ciudad)
      let siglas: string = ''

      if (apiresult.siglas === '') {
        const defaultSiglas = apiresult.nombre.substring(-1, 3)
        siglas = String(defaultSiglas).toUpperCase() ?? ''
      } else {
        siglas = String(apiresult.siglas).toUpperCase() ?? ''
      }

      const infoLocalEmpresa: LocalDatosEdicion = {
        edition: false,
        codigo: apiresult.codigo ?? 0,
        nombre: apiresult.nombre ?? '',
        direccion: apiresult.direccion ?? '',
        siglas: siglas ?? '',
        siglasBodega: apiresult.siglasBod ?? '',
        telefono: apiresult.telefono ?? '',
        descuento: apiresult.descuento ?? 0,
        claseCodigo: apiresult.claseCodigo ?? 0,
        gerente: apiresult.gerente ?? '',
        tipo: apiresult.tipo ?? 0,
        perteneceCodigo: apiresult.perteneceCodigo ?? 0,
        estadoCodigo: apiresult.estadoCodigo ?? 0,
        web: apiresult.web ?? '',
        uaf: apiresult.uaf ?? '',
        usuarioDokan: apiresult.usuarioDokan ?? '',
        passwordDokan: apiresult.passwordDokan ?? '',
        controlaKardex: apiresult.kardex == 0 ? false : true,
        listaPrecios: apiresult.listaPrecios ?? false,
        pais: pais ?? null,
        provincia: provincia ?? null,
        ciudad: ciudad ?? null,
        dpPais: dpPais ?? [],
        dpProvincia: [],
        dpCiudad: [],
        atributos: [],
        logo: apiresult.logo ?? null,
        loader: {
          show: false,
          mensaje: '',
        },
        loading: false,
        tieneError: false,
        mensajeError: '',
      }
      return infoLocalEmpresa
    },
    [llenarCiudades, llenarProvincias],
  )
  const getPaises = React.useCallback(async () => {
    if (dpPaisRedux.length === 0) {
      const paises = [] as Array<OptionPais>
      const data = await localidadesService.getDpPaises('Elija una opción')

      if (data && data.length > 0) {
        for (const item of data) {
          const provincias = [] as Array<OptionProvincia>
          if (item['provincias'] && item['provincias']?.length > 0) {
            for (const provItem of item.provincias) {
              provincias.push({
                codigoPais: Number(provItem.codigoPais) ?? 0,
                codigo: Number(provItem.codigo) ?? 0,
                nombre: String(provItem.nombre) ?? '',
              })
            }
          }
          paises.push({
            ...item,
            codigo: Number(item.codigo) ?? 0,
            descripcion: String(item.nombre) ?? '',
            nombre: String(item.nombre) ?? '',
            codigoUaf: String(item.codigoUaf) ?? '',
            provincias: provincias ?? [],
          })
        }
      }
      return paises
    } else {
      return dpPaisRedux
    }
  }, [dpPaisRedux])

  const modoNuevo = React.useCallback(
    async (template: number, limpiar: boolean = false) => {
      if (loading === false && !limpiar) {
        return
      }
      const data = { ...defaultEditionLocalEmpresa }
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: { ...data },
        }),
      )
    },
    [dispatch, tabId],
  )
  const modoEdicion = React.useCallback(
    async (reload: boolean = false, dataEdicion: TLocalData) => {
      setLoaderLocal({ show: true, message: 'Cargando...' })

      const dataLocalEmpresaEdition = dataEdicion
      setDatosEdicion2(dataLocalEmpresaEdition)
      try {
        let dataApi: any = []
        let infoParse: LocalDatosEdicion = {
          edition: false,
          codigo: 0,
          nombre: '',
          direccion: '',
          siglas: '',
          siglasBodega: '',
          telefono: '',
          descuento: 0,
          claseCodigo: 0,
          gerente: '',
          tipo: 0,
          perteneceCodigo: 0,
          estadoCodigo: 0,
          web: '',
          uaf: '',
          usuarioDokan: '',
          passwordDokan: '',
          controlaKardex: false,
          listaPrecios: false,
          pais: null,
          provincia: null,
          ciudad: null,
          dpPais: [],
          dpProvincia: [],
          dpCiudad: [],
          atributos: [],
          logo: null,
          loader: {
            show: false,
            mensaje: '',
          },
          loading: false,
          tieneError: false,
          mensajeError: '',
        }
        infoParse.edition = false

        dataApi = dataEdicion

        infoParse = await parseApiLocalEmpresaData(dataEdicion)

        if (reload) {
          dataApi = await sidebarService.localExtraer(
            dataLocalEmpresaEdition?.codigo ?? 0,
          )

          infoParse = await parseApiLocalEmpresaData(dataApi)
        }
        if (String(infoParse.logo).length > 0) {
          setImageSource(infoParse.logo ?? '')
        }

        /*  if (dataApi.codigo !== 0) {
          setDpPais(infoParse.dpPais)
          setDpProvincia(infoParse.dpProvincia)
          setDpCiudad(infoParse.dpCiudad)

          setFile(null)
          setNombre(infoParse.nombre)
          setDireccion(infoParse.direccion)
          setSiglas(infoParse.siglas)
          setDescuento(infoParse.descuento)
          setTelefono(infoParse.telefono)
          setPais(infoParse.pais)
          setProvincia(infoParse.provincia)
          setCiudad(infoParse.ciudad)
          setControlaKardex(infoParse.controlaKardex)
          setListaPrecios(infoParse.listaPrecios)
        } */
        const tiposEstApi =
          await tipoEstablecimientoService.getTiposEstablecimientos(
            'Elija una opción',
            infoParse?.pais?.codigo,
          )
        if (tiposEstApi.auto && tiposEstApi.auto.length > 0) {
          const selectedIndex = tiposEstApi.auto.findIndex(
            (select: TipoEstablecimientoOption) => {
              return select.codigo === infoParse?.tipo
            },
          )
          if (selectedIndex > -1) {
            setTipoEstablecimiento(tiposEstApi.auto[selectedIndex])
          }
        }
        dispatch(
          setDatosEdicion({
            key: tabId,
            data: { ...infoParse },
          }),
        )
        //dispatch(reducerSidebar.setConfigLocalEmpresa(infoParse))
        setLoaderLocal({ show: false, message: '' })
      } catch (error) {
        console.log(error)
        setLoaderLocal({ show: false, message: JSON.stringify(error) })
      }
    },
    [parseApiLocalEmpresaData, getPaises, dispatch],
  )

  const convertBase64 = React.useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const onSelectedFilesChangedDec = React.useCallback(
    async (e) => {
      if (e) {
        setFile(e[0])
        for (const item of e) {
          let fileCoded: any = ''

          await convertBase64(item)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })

          setImageSource(fileCoded)
        }
      }
    },
    [convertBase64, file],
  )
  const validarFormatoPermitido = React.useCallback((file) => {
    let resRetorno: boolean = false
    let smsErrorsLogo: any = []
    let extention: string = ''
    if (file && file.length > 0) {
      extention = utilidades.getExtension(file[0].name)

      extention = extention.toLowerCase()

      if (allowExtention.includes('.' + extention)) {
        smsErrorsLogo = []
        resRetorno = true
      } else {
        const sms: string = 'El formato permitido es .jpg'
        smsErrorsLogo.push({ sms })
        resRetorno = false
      }
      setErrorsLogo(smsErrorsLogo)
      stopLoader()
    }
    stopLoader()
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          ...ELocalButtons,
          Guardar: true,
          Imprimir: true,
        },
      }),
    )
    return resRetorno
  }, [])

  const playLoader = React.useCallback(async () => {
    setLoaderLocal({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderLocal({ show: false, message: '' })
  }, [])

  const onChangeListaPrecios = React.useCallback((data) => {
    if (data !== undefined) {
      setListaPrecios(data)
    }
  }, [])

  const onChangeControlaKardex = React.useCallback(
    (data) => {
      if (data !== undefined) {
        dispatch(
          setDatosEdicion({
            key: tabId,
            data: {
              ...dataEdicion,
              ['controlaKardex']: data,
            },
          }),
        )
      }
    },
    [dispatch, dataEdicion, tabId],
  )

  const onSetButtonAction = useCallback(
    (tipo: ButtonTypes | undefined) => {
      dispatch(
        setCurrentExecutingAction({
          tabKey: tabId,
          buttonType: tipo,
        }),
      )
    },
    [dispatch, tabId],
  )

  const validarFormulario = React.useCallback(async () => {
    const errors: any = []
    if (dataEdicion.ciudad === null || dataEdicion.ciudad.codigo < 0) {
      errors.push('Ciudad: Debe seleccionar una opción.')
    }
    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors,
      }
    } else {
      return {
        errors: false,
        messages: errors,
      }
    }
  }, [dataEdicion, ciudad])

  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    dialogRef.current.show({
      title: (
        <>
          <strong>{'Ácatha'}</strong>
        </>
      ),
      body: (
        <>
          <strong style={{ fontSize: '14px', margin: '0px', padding: '0px' }}>
            {tittle}
          </strong>
          <br />
          <CAlert color="danger">
            <ul className="errorList">
              {messages.map(function (item, id) {
                return <li key={id}>{item}</li>
              })}
            </ul>
          </CAlert>
        </>
      ),
      actions: [
        Dialog.Action(
          <span>
            <u>{'A'}</u>
            {'ceptar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-success',
          'n',
        ),
        Dialog.Action(
          <span>
            <u>{'C'}</u>
            {'ancelar'}
          </span>,
          (dialog) => {
            dialog.hide()
          },
          'btn-danger',
          'c',
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      },
    })
    return
  }, [])
  const onChangeCity = React.useCallback(
    async (e) => {
      localStorage.setItem('ciudad', JSON.stringify(e))

      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            ['ciudad']: e,
          },
        }),
      )
      setCiudad(e)
    },
    [dispatch, dataEdicion, tabId],
  )

  const onChangeValue = React.useCallback(
    (data, key: string) => {
      dispatch(
        setDatosEdicion({
          key: tabId,
          data: {
            ...dataEdicion,
            [key]: data,
          },
        }),
      )
    },
    [dispatch, dataEdicion, tabId],
  )
  const handleSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()

    playLoader()

    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', ToastTypes.Info)
      setShowErrorPopup(true)

      setShowErrorMessages(result.brokenRules)
      stopLoader()
      onSetButtonAction(undefined)
      return false
    } else {
      setShowErrorPopup(false)
      setShowErrorMessages([])
      const resValidar = await validarFormulario()
      if (resValidar['errors'] === true) {
        onErrorConfirm(
          resValidar['messages'],
          'Antes de continuar revise lo siguiente:',
        )
        stopLoader()
        onSetButtonAction(undefined)
        return false
      }
      onSetButtonAction(ButtonTypes.save)
      if (localesContrato !== 0) {
        const misLocales = await LocalesService.countLocales()

        if (datosEdicion2.length === 0) {
          if (misLocales >= localesContrato) {
            setToast(
              'Usted ha alcanzado el número máximo de locales permitidos para este plan.',
              ToastTypes.Info,
            )
            dispatch(
              setButtons({
                tabKey: tabId,
                buttons: {
                  ...ELocalButtons,
                  Guardar: true,
                  Imprimir: true,
                },
              }),
            )
            stopLoader()
            onSetButtonAction(undefined)
            return false
          }
        }
      }

      const dataLocal: any = {
        codigo: dataEdicion?.codigo ? dataEdicion?.codigo : 0,
        clase: 0,
        nombre: dataEdicion.nombre ?? '',
        siglas: siglas ? siglas : dataEdicion.nombre.substring(0, 3),
        ciudad: dataEdicion.ciudad?.codigo ?? 0,
        direccion: dataEdicion.direccion,
        telefono: dataEdicion.telefono,
        gerente: dataEdicion?.gerente ? dataEdicion?.gerente : '',
        tipo: tipoEstablecimiento?.codigo ?? 0,
        pertenece: 0,
        kardex: dataEdicion.controlaKardex === true ? 1 : 0,
        estado: 1,
        siglasBOD: dataEdicion?.siglas,
        web: datosEdicion2?.web,
        empresaCodigo: empresaState?.codigo,
        uaf: dataEdicion?.uaf ? dataEdicion?.uaf : '',
        descuento: dataEdicion.descuento ?? 0,
        usuarioDokan: dataEdicion?.usuarioDokan
          ? dataEdicion?.usuarioDokan
          : '',
        passwordDokan: dataEdicion?.passwordDokan
          ? dataEdicion?.passwordDokan
          : '',
        listaPrecios: listaPrecios === true ? 1 : 0,
        nameLogo: dataEdicion?.logo === null ? '' : dataEdicion?.logo ?? '',
        codeFileLogo: '',
      }

      if (file !== null) {
        const extentionFile: string = file['name'].split('.').pop() ?? ''

        if (extentionFile !== 'jpg') {
          stopLoader()
          setToast(
            'El formato del logo ingresado no es permitido.',
            ToastTypes.Info,
          )
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ELocalButtons,
                Guardar: true,
                Imprimir: true,
              },
            }),
          )
          onSetButtonAction(undefined)
          return false
        }

        dataLocal.nameLogo =
          (empresaState && empresaState['codigo']) + '_' + '.' + extentionFile
        let fileCoded: any = ''

        if (typeof file === 'object') {
          await convertBase64(file)
            .then((data) => {
              fileCoded = data
            })
            .catch((data) => {
              fileCoded = data
            })
        } else {
          fileCoded = file
        }
        dataLocal.codeFileLogo = fileCoded
      }

      if (dataLocal?.nameLogo) {
        if (dataLocal.nameLogo.includes('null')) {
          dataLocal.nameLogo = ''
        } else if (dataLocal.nameLogo.includes('/')) {
          const fileName = utilidades.getTextAfterLastSlash(dataLocal.nameLogo)
          dataLocal.nameLogo = fileName
        }
      }
      try {
        const data = await sidebarService.datosLocalActualizar(dataLocal)
        const apiDataCiudadLocal = await localidadesService.consultarCiudades(
          ciudad?.codigo,
        )
        stopLoader()
        if (data['error'] === true) {
          setToast(data['message'], ToastTypes.Warning)
          stopLoader()

          onSetButtonAction(undefined)
          dispatch(
            setButtons({
              tabKey: tabId,
              buttons: {
                ...ELocalButtons,
                Guardar: true,
                Imprimir: true,
              },
            }),
          )
          return false
        } else {
          setDatosEdicion2(dataLocal)
          if (dataLocal.codigo === 0) {
            dispatch(
              setButtons({
                tabKey: tabId,
                buttons: {
                  ...ELocalButtons,
                  Guardar: true,
                  Imprimir: true,
                },
              }),
            )
          }
          if (dataLocal.codigo !== 0) {
            dispatch(
              setButtons({
                tabKey: tabId,
                buttons: {
                  ...ELocalButtons,
                  Guardar: true,
                  Imprimir: true,
                },
              }),
            )
          }
          dispatch(
            setNameTab({
              key: tabId,
              nombre: dataLocal?.nombre ?? '',
              codigo: data?.auto ?? 0,
            }),
          )
          if (dataLocal.codigo === sessionStatus.local.codigo) {
            dispatch(
              establecerSession({
                empresa: sessionStatus.empresa,
                local: { ...dataLocal, ciudad: apiDataCiudadLocal[0] },
                usuario: sessionStatus.usuario,
                ingreso: sessionStatus.ingreso,
              }),
            )
          }
          // update session store local
          //dataLocal.ciudad = ciudad
        }
      } catch (error) {
        onSetButtonAction(undefined)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              ...ELocalButtons,
              Guardar: true,
              Imprimir: true,
            },
          }),
        )
        stopLoader()
        setToast(error, ToastTypes.Danger)
      }
    }
  }, [
    playLoader,
    setToast,
    stopLoader,
    dispatch,
    onErrorConfirm,
    modoEdicion,
    empresaState,
    convertBase64,
    file,
    nombre,
    direccion,
    siglas,
    descuento,
    telefono,
    ciudad,
    controlaKardex,
    listaPrecios,
    tipoEstablecimiento,
    parseSesionLocal,
    usuarioState,
    ingresoState,
    parseSesionCiudad,
    dataEdicion,
  ])

  const onChangeFile = React.useCallback(
    (data) => {
      validarFormatoPermitido(data)
      onSelectedFilesChangedDec(data)
    },
    [validarFormatoPermitido, onSelectedFilesChangedDec],
  )

  const handleButtonClick = React.useCallback(
    async (buttonAction: ButtonTypes) => {
      //let ciudaddselect=null;
      switch (buttonAction) {
        case ButtonTypes.undo:
          // eslint-disable-next-line no-case-declarations
          setImageSource(dataEdicion.logo ?? '')
          dispatch(
            setDatosEdicion({
              key: tabId,
              data: { ...datosEdicion2, ciudad: selectCiu },
            }),
          )
          break
        case ButtonTypes.save:
          dispatch(
            setCurrentExecutingAction({
              tabKey: tabId,
              buttonType: ButtonTypes.save,
            }),
          )
          if (tabs.current === tabId) handleSubmit()
          break
        default:
          break
      }
      dispatch(clearButtonClick(tabId))
    },
    [
      tabId,
      tabs,
      info,
      modoNuevo,
      dispatch,
      modoEdicion,
      selectCiu,
      onSetButtonAction,
    ],
  )

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(tab.info?.codigo ?? 0)
    } else {
      modoEdicion(false, info.info)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }}>
      <div>
        <div>
          <Dialog ref={dialogRef} />
        </div>
        {showErrorPopup && (
          <PopupContent
            show={showErrorPopup}
            title={'Acatha'}
            subTitle={'Por favor revise las siguientes advertencias:'}
            onClose={() => {
              setShowErrorPopup(false)
              setShowErrorMessages([])
            }}
            size={'sm'}
            canClose={true}
            height={'auto'}
          >
            <CAlert color="danger">
              <ul style={{ listStyle: 'none', margin: '0px', padding: '0px' }}>
                {showErrorMessages.map(function (item, id) {
                  return <li key={id}>{item['message']}</li>
                })}
              </ul>
            </CAlert>
          </PopupContent>
        )}
        {openAtributos === true && (
          <AtributosLocal
            show={openAtributos}
            onClose={() => {
              setButtons({
                tabKey: tabId,
                buttons: {
                  ...ELocalButtons,
                  Guardar: true,
                  Imprimir: true,
                },
              }),
                setOpenAtributos(false)
            }}
            onChange={() => {
              setButtons({
                tabKey: tabId,
                buttons: {
                  ...ELocalButtons,
                  Guardar: true,
                  Imprimir: true,
                },
              }),
                setOpenAtributos(false)
              // initDatosLocalEmpresa(true)
            }}
            atributos={[]}
            setToast={setToast}
          />
        )}

        <div id="configLocal">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loaderLocal?.show ?? false}
            message={loaderLocal?.message ?? ''}
          >
            <fieldset disabled={loaderLocal.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <CCard style={{ border: 1 }} className="m-0">
                  <ValidationSummary />

                  <CCardBody>
                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Nombre Local*:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.nombre ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'nombre')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Nombre Local: Este campo es requerido" />
                                  <StringLengthRule
                                    min="4"
                                    message="- Nombre Local: Este campo debe tener al menos 4 caracteres"
                                  />
                                  <StringLengthRule
                                    max="75"
                                    message="- Nombre Local: Este campo no puede tener mas de 75 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Dirección(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <TextArea
                                value={dataEdicion?.direccion ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'direccion')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Dirección: Este campo es requerido" />
                                  <StringLengthRule
                                    min="8"
                                    message="- Dirección: Este campo debe tener al menos 8 caracteres"
                                  />
                                  <StringLengthRule
                                    max="150"
                                    message="- Dirección: Este campo no puede tener mas de 150 caracteres"
                                  />
                                </Validator>
                              </TextArea>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      {/*   <CCol className=" d-flex " md="6">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Siglas(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <TextBox
                                value={siglas}
                                placeholder=""
                                onValueChange={(data) => {
                                  setSiglas(data)
                                }}
                                showClearButton={true}
                                width="100%"
                                onEnterKey={() => {
                                  handleSubmit()
                                }}
                              >
                                <Validator>
                                  <RequiredRule message="- Siglas: Este campo es requerido" />
                                  <StringLengthRule
                                    min="3"
                                    message="- Siglas: Este campo debe tener al menos 3 caracteres"
                                  />
                                  <StringLengthRule
                                    max="10"
                                    message="- Siglas: Este campo no puede tener mas de 10 caracteres"
                                  />
                                </Validator>
                              </TextBox>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol> */}

                      <CCol className=" d-flex " md="6">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Descuento(*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputNumberDE
                                value={dataEdicion?.descuento ?? 0}
                                placeholder="3.99"
                                onValueChange={(e) => {
                                  onChangeValue(e, 'descuento')
                                }}
                                width="100%"
                                onEnterKey={() => {
                                  handleSubmit()
                                }}
                                min={0.0}
                                max={100}
                                showSpinButtons={true}
                                showClearButton={true}
                                defaultValue={0}
                              >
                                <Validator>
                                  <RequiredRule message="- Descuento: Ingrese un valor en descuento o al menos (0)" />
                                </Validator>
                              </InputNumberDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                      <CCol className=" d-flex " md="6">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Teléfono (*):'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <InputTextDE
                                value={dataEdicion?.telefono ?? ''}
                                placeholder=""
                                onValueChange={(e) => {
                                  onChangeValue(e, 'telefono')
                                }}
                                showClearButton={true}
                                width="100%"
                              >
                                <Validator>
                                  <RequiredRule message="- Teléfono: Este campo es requerido" />
                                  <StringLengthRule
                                    min="6"
                                    message="- Teléfono: Este campo debe tener al menos 6 caracteres"
                                  />
                                  <StringLengthRule
                                    max="15"
                                    message="- Teléfono: Este campo no puede tener mas de 15 caracteres"
                                  />
                                </Validator>
                              </InputTextDE>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow></CRow>

                    <CRow>
                      <CCol className=" d-flex " md="6">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Ciudad :'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <LocalidadesLookUp
                                onChanged={(data) => {
                                  onChangeCity(data)
                                }}
                                onChangedOptions={() => {}}
                                selected={
                                  (dataEdicion?.ciudad as OptionCiudad) ?? null
                                }
                                allowEdit
                                allowClear
                              >
                                <Validator>
                                  <RequiredRule
                                    message={
                                      'Municipio: Este campo es requerido'
                                    }
                                  />
                                </Validator>
                              </LocalidadesLookUp>
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                      <CCol className=" d-flex " md="6">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Tipo Establecimiento :'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <LookUpTipoEstablecimiento
                                onChanged={(data) => {
                                  setTipoEstablecimiento(data)
                                }}
                                selected={tipoEstablecimiento}
                                paisCodigo={3}
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Transacciones en negativo*:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <Switch
                                value={dataEdicion?.controlaKardex ?? false}
                                onValueChange={onChangeControlaKardex}
                                switchedOffText="NO"
                                switchedOnText="SI"
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                    {/* <CRow>
                      <CCol className=" d-flex " md="12">
                        <div className="dx-field" style={{ width: '100%' }}>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <div
                                className="dx-field-label"
                                style={{ width: '100%' }}
                              >
                                {'Utilizar Lista de Precio*:'}
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12" md="12" sm="12">
                              <Switch
                                value={listaPrecios}
                                onValueChange={onChangeListaPrecios}
                                switchedOffText="NO"
                                switchedOnText="SI"
                              />
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow> */}

                    {/*  <CRow>
                      <CCol lg="12" className={'mt-2 mb-2'}>
                        <CCard className={'p-2'}>
                          <CRow>
                            <CCol lg="12" className={'mt-2 mb-2'}>
                              <strong className="m-2">{'ATRIBUTOS'}</strong>
                              <CTooltip
                                key="title"
                                placement="top"
                                content={'Editar Atributos'}
                              >
                                <CIcon
                                  onClick={() => {
                                    setOpenAtributos(true)
                                  }}
                                  className="ms-3"
                                  size="sm"
                                  icon={cilPencil}
                                />
                              </CTooltip>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12">
                              <ul style={{ listStyle: 'none' }}>
                                {atributos.length > 0 &&
                                  atributos.map(function (x, i) {
                                    return (
                                      <li key={i}>
                                        <div key={i.toString()}>
                                          <span key={'labSpan-' + i}>
                                            {' ' + x.atributo + ' : '}
                                          </span>
                                          <label
                                            key={'lab-' + i}
                                            style={{
                                              backgroundColor: String(
                                                x?.atributo,
                                              )
                                                .toLocaleLowerCase()
                                                .includes('color')
                                                ? String(x?.valor)
                                                : '',
                                            }}
                                          >
                                            {' ' + x.valor}
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  })}
                              </ul>
                            </CCol>
                          </CRow>
                        </CCard>
                      </CCol>
                    </CRow> */}

                    <CTooltip
                      key={'tool-subirLogoLocal'}
                      placement="top"
                      content={
                        'Medidas recomendadas 487x295 px y en formato (.jpg) '
                      }
                    >
                      <CLink> {'Carga tu Logo'} </CLink>
                    </CTooltip>
                    <br />
                    <small>
                      {'Medidas recomendadas 487x295 px y en formato (.jpg) '}
                    </small>
                    <CRow className="sectionErrorsLogo">
                      <CCol lg="12" className="sectionErrorsLogo">
                        <ul className="sectionErrorsLogo">
                          {errorsLogo.map((objFile, i) => {
                            return (
                              <li key={i} className="smsError">
                                {objFile['sms']}
                              </li>
                            )
                          })}
                        </ul>
                      </CCol>
                    </CRow>
                    {(file !== null || imageSource !== '') && (
                      <CRow className="mt-1 mb-1">
                        <CCol lg="12">
                          <CLink
                            className="card-header-action sectionErrorsLogo"
                            onClick={() => {
                              /* dispatch(
                              reducerSidebar.setConfigLocalEmpresa({
                                ...configLocalEmpresaRedux,
                                logo: null,
                              }),
                            ) */
                              setFile(null)
                              setImageSource(dataEdicion.logo ?? '')
                              setErrorsLogo([])
                              imageLogo.current.instance.reset()
                            }}
                          >
                            {'Reset Logo'}
                          </CLink>
                        </CCol>
                      </CRow>
                    )}

                    <CRow className="m-0">
                      <CCol lg="12" className="sectionErrorsLogo">
                        <FileUploader
                          showFileList={false}
                          multiple={false}
                          accept={'.jpg,.jpeg'}
                          ref={imageLogo}
                          id="subirLogo"
                          labelText=""
                          selectButtonText="Seleccionar logo"
                          uploadMode="useForm"
                          onValueChange={onChangeFile}
                          maxFileSize={FILE_SIZE}
                          allowedFileExtensions={['.jpg', '.jpeg']}
                        />

                        <div className="fileuploader-container" key={uuidv4()}>
                          {imageSource && (
                            <ImagenEmpresa imagenBlob={imageSource} />
                          )}
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </div>
    </div>
  )
}
export default React.memo(MLocal)

export const ELocalButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
}

export const defaultEditionLocalEmpresa: LocalDatosEdicion = {
  codigo: 0,
  loader: null,
  loading: false,
  tieneError: false,
  mensajeError: '',
  edition: false,
  nombre: '',
  direccion: '',
  siglas: '',
  siglasBodega: '',
  telefono: '',
  descuento: 0,
  claseCodigo: 0,
  gerente: '',
  tipo: 0,
  perteneceCodigo: 0,
  estadoCodigo: 0,
  web: '',
  uaf: '',
  usuarioDokan: '',
  passwordDokan: '',
  controlaKardex: false,
  listaPrecios: false,
  pais: null,
  provincia: null,
  ciudad: null,
  dpPais: [],
  dpProvincia: [],
  dpCiudad: [],
  atributos: [],
  logo: null,
}
