import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { anexoTransaccionalSVService } from '../../../services/anexoTransaccionalSV.services'
import {
  ComprobanteDte,
  DteType,
  FiltroBusquedaAnexoTransaccionalState,
  SearchStateAnexoTransaccional,
} from '../types/types'
import { FETCH_STATUS } from '../../../../../../../../store/types'
import { RootState } from '../../../../../../../../store/store'
import { DateUtils } from '../../../../../../../../helpers/dateUtils'

const initialState: SearchStateAnexoTransaccional = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFinal: DateUtils.getCurrentDateAsString(),
    dteOptions: [],
    dte: {
      '01': true,
    },
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: [],
  finalizaBusqueda: false,
}

export const fetchAnexoTransaccional = createAsyncThunk<
  Array<ComprobanteDte> | [],
  FiltroBusquedaAnexoTransaccionalState
>('integraciones/sv/getDataDte', async (filtro) => {
  try {
    const anexoData = await anexoTransaccionalSVService.getAnexoTransac(filtro)

    return anexoData
  } catch (error) {
    return Promise.reject(error)
  }
})

const searchAnexoSlice = createSlice({
  name: 'searchAnexosSV',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
        },
      }
    },
    changeFilter(
      state,
      action: PayloadAction<FiltroBusquedaAnexoTransaccionalState>,
    ) {
      state.filter = action.payload
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFinal = action.payload
    },
    setCleanResult(state) {
      state.resultados = []
      state.finalizaBusqueda = false
    },
    setResultados(state, action: PayloadAction<Array<ComprobanteDte> | []>) {
      state.resultados = action.payload
      state.finalizaBusqueda = false
    },
    setResetEndBusqueda(state) {
      state.finalizaBusqueda = false
    },
    setDte(state, action: PayloadAction<DteType>) {
      state.filter.dte = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnexoTransaccional.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING
        state.mensaje = ''
      }
    })
    builder.addCase(fetchAnexoTransaccional.fulfilled, (state, { payload }) => {
      state.resultados = payload
      state.finalizaBusqueda = true
      state.status = FETCH_STATUS.SUCCESS
    })
    builder.addCase(fetchAnexoTransaccional.rejected, (state, { payload }) => {
      state.resultados = []
      state.finalizaBusqueda = false
      state.status = FETCH_STATUS.FAILED
      state.mensaje = payload as string
    })
  },
})

export const selectFiltroBusquedaAnexos = (state: RootState) => {
  return state.impuestosSV.anexoTransaccional.search.filter
}
export const selectResultadosBusquedaAnexos = (state: RootState) => {
  return state.impuestosSV.anexoTransaccional.search.resultados
}
export const selectBusquedaAnexosEstado = (state: RootState) => {
  return state.impuestosSV.anexoTransaccional.search.status
}
export const {
  setResetEndBusqueda,
  setResultados,
  resetState,
  setCleanResult,
  setDateStart,
  setDateEnd,
  changeFilter,
  setDte,
} = searchAnexoSlice.actions
export const searchAnexoReducer = searchAnexoSlice.reducer
