import { ItemPedido } from '../modulos/ventas/pages/ordenes/pages/ordenPedido/type/types'
import { consoleService } from '../services/console.service'
import { TiposComprobantesSLV } from '../store/types'
import { utilidades } from './utilidades'

export interface IProdDetail {
  codigo: number
  codigoInterno: string
  codigoBarras: string
  descripcion: string
  tieneIva: boolean
  porcentajeiva: number
  imagenes: string[] // Supongo que las imágenes son URLs o rutas a archivos de imágenes
  fecha: string
  _iva: number
  _subtotal: number
  _subtotal0: number
  _subtotalIva: number
  _total: number
  _cantidad: number
  _precioUnitario: number
  _precioIva: number
  _descuento: number
  _porcentajeDescuento: number
  codigoImpuesto?: number
}

export type IProdDetailSV = {
  numItem: number
  tipoItem: number
  numeroDocumento: string | null
  cantidad: number
  codigo: string
  codigoBarras: string
  codTributo: string | null
  uniMedida: number
  descripcion: string
  precioUni: number
  montoDescu: number
  ventaNoSuj: number
  ventaExenta: number
  ventaGravada: number
  tributos: any[] | null // Puedes definir una interfaz específica para los tributos si es necesario
  ivaItem: number | null
  psv?: number
  noGravado?: number
}
export interface IProdDetailReteSV {
  numItem: number
  tipoDte: string
  tipoDoc: number
  numDocumento: string
  fechaEmision: string
  montoSujetoGrav: number
  codigoRetencionMH: string
  ivaRetenido: number
  descripcion: string
}

function calcularIvaIncluido(cantidad: number, tasaIva: number): number {
  const base = cantidad / (1 + tasaIva)
  const ivaIncluido = base * tasaIva
  return parseFloat(ivaIncluido.toFixed(2)) // Redondear a 2 decimales
}

export const FormatProductsElectronicInvoiceSVCCF = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  consoleService.log('FormatProductsElectronicInvoiceSVCCF')
  consoleService.log('IProdDetail', IProdDetail)

  const products = []
  IProdDetail.map((product, index) => {
    consoleService.log('product', product)

    const {
      codigo,
      codigoBarras,
      descripcion,
      _subtotal0,
      _subtotalIva,
      _cantidad,
      _precioIva,
      _descuento,
      _iva,
      porcentajeiva,
    } = product

    const esExentoIva = Number(_iva) <= 0
    const cantidad = _cantidad

    let _precioUnitario: number = product?._precioUnitario

    if (!esExentoIva) {
      _precioUnitario = _precioIva / (porcentajeiva + 1)
    }

    // Calcular producto con IVA
    const productoPrecioConIva = Number(_precioUnitario.toFixed(4))

    //  Calcular el subtotal (sin IVA)
    const subtotalSinIva = cantidad * productoPrecioConIva

    // Calcular el valor total del IVA
    const ventaGravada =
      Number(productoPrecioConIva * cantidad) - Number(_descuento)

    const ventaExenta = esExentoIva ? subtotalSinIva - Number(_descuento) : 0

    const desglosarIva = ventaGravada * porcentajeiva
    consoleService.log('desglosarIva', desglosarIva)

    const itemProd = {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: Number(_precioUnitario.toFixed(4)) ?? 0,
      montoDescu: Number(_descuento.toFixed(4)),
      ventaNoSuj: 0,
      ventaExenta: _subtotal0 > 0 ? Number(ventaExenta.toFixed(4)) : 0,
      ventaGravada: _subtotalIva > 0 ? Number(ventaGravada.toFixed(4)) : 0,
      tributos: !esExentoIva ? ['20'] : null,
      ivaItem: !esExentoIva ? Number(desglosarIva.toFixed(4)) : 0,
      psv: 0,
      noGravado: 0,
    }
    consoleService.log('itemProd', itemProd)

    products.push(itemProd)
  })
  console.log('on FormatProductsElectronicInvoiceSVCCF products', products)
  return products
}

export const FormatProductsElectronicInvoiceSVRetencion = (
  tipoDoc: TiposComprobantesSLV,
  numDoc: string,
  fechaEmision: string,
  precision = 2,
  detalles,
): IProdDetailReteSV[] => {
  return detalles.map((detalle, index) => {
    //fecha actual sv
    const { baseImponible, concepto } = detalle
    let { porcentaje } = detalle
    porcentaje = porcentaje / 100

    const ivaRete = utilidades.formatDecimal(
      baseImponible * porcentaje,
      precision,
    )
    ///"2023-11-20", "23/02/2024"
    const [dia, mes, anio] = fechaEmision.split('/')

    return {
      numItem: index + 1,
      tipoDte: tipoDoc == '14' ? null : tipoDoc,
      tipoDoc: tipoDoc == '14' ? 1 : 2,
      numDocumento: tipoDoc == '14' ? null : numDoc,
      fechaEmision: tipoDoc == '14' ? null : `${anio}-${mes}-${dia}`,
      montoSujetoGrav: baseImponible,
      codigoRetencionMH: concepto.codigo,
      ivaRetenido: ivaRete,
      descripcion: concepto.concepto,
    }
  })
}

export const FormatProductsElectronicInvoiceSVNotaCredito = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products: IProdDetailSV[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _subtotal0,
      _subtotalIva,
      _cantidad,
      _precioUnitario,
      _descuento,
      _iva,
    } = product

    const iva2 = 13
    const cantidad = _cantidad

    const esExentoIva = Number(_iva) <= 0
    const productoPrecioSinIva = Number(_precioUnitario)

    // Calcular producto con IVA
    const productoPrecioConIva = utilidades.formatDecimal(
      productoPrecioSinIva * (1 + iva2 / 100),
      precision,
    )
    const ventaConIva = utilidades.formatDecimal(
      cantidad * productoPrecioConIva,
      precision,
    )

    // Calcular el subtotal (sin IVA)
    const subtotal = utilidades.formatDecimal(
      cantidad * productoPrecioSinIva,
      precision,
    )

    // Calcular el valor total del IVA
    const ivaTotal = utilidades.formatDecimal(ventaConIva - subtotal, precision)

    // Calcular el total (con IVA)
    const total = productoPrecioSinIva * cantidad
    const precioUni = utilidades.formatDecimal(productoPrecioSinIva, precision)
    const descuento = utilidades.formatDecimal(_descuento, precision)

    const ventaGravada = utilidades.formatDecimal(
      precioUni * cantidad - descuento,
      precision,
    )

    return {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: utilidades.formatDecimal(productoPrecioSinIva, precision),
      montoDescu: utilidades.formatDecimal(_descuento, precision),
      ventaNoSuj: 0,
      ventaExenta: esExentoIva
        ? utilidades.formatDecimal(_subtotal0, precision)
        : 0,
      ventaGravada: !esExentoIva
        ? utilidades.formatDecimal(Number(ventaGravada), precision)
        : 0,
      tributos: !esExentoIva ? ['20'] : null,
      psv: 0,
      noGravado: 0,
      ivaItem: ivaTotal,
    }
  })
  return products
}

export const FormatProductsElectronicInvoiceSV = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products = []
  IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      _cantidad,
      _precioUnitario,
      _descuento,
      _iva,
      porcentajeiva,
      _subtotalIva,
      _subtotal0,
      _precioIva,
    } = product

    const esExentoIva = Number(_iva) <= 0

    const itemProd = {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: esExentoIva ? _precioUnitario : _precioIva,
      montoDescu: _descuento,
      ventaNoSuj: 0,
      ventaExenta: _subtotal0 > 0 ? _subtotal0 : 0,
      ventaGravada: _subtotalIva > 0 ? _subtotalIva : 0,
      tributos: null,
      psv: 0,
      noGravado: 0,
      ivaItem: _iva,
    }
    console.log('itemProd', itemProd)
    products.push(itemProd)
  })
  return products
}
export const FormatProductsElectronicInvoiceSVPrueba = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  try {
    const products: IProdDetailSV[] = IProdDetail.map(
      (product: ItemPedido, index) => {
        const {
          codigo,
          codigoBarras,
          descripcion,
          pvd: _precioUnitario,
          pvp,
          impuestoValor: _iva,
        } = product

        const _cantidad = 1
        const _descuento = 0
        const esExentoIva = Number(_iva) <= 0

        const iva2 = 13
        const cantidad = _cantidad

        const productoPrecioSinIva = _precioUnitario > 0 ? _precioUnitario : pvp

        // Calcular producto con IVA
        const productoPrecioConIva = utilidades.formatDecimal(
          productoPrecioSinIva * (1 + iva2 / 100),
          precision,
        )

        const precioSinIva = utilidades.formatDecimal(
          productoPrecioSinIva,
          precision,
        )
        const ventaConIva = utilidades.formatDecimal(
          cantidad * productoPrecioConIva,
          precision,
        )

        // Calcular el subtotal (sin IVA)
        const subtotalSinIva = utilidades.formatDecimal(
          cantidad * productoPrecioSinIva,
          precision,
        )
        const descu = utilidades.formatDecimal(_descuento, precision)

        // Calcular el valor total del IVA
        let ventaGravada =
          Number(productoPrecioConIva) * Number(cantidad) - Number(descu)
        ventaGravada = utilidades.formatDecimal(ventaGravada, precision)

        let ventaExenta = esExentoIva ? subtotalSinIva - Number(descu) : 0
        ventaExenta = utilidades.formatDecimal(ventaExenta, precision)
        const ivaTotal = utilidades.formatDecimal(
          ventaGravada - ventaGravada / (1 + iva2 / 100),
          precision,
        )

        // Calcular el total (con IVA)
        const total = ventaConIva

        return {
          numItem: index + 1,
          tipoItem: 1,
          numeroDocumento: null,
          cantidad: _cantidad,
          codigo: codigo.toString(),
          codigoBarras: codigoBarras + '',
          codTributo: null,
          uniMedida: 1,
          descripcion: descripcion,
          precioUni: utilidades.formatDecimal(
            esExentoIva ? precioSinIva : productoPrecioConIva,
            precision,
          ),
          montoDescu: Number(descu),
          ventaNoSuj: 0,
          ventaExenta: ventaExenta,
          ventaGravada: !esExentoIva
            ? utilidades.formatDecimal(ventaGravada, precision)
            : 0,
          tributos: null,
          psv: 0,
          noGravado: 0,
          ivaItem: esExentoIva ? 0 : ivaTotal,
        }
      },
    )
    return products
  } catch (e) {
    return []
    consoleService.error(e)
  }
}

export const FormatProductsElectronicInvoiceSVCCFPrueba = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  const products: IProdDetailSV[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      pvp: _subtotal0,
      impuestoValor: _iva,
    } = product

    const esExentoIva = Number(_iva) <= 0

    const iva2 = 13
    const _cantidad = 1
    const cantidad = _cantidad
    const _descuento = 0

    const _precioUnitario = _subtotal0
    const productoPrecioSinIva = _precioUnitario

    // Calcular producto con IVA
    const productoPrecioConIva = utilidades.formatDecimal(
      productoPrecioSinIva * (1 + iva2 / 100),
      precision,
    )
    const ventaConIva = utilidades.formatDecimal(
      cantidad * productoPrecioConIva,
      precision,
    )

    // Calcular el subtotal (sin IVA)
    const subtotal = utilidades.formatDecimal(
      cantidad * productoPrecioSinIva,
      precision,
    )

    // Calcular el valor total del IVA
    const ivaTotal = utilidades.formatDecimal(
      (productoPrecioSinIva * iva2) / 100,
      precision,
    )

    // Calcular el total (con IVA)
    const total = _subtotal0 * cantidad - _descuento

    const resumen = {
      numItem: index + 1,
      tipoItem: 1,
      numeroDocumento: null,
      cantidad: _cantidad,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      codTributo: null,
      uniMedida: 1,
      descripcion: descripcion,
      precioUni: utilidades.formatDecimal(_subtotal0, precision),
      montoDescu: utilidades.formatDecimal(_descuento, precision),
      ventaNoSuj: 0,
      ventaExenta: esExentoIva
        ? utilidades.formatDecimal(_subtotal0, precision)
        : 0,
      ventaGravada: !esExentoIva
        ? utilidades.formatDecimal(Number(total), precision)
        : 0,
      tributos: !esExentoIva ? ['20'] : null,
      psv: 0,
      noGravado: 0,
      ivaItem: !esExentoIva ? ivaTotal : 0,
    }

    return resumen
  })

  return products
}

//  "numItem": 1,
// "codigo": null,
// "descripcion": "REGULAR SERVICIO",
// "cantidad": 2.000,
// "uniMedida": 99,
// "precioUni": 3.4779,
// "montoDescu": 0.00,
// "ventaGravada": 6.9precision,
// "noGravado": 0,
// "tributos": [
//     "C3"
// ]
export const FormatProductsElectronicInvoiceExportSV = (
  IProdDetail: IProdDetail[],
  precision = 2,
): IProdDetailSV[] => {
  const products: any[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      codigoBarras,
      descripcion,
      tieneIva,
      _precioIva,
      _cantidad,
      _precioUnitario,
      _descuento,
    } = product

    const iva2 = 0
    const cantidad = _cantidad
    const productoPrecioSinIva = utilidades.formatDecimal(
      _precioUnitario,
      precision,
    )

    // Calcular producto con IVA
    const productoPrecioConIva = utilidades.formatDecimal(
      productoPrecioSinIva * (1 + iva2 / 100),
      precision,
    )
    const ventaConIva = utilidades.formatDecimal(
      cantidad * productoPrecioConIva,
      2,
    )

    // Calcular el subtotal (sin IVA)
    const subtotal = utilidades.formatDecimal(
      cantidad * productoPrecioSinIva,
      precision,
    )

    // Calcular el valor total del IVA
    const ivaTotal = utilidades.formatDecimal(ventaConIva - subtotal, precision)
    const precioUni = utilidades.formatDecimal(productoPrecioSinIva, precision)
    const descuento = utilidades.formatDecimal(_descuento, precision)

    let ventaGravada = 0
    const noGravado = 0
    // if (tieneIva) {
    //   console.log('if')

    //   // con iva
    //   ventaGravada = utilidades.formatDecimal(
    //     precioUni * cantidad - descuento,
    //     precision,
    //   )
    //   noGravado = 0
    // } else {
    //   console.log('else')

    //   // sin iva
    //   noGravado = utilidades.formatDecimal(
    //     precioUni * cantidad - descuento,
    //     precision,
    //   )
    //   ventaGravada = 0
    // }

    ventaGravada = utilidades.formatDecimal(
      precioUni * cantidad - descuento,
      precision,
    )

    return {
      numItem: index + 1,
      codigo: codigo.toString(),
      codigoBarras: codigoBarras + '',
      descripcion: descripcion,
      cantidad: _cantidad,
      uniMedida: 99,
      precioUni: utilidades.formatDecimal(productoPrecioSinIva, precision),
      montoDescu: descuento,
      ventaGravada: utilidades.formatDecimal(ventaGravada, precision),
      tributos: ['C3'],
      noGravado: utilidades.formatDecimal(noGravado, precision),
    }
  })
  console.log('products', products)

  return products
}
export const FormatProductsElectronicInvoiceExportSVPruebas = (
  IProdDetail: ItemPedido[],
  precision = 2,
): IProdDetailSV[] => {
  const products: any[] = IProdDetail.map((product, index) => {
    const {
      codigo,
      descripcion,
      pvp,
      pvd: _precioUnitario,
      codigoBarras,
    } = product

    const iva2 = 0
    const _descuento = 0
    const _cantidad = 1
    const cantidad = _cantidad

    const productoPrecioSinIva = utilidades.formatDecimal(
      _precioUnitario > 0 ? _precioUnitario : pvp,
      precision,
    )

    // Calcular producto con IVA
    const productoPrecioConIva = utilidades.formatDecimal(
      productoPrecioSinIva * (1 + iva2 / 100),
      precision,
    )
    const ventaConIva = utilidades.formatDecimal(
      cantidad * productoPrecioConIva,
      2,
    )

    // Calcular el subtotal (sin IVA)
    const subtotal = utilidades.formatDecimal(
      cantidad * productoPrecioSinIva,
      precision,
    )

    // Calcular el valor total del IVA
    const ivaTotal = utilidades.formatDecimal(ventaConIva - subtotal, precision)
    const precioUni = utilidades.formatDecimal(productoPrecioSinIva, precision)
    const descuento = utilidades.formatDecimal(_descuento, precision)

    const ventaGravada = utilidades.formatDecimal(
      precioUni * cantidad - descuento,
      precision,
    )

    // Calcular el total (con IVA)
    const total = productoPrecioSinIva * cantidad

    return {
      numItem: index + 1,
      codigo: codigo + '',
      codigoBarras: codigoBarras + '',
      descripcion: descripcion,
      cantidad: _cantidad,
      uniMedida: 99,
      precioUni: utilidades.formatDecimal(productoPrecioSinIva, precision),
      montoDescu: descuento,
      ventaGravada: utilidades.formatDecimal(ventaGravada, precision),
      tributos: ['C3'],
      noGravado: 0,
    }
  })
  return products
}

const redondear = (valor: number, _presicionDecimal: number = 2): number => {
  return parseFloat(valor.toFixed(_presicionDecimal))
}
