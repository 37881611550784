export enum EAmbiente {
  Pruebas = '00',
  Produccion = '01',
}

export enum ETipoIdCodTributario {
  nit = '36',
  dui = '13',
  otro = '37',
  pasaporte = '03',
  carnetResidente = '02',
}
export enum ETipoIdCodTributarioAcatha {
  nit = '12',
}
