/* eslint-disable prefer-const */
import { RequestHelper } from '../../../helpers/requestHelper'
import { SesionService } from '../../../services/sesion.service'
import { DateRange, PagedResult, PaginationInfo } from '../../../store/types'
import {
  CompraDetalleInfo,
  CompraFullInfo,
  CompraInfo,
  CompraIngresar,
  CompraModificar,
  FormaPagoModuloCompras,
  ItemCompras,
  SustentosTributarios,
} from '../types/types'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { ProveedorBusqueda } from '../../proveedores/store/types'
import { DateUtils, formatoFechasApi } from '../../../helpers/dateUtils'
import { parseApiItemsLocal } from '../../ventas/pages/ordenes/pages/ordenPedido/services/ordenPedido.services'
import { ItemPedido } from '../../ventas/pages/ordenes/pages/ordenPedido/type/types'

export const CompraService = {
  buscarCompras,
  buscarComprobantesModificar,
  getCompra,
  getDetallesCompra,
  getFormasPago,
  buscarItems,
  getItemCodigoBarras,
  ingresar,
  getItemsDatasource,
  getItemsGridDatasource,
  getSustentosTributarios,
  deleteCompra,
  plantillaImportar,
  exportarDemo,
}

async function deleteCompra(info: CompraInfo) {
  const postData = {
    codigo: info.codigo,
    tipoDocumento: '01',
  }
  const result = await RequestHelper.deleteRequest<CompraInfo>(
    'compras',
    'compras/cancel',
    '',
    postData,
  )
  return result
}

async function getDetallesCompra(
  codigo: number,
): Promise<Array<CompraDetalleInfo>> {
  const queryData = {
    compraCodigo: codigo,
  }
  const detalles = await RequestHelper.get<Array<CompraDetalleInfo>>(
    'compras',
    'compras/getDetail',
    '',
    queryData,
  )
  return detalles
}

async function getItemCodigoBarras(
  codigoBarras: string,
  proveedor: ProveedorBusqueda | null,
): Promise<ItemCompras | undefined> {
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  const queryData: any = {
    descripcion: '',
    barras: codigoBarras,
    exacta: 1,
    identificacion: '',
    establecimiento: '',
  }
  if (proveedor) {
    queryData.identificacion = proveedor.identificacion
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    queryData.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const items = await RequestHelper.get<Array<ItemCompras>>(
    'inventario',
    'items/searchItemsLocalSimplePurchase',
    '',
    queryData,
  )
  if (items.length === 0) {
    // eslint-disable-next-line no-throw-literal
    return undefined
    'No se encuentra un item con código ' + codigoBarras
  }
  const itemsParsed = parseApiItemsData(items)
  return itemsParsed[0]
}

async function buscarItemCodigoBarras(
  codigoBarras: string,
  identificacionProveedor: string,
): Promise<Array<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion()
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion()

  const query = {
    local: sesion.local.codigo,
    barras: codigoBarras,
    exacta: false,
    identificacion: identificacionProveedor ?? '',
    establecimiento: '',
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento
  }
  const itemsApi = await RequestHelper.get<Array<any>>(
    'inventario',
    'items/searchItemsLocalSimplePurchase',
    '',
    query,
  )
  return parseApiItemsData(itemsApi)
}

function getItemsDatasource(proveedor) {
  const store = new CustomStore({
    key: 'barras',
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(
        key,
        proveedor?.identificacion ?? '',
      )
      return item.length > 0 ? item[0] : undefined
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ItemCompras>
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? '',
        '',
        paginInfo,
      )
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'barras',
  })
  return source
}

function getItemsGridDatasource(proveedor, dataSeacrh) {
  const store = new CustomStore({
    key: 'codigoBarras',
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(
        key,
        proveedor?.identificacion ?? '',
      )
      return item.length > 0 ? item[0] : undefined
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<ItemCompras | ItemPedido>
      pagedResult = await buscarItemsPagenate(
        dataSeacrh['descripcion'] ?? '',
        dataSeacrh['codigoBarras'] ?? '',
        paginInfo,
      )
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'barras',
  })
  return source
}

async function buscarItemsPagenate(
  descripcion: string,
  codigoBarras: string,
  pagination: PaginationInfo,
): Promise<PagedResult<ItemCompras | ItemPedido>> {
  const sesion = SesionService.getCurrentSesion()
  const query = {
    local: sesion.local.codigo,
    descripcion: descripcion,
    barras: codigoBarras,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  const pagedApiResult = await RequestHelper.get<any>(
    'inventario',
    'items/searchItemsLocal',
    '',
    query,
  )
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: parseApiItemsLocal(pagedApiResult.auto),
  }
}

async function buscarItemsPaged(
  descripcion: string,
  codigoBarras: string,
  pagination: PaginationInfo,
): Promise<PagedResult<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion()
  const query = {
    local: sesion.local.codigo,
    descripcion: descripcion,
    barras: codigoBarras,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
    identificacion: '',
    establecimiento: '',
  }
  const pagedApiResult = await RequestHelper.get<any>(
    'inventario',
    'items/searchItemsLocalSimplePurchase',
    '',
    query,
  )
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: pagedApiResult.auto as Array<ItemCompras>,
  }
}

async function buscarItems(
  codigoBarras: string,
  nombre: string = '',
): Promise<Array<ItemCompras>> {
  const queryData = {
    descripcion: nombre,
    barras: codigoBarras,
    identificacion: '',
    establecimiento: '',
  }
  const items = await RequestHelper.get<Array<ItemCompras>>(
    'inventario',
    'items/searchItemsLocalSimplePurchase',
    '',
    queryData,
  )
  return parseApiItemsData(items)
}

async function buscarComprobantesModificar(
  identificacion: string,
): Promise<Array<CompraModificar>> {
  const queryData = {
    id: identificacion,
  }
  const apiData = await RequestHelper.get<Array<any>>(
    'compras',
    'invoicemodify/all',
    '',
    queryData,
  )
  const compras: Array<CompraModificar> = []
  for (const data of apiData) {
    const numero = data.NUMERO.split('-')
    compras.push({
      comprobante: parseInt(data.CODIGO), //comprobante
      descripcion: data.TIC_COMPROBANTE, //descripcion
      establecimiento: numero[0], //establecimiento
      puntoEmision: numero[1], //ptoemision
      numero: numero[2], //numero
      comprobanteNumero: data.NUMERO,
      puntoVenta: data.PTO_HOST,
      fecha: data.FECHA, //fecha
      proveedorCodigo: parseInt(data.PRV_CODIGO), //proveedor
      proveedorIdentificacion: data.PRV_IDENTIFICACION, //identificacion
      proveedorNombre: data.PRV_NOMBRE, //nombre
      proveedorDireccion: data.PRV_DIRECCION, //direccion
      proveedorTelefono: data.PRV_TELEFONO, //telefono
      proveedorEmail: data.PRV_EMAIL, //email
      codigoFormaPago: parseInt(data.FPAGO), //fpago
      nombreFormaPago: data.FPAGO_DES, //fpagodes
    })
  }
  return compras
}

async function buscarCompras(
  range: DateRange | null,
  numero: string,
  identificacion: string,
  tipoDoc: string,
  fpago: string,
  claveAcceso: string,
): Promise<Array<CompraInfo>> {
  const queryData: any = {}

  if (range) {
    queryData.desde = range.inicio
    queryData.hasta = range.fin
  }

  if (numero && numero.length > 0) {
    queryData.compraNumero = numero
  }

  if (identificacion && identificacion.length > 0) {
    queryData.proveedorIdentificacion = identificacion
  }

  if (tipoDoc && tipoDoc.length > 0) {
    queryData.compraTipoDocumento = tipoDoc
  }

  if (fpago && fpago.length > 0) {
    queryData.compraFormaPago = fpago
  }

  if (claveAcceso) {
    queryData.claveAcceso = claveAcceso
  }

  const compras = await RequestHelper.get<Array<CompraInfo>>(
    'compras',
    'compras/getAll',
    '',
    queryData,
  )
  return compras
}

async function getCompra(codigo: number): Promise<CompraFullInfo> {
  const queryData: any = {
    codigo,
  }
  const compra = await RequestHelper.get<CompraFullInfo>(
    'compras',
    'compras/get',
    '',
    queryData,
  )
  return compra
}

async function getFormasPago() {
  const data: any = {
    movimiento: 'C',
  }
  data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
  const cacheKey = 'tiposFormasPagoC' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<FormaPagoModuloCompras>>(
      'generales',
      'paymentForms/getCboxFP',
      '',
      data,
    )
    sessionStorage.setItem(cacheKey, JSON.stringify(apiData))

    return apiData
  }
}

async function ingresar(compra: CompraIngresar): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  if (compra?.paisCodigo) {
    compra.paisCodigo = sesion.empresa?.codigoPais ?? 1
  }
  const result = await RequestHelper.post('compras', 'compras/ingresar', compra)
  return result
}

function parseApiItemsData(apiresult: Array<any>): Array<ItemCompras> {
  const items = apiresult.map((x) => {
    const item: ItemCompras = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: x.ice === '-1',
      porcentajeIva: parseFloat(x.porcentajeIva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo),
      lotes: parseInt(x.lotes),
      impuesto: parseInt(x.impuesto),
      unidadDescripcion: x.unidadDescripcion,
    }
    return item
  })
  return items
}

async function getSustentosTributarios() {
  const data: any = {
    tipoComprobanteCodigo: '01',
  }
  data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
  const cacheKey = 'sustentosTributarios' + JSON.stringify(data)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<SustentosTributarios>>(
      'generales',
      'sustentosVouchers/getLivelihoods',
      '',
      data,
    )
    sessionStorage.setItem(cacheKey, JSON.stringify(apiData))
    return apiData
  }
}

async function plantillaImportar(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const fileUpload: any = {
    local: sesion.local.codigo,
    archivo: objeto.file,
    tipo: 'archivo',
  }

  const data = await RequestHelper.postData<any>(
    'compras',
    'getExcelAll',
    fileUpload,
  )
  return data
}

function exportarDemo(fileName) {
  const config = RequestHelper.getConfig('GET')
  const datosSearch = {
    archivo: fileName,
  }
  const response = fetch(
    RequestHelper.getRequestUrl('generales', 'downloadFile', null, datosSearch),
    {
      method: 'get',
      headers: config.headers,
    },
  )
  return response
}
