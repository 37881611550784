import { DateUtils } from '../../../../../helpers/dateUtils'
import {
  TiposComprobantesSLV,
  TiposComprobantesSri,
} from '../../../../../store/types'
import { impresionService } from '../../../../componentes/impresionesLookUp/service/impresiones.service'
import { TS_Auth } from '../../../types/facturacion/elSalvador/types'
import {
  AutorizacionDocumentoVenta,
  TAutorizacionDocumentoSV,
} from '../../../types/types'
import { TAutorizacionHaciendaSV, TCustomTemplate } from '../types/types'

export const VentaHelperSV = {
  getTipoComprobantesSV,
  getTemplatePDFSV,
  getInitialNameEmpSV,
  generarCodigoControl,
  getTipoDocumento,
  getTipoDocumentoSV,
  convertirFecha,
  verificateCustomTemplate,
  agregarSelloRecibido,
  parseAutorizathionSV,
  parseCheckStateDte,
}

/**
 * Función que devuelve el tipo de comprobante en El Salvador.
 * @param tipoDocumento : TiposComprobantesSLV | TiposComprobantesSri- El tipo de comprobante.
 * @returns El tipo de comprobante en formato de cadena de texto.
 */
function getTipoComprobantesSV(
  tipoDocumento: TiposComprobantesSLV | TiposComprobantesSri,
): string {
  if (tipoDocumento === TiposComprobantesSLV.Factura) {
    return 'CONSUMIDOR FINAL'
  } else if (tipoDocumento === TiposComprobantesSLV.CreditoFiscal) {
    return 'CREDITO FISCAL'
  } else if (tipoDocumento === TiposComprobantesSLV.FacturaExportacion) {
    return 'FACTURA EXPORTACION'
  } else if (tipoDocumento === TiposComprobantesSLV.FacturaExcluidoSujeto) {
    return 'SUJETO EXCLUIDO'
  }
}

/**
 * Convierte una fecha en formato "dd/mm/yyyy" a formato "yyyy-mm-dd".
 * @param fecha La fecha en formato "dd/mm/yyyy".
 * @returns La fecha convertida en formato "yyyy-mm-dd".
 */
function convertirFecha(fecha: string): string {
  const partes = fecha.split('/')
  const fechaConvertida = `${partes[2]}-${partes[1]}-${partes[0]}`
  return fechaConvertida
}

/**
 * Función que devuelve la plantilla pdf del comprobante en El Salvador.
 * @param tipoDocumento : TiposComprobantesSLV | TiposComprobantesSri- El tipo de comprobante.
 * @returns La plantilla pdf formato en cadena de texto.
 */
function getTemplatePDFSV(
  tipoDocumento: TiposComprobantesSLV | TiposComprobantesSri,
): string {
  let templatePDF = 'SVRideFactura'
  if (tipoDocumento === TiposComprobantesSLV.CreditoFiscal) {
    templatePDF = 'SVRideFacturaCCF'
  } else if (tipoDocumento === TiposComprobantesSLV.Factura) {
    templatePDF = 'SVRideFactura'
  } else if (tipoDocumento === TiposComprobantesSLV.FacturaExportacion) {
    templatePDF = 'SVRideFacturaEXP'
  } else if (tipoDocumento === TiposComprobantesSLV.FacturaExcluidoSujeto) {
    templatePDF = 'SVRideFacturaSExcluido'
  }
  return templatePDF
}

/**
 * Esta función devuelve las iniciales de un nombre de entidad.
 * @param entityName - El nombre de la entidad.
 * @returns Las iniciales del nombre de la entidad.
 */
function getInitialNameEmpSV(entityName: string): string {
  return entityName
    .split(' ')
    .map((d) => d[0])
    .join('')
}

/**
 * Función para generar un número de control a partir de los parámetros dados.
 * @param tipoDocumento El tipo de documento.
 * @param codigoSucursal El código de la sucursal.
 * @param puntoVenta El punto de venta.
 * @param secuencia La secuencia.
 * @returns Un objeto con un indicador de error y el número de control generado.
 */
function generarCodigoControl(
  tipoDocumento: string,
  codigoSucursal: number,
  puntoVenta: string,
  secuencia: number,
  nomEmpresa: string,
): { error: boolean; content: string } {
  // Validar que los parámetros sean válidos
  if (
    !tipoDocumento ||
    !codigoSucursal ||
    !puntoVenta ||
    secuencia === undefined ||
    !nomEmpresa
  ) {
    return { error: true, content: 'No se puede generar el numero de control.' }
  }

  //Generar en nickname las iniciales de la empresa
  nomEmpresa = nomEmpresa.replaceAll(/[^a-zA-Z0-9\s]/g, '')
  const partsName = nomEmpresa.split(/\s+/)
  const charactersName =
    nomEmpresa.length > 4 ? (partsName.length >= 4 ? 1 : 2) : 4
  let nickName = ''
  for (let i = 0; i < partsName.length; i++) {
    const partName = partsName[i]

    nickName += partName.slice(0, i == 0 ? charactersName : 1)
  }
  nickName = nickName.toUpperCase()
  if (nickName.length > 4) {
    nickName = nickName.slice(0, 4)
  }

  // Formatear la sección de tipo de documento
  const seccionTipoDocumento = `${tipoDocumento}`.padStart(2, '0')
  // Formatear la sección de código de sucursal y punto de venta
  const seccionSucursalPuntoVenta =
    // en lugar de codigo secuencial mandar 4 caracteres del nombre de la empresa si no tiene 4 caracteres completar con 0
    `${nickName}`.padStart(4, '0') + `${puntoVenta}`.padStart(4, '0')
  // Formatear la sección de secuencia
  //al principio de la secuencia agregar el codigoSucursal
  let parserCodigoSucursal = codigoSucursal.toString()
  if (parserCodigoSucursal.length > 4) {
    parserCodigoSucursal = parserCodigoSucursal.slice(0, 4)
  }
  parserCodigoSucursal = parserCodigoSucursal.padStart(4, '0')
  const seccionSecuencia = `${parserCodigoSucursal}${secuencia
    .toString()
    .padStart(11, '0')}`
  // Unir las secciones con guiones
  const numeroControl = `${seccionTipoDocumento}-${seccionSucursalPuntoVenta}-${seccionSecuencia}`
  return { error: false, content: numeroControl }
}

/**
 * Esta función convierte un tipo de documento a TiposComprobantesSLV.
 * @param type El tipo de documento a convertir.
 * @returns El tipo de documento convertido.
 */
function getTipoDocumento(type: string): TiposComprobantesSLV {
  const tipo = type as TiposComprobantesSLV
  return tipo
}

function getTipoDocumentoSV(codigo: string): string | undefined {
  for (const tipo in TiposComprobantesSLV) {
    if (TiposComprobantesSLV[tipo] === codigo) {
      return tipo
    }
  }
  return undefined // Retornar undefined si no se encuentra el tipo para el código dado
}

async function verificateCustomTemplate(
  authorization: AutorizacionDocumentoVenta | null,
): Promise<TCustomTemplate> {
  const formatos = await impresionService.getImpresiones(
    '',
    authorization.codigoTIC,
  )

  let configTemplate: TCustomTemplate = {
    template: '',
    isCustom: false,
  }

  if (formatos?.auto && formatos?.auto.length) {
    const findCustom = formatos?.auto.find(
      (x) => x.codigo == authorization?.codigoImpresora,
    )

    if (findCustom) {
      configTemplate = {
        template: findCustom?.archivoImpresion,
        isCustom: findCustom?.archivoImpresion.toUpperCase().includes('TICKET')
          ? true
          : false,
      }
    }
  }

  return configTemplate
}

function agregarSelloRecibido(jsonString: any, autorizacionDte: TS_Auth) {
  const data = jsonString
  for (const key in data) {
    if (data[key].dteJson) {
      data[key].dteJson.selloRecibido = autorizacionDte.selloRecibido
      data[key].dteJson.respuesta = autorizacionDte
      break
    }
  }
  console.log('agregarSelloRecibido end', data)
  return data
}

export function parseAutorizathionSV(
  dteAuth: TS_Auth,
  claveAcceso: string,
  tipoComprobante: TiposComprobantesSLV | null,
): TAutorizacionHaciendaSV {
  return {
    estado: dteAuth?.estado ?? '',
    version: dteAuth?.version,
    ambiente: dteAuth?.ambiente ?? '',
    codigoMensaje: dteAuth?.codigoMsg ?? '',
    versionApp: dteAuth?.versionApp,
    claveAcceso: claveAcceso,
    clasificacionMensaje: dteAuth?.clasificaMsg ?? '',
    observaciones: dteAuth?.observaciones,
    selloRecibido: dteAuth?.selloRecibido ?? '',
    descripcionMensaje: dteAuth?.descripcionMsg ?? '',
    fechaProcesamiento: dteAuth?.fhProcesamiento ?? '',
    tipoComprobante: tipoComprobante,
    codigoGeneracion: dteAuth?.codigoGeneracion ?? '',
  }
}

function parseCheckStateDte(dteAuth): TS_Auth {
  const fechaEmi = dteAuth?.fechaEmi
  const formateo = DateUtils.format(fechaEmi, 'dd/MM/yyyy')

  const horaEmi = dteAuth?.horaEmi
  const fhProcesamiento = formateo + ' ' + horaEmi

  const json = {
    claveacceso: '',
    ambiente: dteAuth?.documento?.identificacion?.ambiente ?? '',
    clasificaMsg: '',
    codigoGeneracion: dteAuth?.codGen ?? '',
    codigoMsg: '',
    descripcionMsg: '',
    estado: 'PROCESADO',
    fhProcesamiento: String(fhProcesamiento) ?? '',
    selloRecibido: dteAuth?.selloVal ?? '',
    version: dteAuth?.documento?.identificacion?.version ?? 0,
    versionApp: dteAuth?.documento?.identificacion?.version ?? 0,
    observaciones: [],
    tipoComprobante: dteAuth?.tipoDte ?? '',
  }
  return json
}

export function retypeAutorizathionSV(
  dteAuth: TAutorizacionDocumentoSV,
): TAutorizacionHaciendaSV {
  return {
    estado: dteAuth?.estado,
    version: Number(dteAuth?.version),
    ambiente: dteAuth?.ambiente,
    codigoMensaje: dteAuth?.codigoMensaje,
    versionApp: Number(dteAuth?.versionApp),
    claveAcceso: dteAuth?.claveAcceso,
    clasificacionMensaje: dteAuth?.clasificacionMensaje,
    observaciones: dteAuth?.observaciones ?? [],
    selloRecibido: dteAuth?.selloRecibido,
    descripcionMensaje: dteAuth?.descripcionMensaje,
    fechaProcesamiento: dteAuth?.fechaProcesamiento,
    tipoComprobante: dteAuth?.tipoComprobante,
    codigoGeneracion: dteAuth?.codigoGeneracion,
  }
}
