import React from 'react';
import { Portal } from 'react-portal';
import Modalform from '../../../../../../views/componentes/modalform';
import { CButton, CCol, CRow, CTooltip, } from '@coreui/react-pro';
import { Cliente } from '../../../../types/types';
import { TiposComprobantesSLV, TiposComprobantesSri, ToastTypes } from '../../../../../../store/types';
import { addToast } from '../../../../../../store/toasterReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ClientesService } from '../../../../services/clientes.service';
import { VentasService } from '../../../../services/ventas.service';
import * as configGlobal from '../../../../../../store/reducers';
import { ProveedoresService } from '../../../../../proveedores/services/proveedores.service';
import { Proveedor, ProveedorBusqueda } from '../../../../../proveedores/store/types';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput';
import {
  Validator,
  RequiredRule,
  PatternRule
} from 'devextreme-react/validator';
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import TextArea from 'devextreme-react/text-area';
import { GlobalLabel } from '../../../../../../views/componentes/globalLabel/globalLabel';
import { consoleService } from '../../../../../../services/console.service';
import { RootState } from '../../../../../../store/store';
import { ECountry } from '../../../../../../store/enum/countries';


export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\\;]?)+){1,}$/
// export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\;]?)+){1,}$/

interface IPupopReenvioMailProps extends React.PropsWithChildren {
  showConfirm: boolean,
  itemReenviarMail: { cliente?: Cliente, claveAcceso: string, proveedor?: ProveedorBusqueda, documento?: any } | null,
  closeConfirm: () => void
}

enum TipoRemitente {
  cliente = "Cliente",
  proveedor = "Proveedor",
}

const clienteInit = {
  ciudad: "",
  ciudadCodigo: 0,
  codigo: 0,
  descuento: 0,
  direccion: "",
  email: "",
  identificacion: "",
  nombreComercial: "",
  nombres: "",
  telefono: "" ?? "N/A",
  tipoCliente: 0,
  tipoIdentificacion: "",
}

export function PupopReenvioMail(props: IPupopReenvioMailProps) {

  const { showConfirm, itemReenviarMail, closeConfirm } = props;

  const dispatch = useDispatch();
  const validationGroupRef = React.useRef<any>();
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa })

  const [messageConfirm, setMessageConfirm] = React.useState<string>("");
  const [codigo, setCodigo] = React.useState<number>(0);
  const [mailReenvio, setMailReenvio] = React.useState<string>("");
  const [mailBackupReenvio, setMailBackupReenvio] = React.useState<string>("");
  const [sendSameMail, setSendSameMail] = React.useState<boolean>(true);
  const [clienteModificar, setClienteModificar] = React.useState<Cliente>(clienteInit);
  const [proveedorModificar, setProveedorModificar] = React.useState<Proveedor | null>(null);
  const [tipoDocumento, setTipoDocumento] = React.useState<TiposComprobantesSri | TiposComprobantesSLV>(TiposComprobantesSri.Factura);


  const playLoader = React.useCallback(() => {
    dispatch(configGlobal.changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch]);


  const stopLoader = React.useCallback(() => {
    dispatch(configGlobal.changeLoader({ show: false, mensaje: '' }))
  }, [dispatch]);

  const saveMailRemitente = (itemReenviar) => {
    consoleService.log("saveMailRemitente")
    consoleService.log(itemReenviar.cliente?.email)
    if (itemReenviar.claveAcceso && itemReenviar.claveAcceso.length > 0) {
      switch (itemReenviar.claveAcceso.substr(8, 2)) {
        case '01':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '04':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '05':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '06':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '03':
          setMailReenvio(itemReenviar.proveedor?.email ?? "")
          return true
        case '07':
          setMailReenvio(itemReenviar.proveedor?.email ?? "")
          return true
        default:
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
      }
    } else {
      dispatch(addToast({
        title: 'Error',
        content: 'No se puede obtener la clave de acceso.',
        type: ToastTypes.Warning,
        autoHide: 5000
      }))
      closeConfirm();
    }
  }

  const cargarCliente = React.useCallback(async (dataCliente: any) => {
    consoleService.log("cargarCliente")
    consoleService.log("dataCliente", dataCliente)
    playLoader();
    const cliente = await ClientesService.consultarClientes({ nombre: dataCliente?.nombre, identificacion: dataCliente?.identificacion ?? '', codigo: dataCliente.codigo });
    consoleService.log("cliente", cliente)
    setClienteModificar({
      ciudad: cliente[0]['ciudad'],
      ciudadCodigo: cliente[0]['ciudadCodigo'],
      codigo: cliente[0]['codigo'],
      descuento: cliente[0]['descuento'],
      direccion: cliente[0]['direccion'],
      email: cliente[0]['email'],
      identificacion: cliente[0]['identificacion'],
      nombreComercial: cliente[0]['nombreComercial'],
      nombres: cliente[0]['nombres'],
      telefono: cliente[0]['telefono'] ?? "N/A",
      tipoCliente: cliente[0]['tipoCliente'],
      tipoIdentificacion: cliente[0]['tipoIdentificacion'],
    })
    setMailBackupReenvio(cliente[0]['email'] ?? "")
    setCodigo(cliente[0]['codigo']);
    stopLoader();
  }, [playLoader, stopLoader]);

  const cargarProveedor = React.useCallback(async (codigo: number) => {
    playLoader();
    const proveedor = await ProveedoresService.getProveedorByCode(codigo);
    consoleService.log("proveedor", proveedor)
    setProveedorModificar(proveedor)
    setMailBackupReenvio(proveedor['email'] || "")
    setCodigo(proveedor['codigo'] || 0);
    stopLoader();
  }, [playLoader, stopLoader]);

  const reenviarEmail = React.useCallback(async () => {
    let identificadorDocumento: string = props.itemReenviarMail?.claveAcceso;
    if (empresa?.codigoPais === ECountry.ElSalvador) {
      identificadorDocumento = props.itemReenviarMail?.claveAcceso;
    }
    try {
      playLoader()
      const dataReenvio = await VentasService.reenviarDocumento(identificadorDocumento ?? "");

      stopLoader()
      closeConfirm();
      dispatch(addToast({
        title: 'Reenviar Factura',
        content: 'El comprobante fue reenviado al correo',
        type: ToastTypes.Success,
        autoHide: 5000
      }))

      return dataReenvio;

    } catch (error) {
      consoleService.error(error)
      stopLoader()
      dispatch(addToast({
        title: 'Reenviar Factura',
        content: 'No se pudo reenviar la factura.',
        type: ToastTypes.Warning,
        autoHide: 5000
      }))

    }

  }, [closeConfirm, dispatch, playLoader, props, stopLoader, empresa]);

  const updateMailRemitente = React.useCallback(async (mail: string, tipoDocumento: string, codigo: number) => {

    let tipoRemitente: TipoRemitente = TipoRemitente.cliente;
    if (empresa?.codigoPais === ECountry.ElSalvador) {
      if (
        tipoDocumento === "01" ||
        tipoDocumento === "03" ||
        tipoDocumento === "11" ||
        tipoDocumento === "14"
      ) {
        tipoRemitente = TipoRemitente.cliente;
      } else if (tipoDocumento === "03" || tipoDocumento === "07") {
        tipoRemitente = TipoRemitente.proveedor;
      }
    } else {
      if (tipoDocumento === "01" || tipoDocumento === "04" || tipoDocumento === "05" || tipoDocumento === "06") {
        tipoRemitente = TipoRemitente.cliente;
      } else if (tipoDocumento === "03" || tipoDocumento === "07") {
        tipoRemitente = TipoRemitente.proveedor;
      }
    }
    const dataUpdate = await ClientesService.modificarRemitenteMail({ tipoRemitente: tipoRemitente, email: mail, codigo: codigo });
    return dataUpdate;
  }, [empresa]);

  const detectaDocumento = React.useCallback(async (data: any) => {
    consoleService.log("detectaDocumento", data)
    if (empresa?.codigoPais === ECountry.ElSalvador) {

      const { tipoDocumentoCodigo } = data?.documento;
      if (tipoDocumentoCodigo === undefined || tipoDocumentoCodigo === null) {
        dispatch(addToast({
          title: 'Documento no definido',
          content: 'Error, El tipo de documento no se encuentra \n',
          type: ToastTypes.Warning,
          autoHide: 5000
        }));
        props.closeConfirm()
        return false;
      }
      switch (tipoDocumentoCodigo) {
        case '01': // facturas
          setTipoDocumento(TiposComprobantesSLV.Factura);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '03':
          setTipoDocumento(TiposComprobantesSLV.CreditoFiscal);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '11':
          setTipoDocumento(TiposComprobantesSLV.FacturaExportacion);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '14':
          setTipoDocumento(TiposComprobantesSLV.FacturaExcluidoSujeto);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return true
        case '05':
          setTipoDocumento(TiposComprobantesSLV.NotaCredito);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '06':
          setTipoDocumento(TiposComprobantesSLV.NotaDebito);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '04':
          setTipoDocumento(TiposComprobantesSLV.NotaRemision);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return true
        case '07': // retencion
          setTipoDocumento(TiposComprobantesSLV.Retencion);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return true
        case '08': // liquidaciones
          setTipoDocumento(TiposComprobantesSLV.Liquidacion);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return true
        case '09':
          setTipoDocumento(TiposComprobantesSLV.LiquidacionContable);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return true
        default:
          setTipoDocumento(TiposComprobantesSLV.Factura);
          cargarCliente(itemReenviarMail?.cliente ?? "");

          return true
      }

    } else {
      const clave = data?.claveAcceso;

      switch (clave.substr(8, 2)) {
        case '01':
          setTipoDocumento(TiposComprobantesSri.Factura);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return false
        case '04':
          setTipoDocumento(TiposComprobantesSri.NotaCredito);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return false
        case '05':
          setTipoDocumento(TiposComprobantesSri.NotaDebito);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return false
        case '06':
          setTipoDocumento(TiposComprobantesSri.GuiaRemision);
          cargarCliente(itemReenviarMail?.cliente ?? "");
          return false
        case '03':
          setTipoDocumento(TiposComprobantesSri.LiquidacionCompra);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return false
        case '07':
          setTipoDocumento(TiposComprobantesSri.Retencion);
          cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
          return false
        default:
          return false
      }

    }


  }, [cargarCliente, cargarProveedor, dispatch, itemReenviarMail, empresa, props]);

  const aplicarEnvioConActualizacion = React.useCallback(async (tipoDocumento: string) => {
    consoleService.log("aplicarEnvioConActualizacion");
    const resReenvio = await reenviarEmail();
    consoleService.log("resReenvio", resReenvio);
    if (resReenvio === true) {
      if (mailReenvio !== mailBackupReenvio) {
        const resResUpdate: any = await updateMailRemitente(mailBackupReenvio, tipoDocumento, codigo);
        consoleService.log("resResUpdate", resResUpdate);
      }
    }
  }, [codigo, mailBackupReenvio, mailReenvio, reenviarEmail, updateMailRemitente]);


  const actualizaMailUsuario = React.useCallback(async (nuevoCorreo: string) => {

    consoleService.log("actualizaMailUsuario")
    consoleService.log("nuevoCorreo", nuevoCorreo)

    const resUpdateMail = await updateMailRemitente(nuevoCorreo, tipoDocumento, codigo)

    consoleService.log("resUpdateMail", resUpdateMail);

    try {
      setTimeout(function () {
        aplicarEnvioConActualizacion(tipoDocumento);
      }, 50)
    } catch (error) {
      dispatch(addToast({
        title: 'Cambiar Correo',
        content: 'Error al cambiar de correo, \n' + error,
        type: ToastTypes.Warning,
        autoHide: 5000
      }))
    }

  }, [aplicarEnvioConActualizacion, codigo, dispatch, tipoDocumento, updateMailRemitente]);




  const reenviarComprobante = React.useCallback(async () => {

    const validationResult = await validationGroupRef.current.instance.validate();
    if (validationResult.isValid === false) {
      dispatch(addToast({
        title: 'Cambiar Correo',
        content: 'Tiene errores por favor verifíquelos.',
        type: ToastTypes.Info,
        autoHide: 5000
      }))
      return false;
    } else {

      if (mailReenvio === "" || mailReenvio === null) {
        dispatch(addToast({
          title: 'Cambiar Correo',
          content: 'Por favor ingrese una cuenta de correo.',
          type: ToastTypes.Warning,
          autoHide: 5000
        }))
      } else {
        await actualizaMailUsuario(mailReenvio);
      }
    }
  }, [actualizaMailUsuario, dispatch, mailReenvio]);

  const retornaTipo = React.useCallback((clave: string) => {
    let tipo: string = '01';
    if (clave.substr(8, 2) === "01") {
      tipo = "01";
    } else if (clave.substr(8, 2) === "04") {
      tipo = "04";
    } else if (clave.substr(8, 2) === "05") {
      tipo = "05";
    } else if (clave.substr(8, 2) === "06") {
      tipo = "06";
    } else if (clave.substr(8, 2) === "03") {
      tipo = "03";
    } else if (clave.substr(8, 2) === "07") {
      tipo = "07";
    }
    return tipo;
  }, []);

  const botonReenvioOtros = React.useCallback((itemReenviarMail) => {
    consoleService.log('botonReenvioOtros')
    consoleService.log('itemReenviarMail', itemReenviarMail)

    if (empresa?.codigoPais === ECountry.ElSalvador) {
      const tipo = itemReenviarMail?.documento?.tipoDocumentoCodigo;
      consoleService.log('tipo', tipo)
      if (tipo === TiposComprobantesSLV.Factura || tipo === TiposComprobantesSLV.CreditoFiscal || tipo === TiposComprobantesSLV.FacturaExportacion || tipo === TiposComprobantesSLV.FacturaExcluidoSujeto || tipo === TiposComprobantesSLV.NotaCredito || tipo === TiposComprobantesSLV.NotaDebito || tipo === TiposComprobantesSLV.NotaRemision) {
        if (clienteModificar.codigo !== 0) {
          setSendSameMail(false);
          return false;
        } else {
          dispatch(addToast({
            title: 'Cambiar Correo',
            content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
            type: ToastTypes.Info,
            autoHide: 5000
          }))
        }
      } else if (tipo === TiposComprobantesSLV.Liquidacion || tipo === TiposComprobantesSLV.Retencion || tipo === TiposComprobantesSLV.LiquidacionContable) {
        consoleService.log("proveedorModificar", proveedorModificar)
        if (proveedorModificar?.codigo && Number(proveedorModificar?.codigo) !== 0) {
          setSendSameMail(false);
          return false;
        } else {
          dispatch(addToast({
            title: 'Cambiar Correo',
            content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
            type: ToastTypes.Info,
            autoHide: 5000
          }))
        }
      }
    } else {
      const tipo = retornaTipo(itemReenviarMail.claveAcceso);
      if (tipo === TiposComprobantesSri.Factura || tipo === TiposComprobantesSri.NotaCredito || tipo === TiposComprobantesSri.NotaDebito || tipo === TiposComprobantesSri.GuiaRemision) {
        if (clienteModificar.codigo !== 0) {
          setSendSameMail(false);
          return false;
        } else {
          dispatch(addToast({
            title: 'Cambiar Correo',
            content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
            type: ToastTypes.Info,
            autoHide: 5000
          }))
        }
      } else if (tipo === TiposComprobantesSri.LiquidacionCompra || tipo === TiposComprobantesSri.Retencion) {
        consoleService.log("proveedorModificar", proveedorModificar)
        if (proveedorModificar?.codigo && Number(proveedorModificar?.codigo) !== 0) {
          setSendSameMail(false);
          return false;
        } else {
          dispatch(addToast({
            title: 'Cambiar Correo',
            content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
            type: ToastTypes.Info,
            autoHide: 5000
          }))
        }
      }
    }
  }, [clienteModificar.codigo, dispatch, proveedorModificar, retornaTipo, empresa]);

  React.useEffect(() => {
    if (showConfirm) {
      detectaDocumento(itemReenviarMail ?? "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirm, itemReenviarMail]);

  React.useEffect(() => {
    if (itemReenviarMail) {
      setSendSameMail(true)
      saveMailRemitente(itemReenviarMail)
      setMessageConfirm("Desea enviar el comprobante al mismo correo?");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemReenviarMail, showConfirm])


  const renderBody = () => {
    return (
      <>
        <GlobalLabel>{messageConfirm}</GlobalLabel>
        <CRow>
          <CCol lg={6}>
            <div className="d-grid ">
              <CButton color="primary" onClick={() => {
                setSendSameMail(true);
                reenviarEmail();
              }}>
                {"Si"}
              </CButton>
            </div>
          </CCol>
          {" "}
          <CCol lg={6}>
            <div className="d-grid ">
              <CButton color="secondary" onClick={() => {
                botonReenvioOtros(itemReenviarMail)
              }}>
                {"No"}
              </CButton>
            </div>
          </CCol>

        </CRow>
        <br />
        {sendSameMail === false && (
          <>
            <ValidationGroup id={`cambiarCorreoRemitente`} ref={validationGroupRef}>
              <label>
                {"Para reenviar a varios correos puede mandarlos seguido del caracter "}
              </label>
              <CTooltip
                key={"tool-mail-add"}
                placement="top"
                content={"(;) Ejemplo : acatha@acatha.com.ec; acatha@austrosoft.com.ec; austrosoft@austrosoft.com.ec "}
              >
                <label style={{ fontSize: "18px", fontWeight: 700 }}>{";"} </label>
              </CTooltip>
              <RowContainer                >
                <CustomCol
                  xs='12' md='12'>
                  <Labeled
                    label='Email'
                  >
                    <TextArea
                      width='100%'
                      height={90}
                      value={mailReenvio}
                      onValueChanged={({ value }: any) => {
                        if (value) {
                          const val = value.replace(/\r?\n?/g, '');
                          setMailReenvio(val.trim())
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                        <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "- Email:")} />
                      </Validator>
                    </TextArea>
                  </Labeled>
                </CustomCol>
              </RowContainer>


              <ValidationSummary />

              <CRow className=" d-flex align-items-end justify-content-end mt-2"  >
                <CCol lg={6} >
                  <div className="d-grid  ">
                    <CButton color="success" onClick={() => { reenviarComprobante() }} >
                      {"Enviar"}
                    </CButton>
                  </div>
                </CCol>
              </CRow>

            </ValidationGroup>
          </>
        )
        }
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
      </>
    )
  }

  return (
    <Portal node={document && document.getElementById('root')} >
      <Modalform
        name='confirm'
        headerTitle={'Acatha'}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => props.closeConfirm()}
        centered={true}
        size={'sm'}
      />
    </Portal>
  );
};

export default PupopReenvioMail;