import { RolesUsuariosState } from '../rolesUsuario/store/types'
import { UsuarioState } from '../cuenta/usuario/store/types'
import { DesarrolloState } from '../desarrollador/store/types'
import { AdministracionCuentaState } from '../cuenta/store/types'
import { CertificadoState } from '../backOffice/certificadosDigitales/store/types'
import { TipoRecurrenciasState } from '../backOffice/tipoRecurrencias/store/types'
import { NotificationState } from '../backOffice/notificaciones/types/types'
import { ConfiguracionesState } from '../configuracion/store/types'

export type ModuloAdminState = {
  rolesUsuarios: RolesUsuariosState
  usuario: UsuarioState
  desarrollo: DesarrolloState
  cuenta: AdministracionCuentaState
  certificados: CertificadoState
  tipoRecurrencias: TipoRecurrenciasState
  notificaciones: NotificationState
  configuraciones: ConfiguracionesState
}

export enum Aplicacion {
  notificaciones = 'notificaciones',
}

export type EdicionPayload<T> = {
  key: number | string
  data: T
}
