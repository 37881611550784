import React from 'react'
import SelectBox from 'devextreme-react/select-box'
import { TipoProveedor } from '../../ventas/types/types'
import { tipoProveedorService } from './service/tiposProveedor.service'

interface ITipoProveedorLookUpProps extends React.PropsWithChildren {
  selected: TipoProveedor | null
  onChanged: (newValue: TipoProveedor | null) => void
  disabled?: boolean
  provider?: Array<TipoProveedor> | []
}

const TipoProveedorLookUp: React.FC<ITipoProveedorLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props
  const selectRef = React.useRef<any>()
  const [options, setOptions] = React.useState<Array<TipoProveedor> | []>([])

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TipoProveedor) => {
        return select.codigo === selected?.codigo
      })
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      } else {
        onChanged(provider[0])
      }
    } else {
      const data = await tipoProveedorService.getTiposProveedor('Elige una opción')
      if (data.error === false) {
        let opt = []
        if (data.auto.length > 0) {
          opt = data.auto.map((x) => {
            const item: TipoProveedor = {
              codigo: x.codigo ?? '-1',
              descripcion: x.descripcion ?? '',
            }
            return item
          })
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((option) => {
          return option.codigo === selected?.codigo
        });
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }
  }, [onChanged, selected, provider])

  const getItemDisplayExpr = React.useCallback((item: TipoProveedor) => {
    return item ? `${item.descripcion}` : ''
  }, [])

  const onLookUpValueChanged = React.useCallback(
    (evt: any) => {
      if (evt.event !== undefined) {
        onChanged(evt.value)
      }
    }, [onChanged])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector('input.dx-texteditor-input').select()
  }, []);

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: TipoProveedor) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    refreshSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id="tiposProveedorSelect"
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
export default React.memo(TipoProveedorLookUp)
