import React from 'react';
import { Document, Page } from 'react-pdf';
import { CButton, CModal } from '@coreui/react-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faDownload, faFileCode, faPrint, faWindowClose } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/toasterReducer';
import { ToastTypes } from '../../../store/types';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { UrlHelper } from '../../../helpers/urlHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { consoleService } from '../../../services/console.service';
import './pdfVisualizer.scss';
import { ECountry } from '../../../store/enum/countries';

interface IPdfVisualizerProps extends React.PropsWithChildren {
  urlJson?: string,
  urlPdf: string,
  urlBlobPdf: string,
  urlXml: string,
  urlPrint: string,
  fileName: string,
  onClose: () => void
}


export const PdfVisualizer: React.FC<IPdfVisualizerProps> = (props) => {
  const { onClose, } = props;
  const urls = UrlHelper.getUrls();
  consoleService.log("PdfVisualizer")
  consoleService.log("props", props)
  consoleService.log("urls", urls)

  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local });

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [files, setFiles] = React.useState<{
    urlPdf: string,
    urlBlobPdf: string,
    urlXml: string,
    urlJson: string,
    urlPrint: string,
    fileName: string,
  }>(null);
  const dispatch = useDispatch();
  //const [url, setUrl] = React.useState('./samples/pdf.pdf')

  const onDocumentLoadSuccess = React.useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, [])

  const onDocumentLoadFailed = React.useCallback((e) => {
    consoleService.log("onDocumentLoadFailed")
    consoleService.error(e)
    setError(true);
  }, [])

  const changePage = React.useCallback((offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }, [])

  const previousPage = React.useCallback(() => {
    changePage(-1);
  }, [changePage])

  const nextPage = React.useCallback(() => {
    changePage(1);
  }, [changePage])

  const onError = React.useCallback(() => {
    dispatch(addToast({
      id: '',
      autoHide: true,
      content: 'Error al descargar el archivo pdf ',
      fade: true,
      title: 'Descargar Pdf',
      type: ToastTypes.Warning

    }))
    // onClose();
  }, [dispatch])

  const onLoadFiles = React.useCallback((data) => {
    setFiles(data)
  }, [])

  React.useEffect(() => {
    if (error) {
      onError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  React.useEffect(() => {
    if (props) {
      onLoadFiles(props)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const buttonDownFile = React.useMemo(() => {
    let btn = null
    if (local?.ciudad?.paisCodigo === ECountry.Ecuador) {
      if (files?.urlXml !== '') {

        btn = <CButton
          disabled={error}
          variant='ghost'
          onClick={() => {
            if (empresa?.servicioS3 && empresa?.servicioS3 === true) {
              const link = document.createElement('a');
              link.href = 'data:application/octet-stream;base64,' + (files?.urlXml).toString();
              link.download = `${files?.fileName}.xml`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              const link = document.createElement('a');
              link.href = urls.documentServerBasePath + files?.urlXml;
              window.open(link.href, '_blank')
            }
          }}
        >
          <FontAwesomeIcon icon={faFileCode} size='2x' />
        </CButton>
      }
    } else {
      if (files?.urlJson !== "") {

        btn = <CButton
          disabled={error}
          variant='ghost'
          onClick={() => {

            const link = document.createElement('a');
            link.href = urls.documentServerBasePath + files?.urlJson;
            window.open(link.href, '_blank')

          }}
        >
          <FontAwesomeIcon icon={faFileCode} size='2x' />
        </CButton>
      }
    }
    return btn;
  }, [
    empresa, error, files?.fileName, files?.urlJson, files?.urlXml,
    local,
    urls.documentServerBasePath
  ]);

  const style = !isMobile ? { width: `${(window.innerWidth > 1000 ? 800 : window.innerWidth - 100)}px` } : {}
  const element = document.querySelector("#modal");
  if (element) {
    return (
      ReactDOM
        .createPortal(
          <CModal
            visible={true}
            onClose={onClose}
            size={isMobileOnly ? "xl" : 'lg'}

          >
            <div style={style} >
              <div className='pdfVisualizerContainer'>
                <div className='pdfVisualizerLayoutContainer'>
                  <div className='pdfVisualizerLayoutToolbar'>
                    <div className='pdfVisualizerToolbar'>
                      <div className='pdfVisualizerToolbar-left'>
                        {pageNumber > 1 &&
                          < CButton
                            variant='ghost'
                            disabled={pageNumber === 1 || error}
                            onClick={previousPage}
                          >
                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                          </CButton>
                        }
                        {numPages > 1 && pageNumber < numPages &&
                          <CButton
                            variant='ghost'
                            disabled={pageNumber >= numPages || error}
                            onClick={nextPage}
                          >
                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                          </CButton>
                        }
                      </div>
                      <div className='pdfVisualizerToolbar-right'>
                        <CButton
                          disabled={error}
                          variant='ghost'
                          onClick={() => {
                            if (empresa?.servicioS3 && empresa?.servicioS3 === true) {
                              const link = document.createElement('a');
                              link.href = 'data:application/octet-stream;base64,' + (files?.urlPdf).toString();
                              link.download = `${files?.fileName}.pdf`;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            } else {
                              const link = document.createElement('a');
                              link.href = urls.documentServerBasePath + files?.urlPdf;
                              window.open(link.href, '_blank')
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} size='2x' />
                        </CButton>
                        {buttonDownFile}

                        <CButton
                          disabled={error}
                          variant='ghost'
                          onClick={async () => {

                            const res = await fetch(files?.urlBlobPdf);
                            const b = await res.blob();
                            const blob = new Blob([b], { type: 'application/pdf;base64' });
                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL, '_blank');

                          }}
                        >
                          <FontAwesomeIcon icon={faPrint} size='2x' />
                        </CButton>
                        {isMobile &&
                          <CButton
                            disabled={error}
                            variant='ghost'
                            onClick={() => {
                              onClose()
                            }}
                          >
                            <FontAwesomeIcon icon={faWindowClose} size='2x' />
                          </CButton>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='pdfVisualizerLayoutMain'>
                    {files?.urlBlobPdf !== '' &&
                      <Document
                        file={files?.urlBlobPdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadFailed}
                      >
                        {isMobile &&
                          <Page pageNumber={pageNumber} width={window.innerWidth - 10} />
                        }
                        {!isMobile &&
                          <Page pageNumber={pageNumber} width={window.innerWidth > 1000 ? 800 : window.innerWidth - 100} />
                        }
                      </Document>
                    }
                  </div>
                </div>
              </div>
            </div>
          </CModal >
          , element)
    );
  }

  return <></>;

};


