import { RequestHelper } from '../helpers/requestHelper'
import { Ciudad } from '../modulos/ventas/store/types'
import { SesionService } from './sesion.service'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import { utilidades } from '../helpers/utilidades'
import {
  DotNetPagedResult,
  PagedResult,
  PaginationInfo,
  Pais,
  Provincia,
} from '../store/types'
import { RequestHelperDotNet } from '../helpers/requestHelperDotNet'
import { DateUtils, formatoFechasApi } from '../helpers/dateUtils'

export const localidadesService = {
  getPaises,
  getCiudades,
  getCiudad,
  consultarCiudades,
  getCiudadLocalActual,
  getCiudadesDatasource,
  getDpPaises,
  getDpCiudades,
  getCiudadesDatasourceNoSession,
  getCiudadNoSession,
  getCiudadByCode,
}

async function getPaises(objeto): Promise<any> {
  try {
    // const data = await RequestHelper.getAll<any>('countries', 'getAll', '', objeto)
    const data: any = {
      ...objeto,
    }
    const res = await RequestHelper.getAll<any>('paises', 'listar', '', data)
    return res
  } catch (error) {
    return error
  }
}

async function getCiudades(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.get<any>('ciudades', 'listar', '', objeto)
    return data
  } catch (error) {
    return error
  }
}

async function consultarCiudadesPaged(
  nombre: string,
  pagination: PaginationInfo,
): Promise<PagedResult<Ciudad>> {
  if (utilidades.canUseNetApi()) {
    return consultaCiudadesPagedNetApi(nombre, pagination)
  } else {
    return consultaCiudadesPagedPhpApi(nombre, pagination)
  }
}

async function consultaCiudadesPagedNetApi(
  nombre: string,
  pagination: PaginationInfo,
): Promise<PagedResult<Ciudad>> {
  const query: any = {
    getPaged: true,
    pageSize: pagination.size,
    pageIndex: pagination.current,
    by: 'nombre',
    queryValue: nombre,
  }

  const ciudadesApi = await RequestHelperDotNet.get<DotNetPagedResult<Ciudad>>(
    'Locations',
    'Query',
    '',
    query,
  )
  const pagedResult: PagedResult<Ciudad> = {
    currentPage: ciudadesApi.page,
    from: 0,
    to: 0,
    count: ciudadesApi.count,
    result: ciudadesApi.data,
  }
  return pagedResult
}

async function consultaCiudadesPagedPhpApi(
  nombre: string,
  pagination: PaginationInfo,
): Promise<PagedResult<Ciudad>> {
  const sesion = SesionService.getCurrentSesion()
  const query: any = {
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
    ciudad: nombre,
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }

  const pagedApiResult = await RequestHelper.get<any>(
    'ciudad',
    'cargar',
    '',
    query,
  )
  const ciudadesApi = pagedApiResult.auto as Array<any>

  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: ciudadesApi,
  }
}

async function consultarCiudades(
  codigo: number,
  nombre: string = '',
  codPais: number = 0,
): Promise<Array<Ciudad>> {
  const sesion = SesionService.getCurrentSesion()
  if (sesion?.empresa && sesion?.empresa?.codigoPais) {
    codPais = sesion?.empresa?.codigoPais
  }
  if (codPais === 0) {
    codPais = (await utilidades.isPathLocationSV()) ? 37 : 1
  }
  const queryData = {
    ciudad: nombre,
    codigo: codigo,
    paisCodigo: codPais,
  }
  const apiData = await RequestHelper.get<Array<Ciudad>>(
    'ciudad',
    'cargar',
    '',
    queryData,
  )

  return apiData
}

async function getCiudad(
  codigo: number,
  nombre: string = '',
): Promise<Ciudad | null> {
  const canUseNetApi = utilidades.canUseNetApi()
  if (canUseNetApi) {
    if (codigo > 0) {
      const ciudad = await RequestHelperDotNet.get<Ciudad>(
        'Locations',
        'Cities',
        codigo.toString(),
        null,
      )
      return ciudad
    } else {
      const ciudad = await RequestHelperDotNet.get<Array<Ciudad>>(
        'Locations',
        'Cities/Query',
        '',
        { By: 'nombre', QueryValue: nombre },
      )
      return ciudad.find((c) => c.codigo === codigo) ?? null
    }
  } else {
    const ciudades = await consultarCiudades(codigo, nombre)
    const ciudad = ciudades.find((x) => x.codigo === codigo)
    return ciudad ?? null
  }
}

function getCiudadLocalActual(): Ciudad | undefined {
  const sesion = SesionService.getCurrentSesion()
  const ciudad = sesion.local.ciudad
  return ciudad
}

function getCiudadesDatasource() {
  const customCache: any = {}
  let loading = false
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      if (loading) {
        while (loading) {
          await utilidades.sleep(50)
        }
      }
      if (customCache[key]) {
        return customCache[key]
      } else {
        loading = true
        const ciudad = await getCiudad(key, '')
        customCache[key] = ciudad
        loading = false
        return ciudad
      }

      //console.log(key);
    },
    load: async (loadOptions) => {
      console.log(loadOptions)
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<Ciudad> | null = null
      pagedResult = await consultarCiudadesPaged(
        loadOptions.searchValue.toString(),
        paginInfo,
      )
      console.log('current page ', pagedResult.currentPage)
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function getDpPaises(mensaje: string): Promise<Array<Pais>> {
  const queryData = {
    mensaje: mensaje,
  }
  const cacheKey = 'paises' + JSON.stringify(queryData)
  const savedData = sessionStorage.getItem(cacheKey) ?? null
  if (savedData) {
    return JSON.parse(savedData)
  }

  const apiData = await RequestHelper.get<Array<Pais>>(
    'paises',
    'listar',
    '',
    queryData,
  )

  const paisesData: Array<Pais> = apiData.map((x: any) => {
    const provincias: Array<Provincia> = []
    x.provincias.map((x: any) => {
      provincias.push({
        codigoPais: Number(x.PAI_CODIGO),
        codigo: Number(x.PRO_CODIGO),
        nombre: x.PRO_NOMBRE,
        codigoInec: x.PRO_INEC,
      })
    })

    const p: Pais = {
      nombre: x.PAI_NOMBRE,
      codigo: Number(x.PAI_CODIGO),
      codigoUaf: x.PAI_UAF,
      provincias: provincias,
    }
    return p
  })

  sessionStorage.setItem(cacheKey, JSON.stringify(paisesData))
  return paisesData
}

async function getDpCiudades(
  codigoProvincia: number,
  mensaje: string,
): Promise<Array<Ciudad>> {
  const sesion = SesionService.getCurrentSesion()
  const queryData: any = {
    mensaje: mensaje,
    provincia: codigoProvincia,
    paisCodigo: sesion?.empresa?.codigoPais ?? 1,
  }
  queryData.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
  const cacheKey = 'dpCiudades' + JSON.stringify(queryData)
  const cached = sessionStorage.getItem(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  } else {
    const apiData = await RequestHelper.get<Array<Ciudad>>(
      'ciudades',
      'listar',
      '',
      queryData,
    )
    const ciudadesData: Array<Ciudad> = apiData.map((x: any) => {
      const ciudades: Ciudad = {
        codigo: Number(x.CIU_CODIGO),
        nombre: x.CIU_NOMBRE,
        provinciaCodigo: Number(x.PRO_CODIGO),
        provinciaNombre: x.PRO_NOMBRE,
        rDEPCodigo: Number(x.CIU_INEC),
        paisCodigo: Number(x.PAIS_CODIGO),
        paisNombre: x.PAIS_NOMBRE,
      }
      return ciudades
    })
    sessionStorage.setItem(cacheKey, JSON.stringify(ciudadesData))

    return ciudadesData
  }
}

function getCiudadesDatasourceNoSession(codPais = 0) {
  const customCache: any = {}
  let loading = false
  const store = new CustomStore({
    key: 'codigo',
    byKey: async (key) => {
      if (loading) {
        while (loading) {
          await utilidades.sleep(50)
        }
      }
      if (customCache[key]) {
        return customCache[key]
      } else {
        loading = true
        const ciudad = await getCiudadNoSession(key, '', codPais)
        customCache[key] = ciudad
        loading = false
        return ciudad
      }

      //console.log(key);
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      }
      let pagedResult: PagedResult<Ciudad> | null = null
      pagedResult = await consultarCiudadesPagedNoSesion(
        loadOptions.searchValue.toString(),
        paginInfo,
        codPais,
      )
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      }
    },
  })
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: 'codigo',
  })
  return source
}

async function getCiudadNoSession(
  codigo: number,
  nombre: string = '',
  codPais: number = 0,
): Promise<any> {
  const ciudades = await consultarCiudades(codigo, nombre, codPais)
  const ciudad = ciudades.find((x) => x.codigo === codigo)
  return ciudad ?? null
}

async function consultarCiudadesPagedNoSesion(
  nombre: string,
  pagination: PaginationInfo,
  codPais: number = 0,
): Promise<PagedResult<Ciudad>> {
  return consultaCiudadesPagedPhpApiNoSession(nombre, pagination, codPais)
}

async function consultaCiudadesPagedPhpApiNoSession(
  nombre: string,
  pagination: PaginationInfo,
  codPais: number = 0,
): Promise<PagedResult<Ciudad>> {
  if (codPais === 0) {
    codPais = (await utilidades.isPathLocationSV()) ? 37 : 1
  }
  const query: any = {
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
    ciudad: nombre,
    paisCodigo: codPais,
  }

  const pagedApiResult = await RequestHelper.getAllNoSession(
    'ciudad',
    'cargar',
    '',
    query,
  )

  const ciudadesApi = pagedApiResult.auto.auto as Array<any>

  return {
    currentPage: parseInt(pagedApiResult.auto.paginaActual),
    from: parseInt(pagedApiResult.auto.desde),
    to: parseInt(pagedApiResult.auto.hasta),
    count: parseInt(pagedApiResult.auto.totalRegistros),
    result: ciudadesApi,
  }
}

async function getCiudadByCode(
  ciudadCodigo: number | null,
  codigoTributario?: {
    ciudadCodTributario?: number | string
    provinciaCodTributario?: number | string
  },
): Promise<any> {
  try {
    const query: any = {
      ciudad: ciudadCodigo,
    }
    if (codigoTributario?.ciudadCodTributario) {
      query.ciudadCodTributario = codigoTributario?.ciudadCodTributario
    }
    if (codigoTributario?.provinciaCodTributario) {
      query.provinciaCodTributario = codigoTributario?.provinciaCodTributario
    }
    const ciudades = await RequestHelper.get<any>('cityByCode', '', '', query)
    if (ciudades.length > 0) {
      if (ciudadCodigo !== null) {
        const ciudad = ciudades.find((x) => x.codigo === ciudadCodigo)
        return ciudad ?? null
      } else {
        return ciudades[0]
      }
    }
    return null
  } catch (error) {
    return error
  }
}
