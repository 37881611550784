import { TiposComprobantesSLV } from '../../../../../../store/types'

export const dteSalvador = [
  { codigo: TiposComprobantesSLV.Factura, valor: 'Consumidor Final' },
  { codigo: TiposComprobantesSLV.CreditoFiscal, valor: 'Crédito Fiscal' },
  {
    codigo: TiposComprobantesSLV.FacturaExportacion,
    valor: 'Exportación',
  },
  {
    codigo: TiposComprobantesSLV.FacturaExcluidoSujeto,
    valor: 'Sujeto Excluido ',
  },
  { codigo: TiposComprobantesSLV.NotaCredito, valor: 'Nota de Crédito' },
  { codigo: TiposComprobantesSLV.NotaDebito, valor: 'Nota de Débito' },
  { codigo: TiposComprobantesSLV.NotaRemision, valor: 'Nota de Remisión' },
  { codigo: TiposComprobantesSLV.Retencion, valor: 'Retención' },
  { codigo: TiposComprobantesSLV.Liquidacion, valor: 'Liquidación' },
  {
    codigo: TiposComprobantesSLV.LiquidacionContable,
    valor: 'Liquidación Contable',
  },
  {
    codigo: TiposComprobantesSLV.Donacion,
    valor: 'Donación',
  },
]
