import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { StatesEdition, TabTypes } from '../../../../ventas/types/enums'
import { ProveedoresListado } from '../types/types'
import { ModuleButtonsImport } from '../components/importar'
import { ModuleButtons } from '../components/documento'
import { TabsState, TabState } from '../../../../../store/genericTabTypes'
import { tabExists, tabIsVisible, closeTab } from '../../../../../hooks/useNavigationTabControls'
import { CustomButtons } from '../../../../../store/types'

const initialState: TabsState<ProveedoresListado> = {
  current: 'BUSQUEDA',
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: 'Búsqueda',
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: 'BUSQUEDA',
      buttons: {
        Buscar: true,
        Nuevo: true,
        Importar: true,
      },
    },
  },
}

const tabsProveedoresSlice = createSlice({
  name: 'proveedoresTabs',
  initialState: initialState,
  reducers: {
    proveedoresCloseTab: closeTab,
    openTab(
      state,
      action: PayloadAction<{
        key: string
        supplier?: ProveedoresListado
        templateMode?: boolean
      }>,
    ) {
      if (
        action.payload.supplier === undefined ||
        action.payload.templateMode
      ) {
        const tab: TabState<ProveedoresListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: 'Nueva',
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            codigo: 0,
          },
          buttons: ModuleButtons,
          templateMode: action.payload.templateMode,
        }
        state.tabs[action.payload.key] = tab
        state.current = action.payload.key
      } else {
        const tabKey = tabExists(state.tabs, action.payload.supplier.codigo)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<ProveedoresListado> = {
            tabKey: action.payload.key,
            id: action.payload.supplier.codigo,
            tittle: `${action.payload.supplier.razonComercial}-${action.payload.supplier.identificacion}-${action.payload.supplier.codigo}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              codigo: action.payload.supplier.codigo,
              info: action.payload.supplier,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: ModuleButtons,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    openTabImport(
      state,
      action: PayloadAction<{
        key: string
        type: TabTypes
      }>,
    ) {
      console.log('action.payload.type', action.payload.type)
      if (action.payload.type === TabTypes.import) {
        const tabKey = tabIsVisible(state.tabs, action.payload.type)
        if (tabKey) {
          state.current = tabKey
        } else {
          const tab: TabState<ProveedoresListado> = {
            id: -1,
            tittle: 'Importar',
            type: TabTypes.import,
            info: {
              codigo: 0,
            },
            canClose: true,
            editStatus: StatesEdition.new,
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            tabKey: action.payload.key,
            buttons: ModuleButtonsImport,
          }
          state.tabs[action.payload.key] = tab
          state.current = action.payload.key
        }
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload
      }
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string
        buttonType: ButtonTypes | undefined
      }>,
    ) {
      const { buttonType, tabKey } = action.payload
      state.tabs[tabKey].currentExecutingAction = buttonType
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>,
    ) {
      const { buttons, tabKey } = action.payload
      state.tabs[tabKey].buttons = buttons
      state.tabs[tabKey].currentExecutingAction = undefined
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>,
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none
      }
    },
  },
})

export const {
  proveedoresCloseTab,
  openTab,
  changeCurrentTab,
  setCurrentExecutingAction,
  setButtons,
  buttonClick,
  openTabImport,
  clearButtonClick,
} = tabsProveedoresSlice.actions
export const tabsReducer = tabsProveedoresSlice.reducer
