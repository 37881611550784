import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../../helpers/requestHelper'
import { utilidades } from '../../../../../helpers/utilidades'
import { SesionService } from '../../../../../services/sesion.service'
import { ECountry } from '../../../../../store/enum/countries'
import { TipoAgente, TipoNegocio } from '../../../../../store/types'
import { RimpeOption } from '../formularios/formRegEmpresa'
import { parseActivities, parseAgent, parseRimpe } from '../helper/helper'

export const wizardService = {
  getTiposNegocios,
  getTiposAgente,
  getPlanes,
  getInfoPlan,
  docEmpresaUplod,
  obtenerTerminosCondiciones,
  datosEmpresaIngresar,
  uploadFiles,
  verificaExisteEmpresa,
  getTiposRimpe,
  getCategoryPlan,
  validaCertificate,
  checkAuthHacienda,
}

async function getTiposNegocios(paisCodigo): Promise<any> {
  try {
    const data: any = {
      paisCodigo: paisCodigo ?? 1,
    }
    const responseSV = await utilidades.isPathLocationSV()
    if (responseSV) {
      data.paisCodigo = 37
    }
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'activitiesOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const res = await RequestHelper.getAllNoSession(
        'tiponegocio',
        'listar',
        '',
        data,
      )
      let opt: Array<TipoNegocio> = []
      if (res?.auto && res?.auto.length > 0) {
        opt = parseActivities(res.auto)
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      res.auto = opt ?? []
      return res
    }
  } catch (error) {
    return error
  }
}

async function getTiposAgente(codPais: number = 1): Promise<any> {
  try {
    const data: any = {}
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    data.paisCodigo = 1
    if (codPais !== 1) {
      data.paisCodigo = codPais
    }
    const cacheKey = 'agentOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const res = await RequestHelper.getAllNoSession(
        'tipoagente',
        'listar',
        '',
        data,
      )
      let opt: Array<TipoAgente> = []
      if (res?.auto && res?.auto.length > 0) {
        opt = parseAgent(res.auto)
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      res.auto = opt ?? []
      return res
    }
  } catch (error) {
    return error
  }
}

async function getPlanes(obj): Promise<any> {
  try {
    const responseSV = await utilidades.isPathLocationSV()

    if (responseSV) {
      obj.pai_codigo = ECountry.ElSalvador
    } else {
      obj.pai_codigo = ECountry.Ecuador
    }
    const sesion = SesionService.getCurrentSesion()
    if (sesion && sesion?.empresa && sesion?.empresa?.codigoPais) {
      obj.pai_codigo = sesion?.empresa?.codigoPais
    }

    obj.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'allPlansOptions' + JSON.stringify(obj)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const data = await RequestHelper.getAllNoSession(
        'public',
        'getAllPlans',
        '',
        obj,
      )
      if (data?.auto && data?.auto.length > 0) {
        data['auto'] = utilidades.sortJSON(data?.auto, 'planOrden', 'asc')
      }

      let opt: Array<any> = []
      if (data['auto'] && data['auto'].length > 0) {
        opt = data?.auto ?? []
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      data.auto = opt ?? []

      return data
    }
  } catch (error) {
    return error
  }
}

async function getInfoPlan(number): Promise<any> {
  try {
    const obj: any = null
    const response = await utilidades.isInElSalvador()
    if (response) {
      obj.pai_codigo = ECountry.ElSalvador
    } else {
      obj.pai_codigo = ECountry.Ecuador
    }
    const data = await RequestHelper.getAllNoSession(
      'public',
      'getAllPlans',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function docEmpresaUplod(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postDataNoSession<boolean>(
      'public',
      'validateCertificate',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerTerminosCondiciones(): Promise<any> {
  try {
    const getTerminos = await RequestHelper.getAllNoSession(
      'terminos',
      'getConditions',
      '',
      '',
    )
    return getTerminos
  } catch (error) {
    return error
  }
}

async function datosEmpresaIngresar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAllNoSession<any>(
      'admin',
      'contratos/register',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function uploadFiles(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postDataNoSession<any>(
      'public',
      'uploadTMPFile',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificaExisteEmpresa(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.getAllNoSession(
      'admin',
      'contratos/verificateIdEmpresa',
      '',
      { ruc: objeto },
    )
    return data
  } catch (error) {
    return error
  }
}

async function getTiposRimpe(): Promise<any> {
  try {
    const data: any = {}
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'rimpeOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const res = await RequestHelper.getAllNoSession(
        'public',
        'getClasificationRimpe',
        '',
        '',
      )
      let opt: Array<RimpeOption> = []
      if (res?.auto && res?.auto.length > 0) {
        opt = parseRimpe(res.auto)
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      res.auto = opt ?? []
      return res
    }
  } catch (error) {
    return error
  }
}

async function getCategoryPlan(): Promise<any> {
  try {
    const data: any = {}
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'CategoryPlansOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const res = await RequestHelper.getAllNoSession(
        'public',
        'getCategoryPlans',
        '',
        '',
      )
      let opt: Array<any> = []
      if (res?.auto && res?.auto.length > 0) {
        opt = res?.auto.map((x) => {
          const item = {
            codigo: parseInt(x['codigo']) ?? 0,
            descripcion: utilidades.capText(x['descripcion']) ?? '',
            estado: x['estado'],
          }
          return item
        })
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      res.auto = opt ?? []
      return res
    }
  } catch (error) {
    return error
  }
}

async function validaCertificate(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postDataNoSession<boolean>(
      'public',
      'validateCertificateMultiCountry',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function checkAuthHacienda(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postDataNoSession<boolean>(
      'public',
      'checkAuthHacienda',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}
