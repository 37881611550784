import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { tipoAgenteService } from './service/tipoAgente.service';
import { TipoAgente } from '../../../store/types';

interface ITipoAgenteLookUpProps extends React.PropsWithChildren {
  selected: TipoAgente | null,
  onChanged: (newValue: TipoAgente | null) => void,
  disabled?: boolean,
  provider?: Array<TipoAgente> | []
}

const TipoAgenteLookUp: React.FC<ITipoAgenteLookUpProps> = (props) => {
  const { provider, selected, onChanged, disabled } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TipoAgente> | []>([]);

  const loadDatos = React.useCallback(async () => {
    if (provider !== undefined && provider.length > 0) {
      setOptions(provider)
      const selectedIndex = provider.findIndex((select: TipoAgente) => {
        return select.codigo === selected?.codigo
      })
      onChanged(provider[selectedIndex] ?? provider[0])
      if (selectedIndex > -1) {
        onChanged(provider[selectedIndex] ?? provider[0])
      }
    } else {
      const data = await tipoAgenteService.getTipoAgente("Tipo de Agente");
      if (data.error === false) {
        let opt = [];
        if (data.auto.length > 0) {
          opt = data.auto.map(x => {
            const item: TipoAgente = {
              codigo: parseInt(x.codigo) ?? -1,
              descripcion: x.descripcion ?? '',
            };
            return item;
          });
        }
        setOptions(opt)
        const selectedIndex = opt.findIndex((select) => {
          return select.codigo === selected?.codigo
        })
        if (selectedIndex > -1) {
          onChanged(opt[selectedIndex] ?? opt[0])
        }
      }
    }

  }, [onChanged, selected, provider]);

  const getItemDisplayExpr = React.useCallback((item: TipoAgente) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='tiposAgenteSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(TipoAgenteLookUp);
