import React from 'react'
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer'
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput'
import CustomCol from '../../../../../../../../views/componentes/colContainer'
import { TextBox } from 'devextreme-react/text-box'
import { Button } from 'devextreme-react/button'
import { CTooltip } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/store'
import { NumberBox, Validator } from 'devextreme-react'
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator'
import {
  setTabContribuyente
} from '../../../../store/editDataReducer'
import { DatosEdicionContribuyente } from '../../../../types/types'

interface IMainProps {
  tabId: string
}

const MainInfo: React.FunctionComponent<IMainProps> = (props) => {
  const { tabId } = props

  const dispatch = useDispatch()

  const stateEditContribuyente = useSelector((state: RootState) => {
    return state.proveedores.proveedores.editData[tabId]?.tabs?.contribuyente
  })

  const [codigo, setCodigo] = React.useState<number>(0);
  const [nombre, setNombre] = React.useState<string>('');
  const [razonComercial, setRazonComercial] = React.useState<string>('');


  const onChangedName = React.useCallback(
    (e: any) => {
      if (e?.event !== null || e?.event !== undefined) {
        dispatch(setTabContribuyente({
          key: tabId,
          data: {
            ...stateEditContribuyente,
            nombre: e?.value ?? ''
          }
        }))
      }
    },
    [dispatch, tabId, stateEditContribuyente])

  const onChangedBusinessReason = React.useCallback(
    (e: any) => {
      if (e?.event !== null || e?.event !== undefined) {
        dispatch(setTabContribuyente({
          key: tabId,
          data: {
            ...stateEditContribuyente,
            razonComercial: e?.value ?? ''
          }
        }))
      }
    },
    [dispatch, tabId, stateEditContribuyente])

  const onDataFormulario = React.useCallback(async (dataToFormulario: DatosEdicionContribuyente) => {
    setCodigo(dataToFormulario?.codigo)
    setNombre(dataToFormulario?.nombre)
    setRazonComercial(dataToFormulario?.razonComercial)
  }, []);

  React.useEffect(() => {
    onDataFormulario(stateEditContribuyente)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEditContribuyente]);


  return (
    <>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Código:">
            <NumberBox name="supplierCode" value={codigo ?? 0} readOnly />
          </Labeled>
        </CustomCol>

        <CustomCol xs="12" md="1" className="d-flex align-items-end">
          <CTooltip content="Campo Informativo" placement="right">
            <div>
              <Button
                id="btnInfo"
                type="normal"
                icon="info"
                stylingMode="contained"
              />
            </div>
          </CTooltip>
        </CustomCol>
      </RowContainer>
      <RowContainer>
        <CustomCol xs="12" md="3">
          <Labeled label="Razón Social:">
            <TextBox
              name="razonSocial"
              placeholder=""
              value={nombre ?? ''}
              onValueChanged={onChangedName}
              inputAttr={{
                autocomplete: 'nope',
              }}
            >
              <Validator>
                <RequiredRule
                  message={'Nombre: Debe tener al menos 5 caracteres.'}
                />
                <StringLengthRule
                  min={5}
                  message={'Nombre: Debe tener al menos 5 caracteres.'}
                />
              </Validator>
            </TextBox>
          </Labeled>
        </CustomCol>
        <CustomCol xs="12" md="4">
          <Labeled label="Razon Comercial:">
            <TextBox
              name="razonComercial"
              placeholder=""
              value={razonComercial ?? ''}
              onValueChanged={onChangedBusinessReason}
              inputAttr={{
                autocomplete: 'nope',
              }}
            />
          </Labeled>
        </CustomCol>
      </RowContainer>
    </>
  )
}

export default React.memo(MainInfo)

export const defaultDatosContribuyente: DatosEdicionContribuyente = {
  codigo: 0,
  nombre: '',
  razonComercial: ''
}