import React from 'react'
import CustomModalDevx from '../../../../views/componentes/modal/Modal'
import FormularioItem from './busquedaItemsModal/Componentes/formularioItem'
import { Portal } from 'react-portal'
import ReactDom from 'react-dom'
import {
  EAplicationsAcatha,
  EModulosAcatha,
} from '../../../../store/enumsAplication'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'

export function ModificaItem(props: IModificaItemProps) {
  const { show, codigoBarras, onChanged, onCancel } = props

  const [funcion, setFuncion] = React.useState<string>('')

  const modalContent = () => {
    return (
      <CustomModalDevx
        id="popupAdminItems"
        show={show}
        title="Administración de productos"
        onClose={onCancel}
        size="md"
        toolbarItems={[
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Aceptar',
              type: 'default',
              onClick: () => {
                setFuncion('Guardar')
              },
            },
          },
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Cancelar',
              type: 'danger',
              onClick: props.onCancel,
            },
          },
        ]}
      >
        <FormularioItem
          transaccion={props.transaccion}
          codigoBarras={codigoBarras}
          onChanged={onChanged}
          onCancel={onCancel}
          funcion={funcion}
          resetFunction={() => setFuncion('')}
          currentTab={codigoBarras !== '' ? 'Editar' : 'Nuevo'}
          modulo={props.modulo}
          tipo={null}
        />
      </CustomModalDevx>
    )
  }

  return (
    <Portal node={document && document.getElementById('root')}>
      {ReactDom.createPortal(modalContent(), document.body)}
    </Portal>
  )
}

interface IModificaItemProps extends React.PropsWithChildren {
  show: boolean
  codigoBarras: string
  onChanged?: (codigoBarras: string) => void
  onCancel: () => void
  transaccion: string
  modulo?: EModulosAcatha | EAplicationsAcatha
}

export default ModificaItem
