import { DateUtils } from '../../../../../../helpers/dateUtils'
import { RequestHelper } from '../../../../../../helpers/requestHelper'
import { RequestHelperCountries } from '../../../../../../helpers/requestHelperCountries'
import { TiposComprobantesSLV } from '../../../../../../store/types'
import { TAutorizacionDocumentoSV } from '../../../../types/types'
import {
  ComprobanteDte,
  DatosVenta,
  FiltroBusquedaAnexoTransaccionalState,
  TAnexoIngresar,
} from '../pages/anexoTransaccional/types/types'

export const anexoTransaccionalSVService = {
  getAnexoTransac,
  getSustentos,
  generarCsvCF,
  generarCsvContribuyente,
  asignarSustento,
  obtenerXml,
  generarCsvRete,
  generarCsvSujeto,
}

async function getAnexoTransac(
  filtro: FiltroBusquedaAnexoTransaccionalState,
): Promise<Array<ComprobanteDte>> {
  const data: any = {}
  if (filtro.fechaInicio) {
    data.desde = DateUtils.pickersDateToApiDate(filtro.fechaInicio)
  }
  if (filtro.fechaFinal) {
    data.hasta = DateUtils.pickersDateToApiDate(filtro.fechaFinal)
  }
  if (filtro.dte) {
    data.dte = Object.keys(filtro.dte).filter((key) => filtro.dte[key] === true)
  }
  const res = await RequestHelper.get(
    'integraciones',
    'sv/getDataDte',
    '',
    data,
  )

  let anexos: ComprobanteDte[] = []
  let dteData = []

  if (res) {
    const dteName = Object.keys(res)

    for (const itemKey of dteName) {
      const datosVenta: DatosVenta[] = []
      if (res[itemKey] && res[itemKey].length > 0) {
        for (const itemVenta of res[itemKey]) {
          if (itemKey == TiposComprobantesSLV.Retencion) {
            const parsed = parseApiDatosVentaRetencion(itemVenta)

            datosVenta.push({ ...parsed })
          } else {
            const parsed = parseApiDatosVenta(itemVenta)
            datosVenta.push({ ...parsed })
          }
        }
      }

      const add: ComprobanteDte = {
        [itemKey]: datosVenta ?? [],
      }

      dteData = Object.assign(dteData, add)
      anexos = { ...Object.assign(dteData, add) }
    }
  }

  return anexos
}

async function getSustentos(tipoComprobante: string) {
  const data: any = {
    all: true,
  }

  const sustentos = await RequestHelper.get<Array<any>>(
    'generales',
    'sustentosVouchers/getLivelihoods',
    '',
    data,
  )
  return sustentos
}

async function generarCsvCF(anexo: DatosVenta): Promise<Promise<any>> {
  try {
    const res = await RequestHelperCountries.postAll<any>(
      'anexos-fiscales',
      'get-csv-venta-cf',
      anexo,
    )
    return res
  } catch (error) {
    return error
  }
}
async function generarCsvRete(anexo: DatosVenta): Promise<Promise<any>> {
  try {
    const res = await RequestHelperCountries.postAll<any>(
      'anexos-fiscales',
      'get-csv-venta-rete',
      anexo,
    )
    return res
  } catch (error) {
    return error
  }
}

async function generarCsvContribuyente(
  anexo: DatosVenta[],
): Promise<Promise<any>> {
  try {
    const res = await RequestHelperCountries.postAll<any>(
      'anexos-fiscales',
      'get-csv-venta-contribuyente',
      anexo,
    )
    return res
  } catch (error) {
    return error
  }
}
async function generarCsvSujeto(anexo: DatosVenta[]): Promise<Promise<any>> {
  try {
    const res = await RequestHelperCountries.postAll<any>(
      'anexos-fiscales',
      'get-csv-compras-sujeto-excluido',
      anexo,
    )
    return res
  } catch (error) {
    return error
  }
}

async function asignarSustento(asignacion): Promise<Promise<any>> {
  try {
    const data: any = {
      ...asignacion,
    }

    const sustentos = await RequestHelper.get<Array<any>>(
      'impuestos',
      'asignarSustento',
      '',
      data,
    )
    return sustentos
  } catch (error) {
    return error
  }
}

async function obtenerXml(filtros): Promise<Blob> {
  // eslint-disable-next-line no-useless-catch
  try {
    const queryData = {
      ...filtros,
    }
    const blob = await RequestHelper.getFile(
      'impuestos',
      'obtenerATS',
      '',
      queryData,
      { type: 'application/xml' },
    )
    return blob
  } catch (error) {
    throw error
  }
}

function parseApiDatosVenta(apiData: any): DatosVenta {
  const data = apiData

  let autorizacionHacienda: TAutorizacionDocumentoSV
  if (data?.autorizacionHacienda) {
    autorizacionHacienda = {
      estado: data?.autorizacionHacienda?.estado ?? '',
      version: data?.autorizacionHacienda?.version ?? '',
      ambiente: data?.autorizacionHacienda?.ambiente ?? '',
      codigoMensaje: data?.autorizacionHacienda?.codigoMensaje ?? '',
      versionApp: data?.autorizacionHacienda?.versionApp ?? '',
      clasificacionMensaje:
        data?.autorizacionHacienda?.clasificacionMensaje ?? '',
      selloRecibido: data?.autorizacionHacienda?.selloRecibido ?? '',
      descripcionMensaje: data?.autorizacionHacienda?.descripcionMensaje ?? '',
      fechaProcesamiento: data?.autorizacionHacienda?.fechaProcesamiento ?? '',
      tipoComprobante: data?.autorizacionHacienda?.tipoComprobante ?? '',
      codigoGeneracion: data?.autorizacionHacienda?.codigoGeneracion ?? '',
      claveAcceso: data?.autorizacionHacienda?.claveAcceso ?? '',
      codigo: data?.autorizacionHacienda?.codigo ?? '',
    }
  }
  const item: DatosVenta = {
    ...data,
    retener: Number(data.retener),
    clasificacionDescripcion: data.clasificacionDescripcion,
    tipoCostoDescripcion: data.tipoCostoDescripcion,
    sectorDescripcion: data.sectorDescripcion,
    operacionDescripcion: data.operacionDescripcion,
    comprobante: parseInt(data?.comprobante ?? 0) ?? 0,
    tipoDocumentoDescripcion: data?.tipoDocumentoDescripcion ?? '',
    tipoDocumentoCodigo: data?.tipoDocumentoCodigo ?? '',
    guia: data?.guia ?? null,
    fechaNegociacion: data?.fecha ?? '',
    establecimiento: data?.establecimiento ?? '',
    servicios: parseFloat(data?.servicios ?? 0) ?? 0,
    puntoEmision: data?.puntoEmision ?? '',
    numero: data?.numero ?? '',
    autorizacion: data?.autorizacion ?? '',
    fecha: data?.fecha ?? '',
    subtotalIva: parseFloat(data?.subtotalIva ?? 0) ?? 0,
    subtotal0: parseFloat(data?.subtotal0 ?? 0) ?? 0,
    subtotalNoIva: parseFloat(data?.subtotalNoIva ?? 0) ?? 0,
    subtotal: parseFloat(data?.subtotal ?? 0) ?? 0,
    retTotal: parseFloat(data?.retTotal ?? 0) ?? 0,
    descuentos: parseFloat(data?.descuentos ?? 0) ?? 0,
    descuentoItems: parseFloat(data?.descuentoItems ?? 0) ?? 0,
    descuentoGlobal: parseFloat(data?.descuentoGlobal ?? 0) ?? 0,
    ice: parseFloat(data?.ice ?? 0) ?? 0,
    iva: parseFloat(data?.iva ?? 0) ?? 0,
    total: parseFloat(data?.total ?? 0) ?? 0,
    clienteIdentificacion: data?.clienteIdentificacion ?? '',
    clienteNombre: data?.clienteNombre ?? '',
    clienteRazonComercial: data?.clienteRazonComercial ?? '',
    asesorComercial: data?.asesorComercial ?? '',
    clienteDireccion: data?.clienteDireccion ?? '',
    clienteTelefono: data?.clienteTelefono ?? '',
    clienteEmail: data?.clienteEmail ?? '',
    estado: data?.estado ?? '',
    claveAcceso: data?.claveAcceso ?? '',
    fechaAutorizacion: data?.fechaAutorizacion ?? '',
    saldo: parseFloat(data?.saldo ?? 0) ?? 0,
    asociados: parseInt(data?.asociados ?? 0) ?? 0,
    formaPago: data?.formaPago ?? '',
    local: data?.local ?? '',
    puntoVenta: data?.puntoVenta ?? '',
    observaciones: data?.observaciones ?? '',
    pdf: data?.pdf ?? '',
    xml: data?.xml ?? '',
    url: data?.url ?? '',

    items: parseInt(data?.items ?? 0) ?? 0,
    estadoElectronico: data?.estadoElectronico ?? '',
    itemsSinImpuesto: parseInt(data?.itemsSinImpuesto ?? 0) ?? 0,
    itemsConImpuesto: parseInt(data?.itemsConImpuesto ?? 0) ?? 0,
    autorizacionHacienda: autorizacionHacienda ?? null,
    totalExenta: parseFloat(data?.totalExenta ?? 0) ?? 0,
    totalGravada: parseFloat(data?.totalGravada ?? 0) ?? 0,
  }
  return item
}
function parseApiDatosVentaRetencion(apiData: any): DatosVenta {
  const data = apiData

  let autorizacionHacienda: TAutorizacionDocumentoSV
  if (data?.autorizacionHacienda) {
    autorizacionHacienda = {
      estado: data?.autorizacionHacienda?.estado ?? '',
      version: data?.autorizacionHacienda?.version ?? '',
      ambiente: data?.autorizacionHacienda?.ambiente ?? '',
      codigoMensaje: data?.autorizacionHacienda?.codigoMensaje ?? '',
      versionApp: data?.autorizacionHacienda?.versionApp ?? '',
      clasificacionMensaje:
        data?.autorizacionHacienda?.clasificacionMensaje ?? '',
      selloRecibido: data?.autorizacionHacienda?.selloRecibido ?? '',
      descripcionMensaje: data?.autorizacionHacienda?.descripcionMensaje ?? '',
      fechaProcesamiento: data?.autorizacionHacienda?.fechaProcesamiento ?? '',
      tipoComprobante: data?.autorizacionHacienda?.tipoComprobante ?? '',
      codigoGeneracion: data?.autorizacionHacienda?.codigoGeneracion ?? '',
      claveAcceso: data?.autorizacionHacienda?.claveAcceso ?? '',
      codigo: data?.autorizacionHacienda?.codigo ?? '',
    }
  }
  const item: DatosVenta = {
    comprobante: parseInt(data?.comprobante ?? 0) ?? 0,
    tipoDocumentoDescripcion: data?.tipoDocumentoDescripcion ?? '',
    tipoDocumentoCodigo: data?.tipoDocumentoCodigo ?? '',
    guia: data?.guia ?? null,
    fechaNegociacion: data?.fechaNegociacion ?? '',
    establecimiento: data?.establecimiento ?? '',
    servicios: parseFloat(data?.servicios ?? 0) ?? 0,
    puntoEmision: data?.puntoEmision ?? '',
    numero: data?.numero ?? '',
    autorizacion: data?.autorizacion ?? '',
    fecha: data?.fecha ?? '',
    subtotalIva: parseFloat(data?.subtotalIva ?? 0) ?? 0,
    subtotal0: parseFloat(data?.compraSubtotal12 ?? 0) ?? 0,
    subtotalNoIva: parseFloat(data?.subtotalNoIva ?? 0) ?? 0,
    subtotal: parseFloat(data?.subtotal ?? 0) ?? 0,
    retTotal: parseFloat(data?.retTotal ?? 0) ?? 0,
    descuentos: parseFloat(data?.descuentos ?? 0) ?? 0,
    descuentoItems: parseFloat(data?.descuentoItems ?? 0) ?? 0,
    descuentoGlobal: parseFloat(data?.descuentoGlobal ?? 0) ?? 0,
    ice: parseFloat(data?.ice ?? 0) ?? 0,
    iva: parseFloat(data?.iva ?? 0) ?? 0,
    total: parseFloat(data?.total ?? 0) ?? 0,
    clienteIdentificacion: data?.proveedorIdentificacion ?? '',
    clienteNombre: data?.proveedorNombre ?? '',
    clienteRazonComercial: data?.proveedorNombre ?? '',
    asesorComercial: data?.asesorComercial ?? '',
    clienteDireccion: data?.proveedorDireccion ?? '',
    clienteTelefono: data?.proveedorTelefono ?? '',
    clienteEmail: data?.proveedorEmail ?? '',
    estado: data?.estado ?? '',
    claveAcceso: data?.claveAcceso ?? '',
    fechaAutorizacion: data?.fechaAutorizacion ?? '',
    saldo: parseFloat(data?.saldo ?? 0) ?? 0,
    asociados: parseInt(data?.asociados ?? 0) ?? 0,
    formaPago: data?.formaPago ?? '',
    local: data?.local ?? '',
    puntoVenta: data?.puntoVenta ?? '',
    observaciones: data?.observaciones ?? '',
    pdf: data?.pdf ?? '',
    xml: data?.xml ?? '',
    url: data?.url ?? '',
    items: parseInt(data?.items ?? 0) ?? 0,
    estadoElectronico: data?.estadoElectronico ?? '',
    itemsSinImpuesto: parseInt(data?.itemsSinImpuesto ?? 0) ?? 0,
    itemsConImpuesto: parseInt(data?.itemsConImpuesto ?? 0) ?? 0,
    autorizacionHacienda: autorizacionHacienda ?? null,
    totalExenta: parseFloat(data?.totalExenta ?? 0) ?? 0,
    totalGravada: parseFloat(data?.compraSubtotal12 ?? 0) ?? 0,
    tipoIdentificacion: data?.provedorTipoIdentificacion ?? '',
  }
  return item
}
